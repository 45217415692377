import PresentationHeader from "../components/PresentationHeader"
import {FiAlertCircle} from "react-icons/fi"
import {Link, useNavigate} from "react-router-dom"
import Footer from "../components/Footer"
import React, {useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import axios from "axios"


const ForgottenPassword = () => {
    const navigate = useNavigate()

    const [step, setStep] = useState(1)

    const [formData, setFormData] = useState({
        email: '',
        code: '',
        newPassword1: '',
        newPassword2: ''
    })
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isCodeLoading, setIsCodeLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const [user, setUser] = useState({})

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("token"))) {
            navigate('/home')
        }
        document.title = "Récupération du mot de passe | RetroRealm"
    }, [])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({
            ...formData, [name]: value,
        })
    }

    const generateEmailCode = async (user) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            await axios.post(API_URL + '/auth/generate-recover-code', {email: user.email})
        } catch (error) {
            console.error(error)
            navigate('/500')
        }
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault()

        const receivedErrors = validateEmail(formData)

        setErrors(receivedErrors)
        if (Object.keys(receivedErrors).length > 0) {
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + '/auth/check-email', formData)

            if (response.status === 200) {
                const receivedUser = response.data
                setUser(receivedUser)
                await generateEmailCode(receivedUser)
                setStep(2)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    setErrors(error.response.data)
                    break
                case 400:
                    navigate("/400")
                    break
                case 422:
                    navigate("/422")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleCodeSubmit = async (e) => {
        e.preventDefault()

        const receivedErrors = validateCode(formData)

        setErrors(receivedErrors)
        if (Object.keys(receivedErrors).length > 0) {
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + '/auth/validate-recover-code', formData)
            if (response.status === 200) {
                setStep(3)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    navigate("/400")
                    break
                case 422:
                    setErrors(error.response.data)
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }

    }

    const resendCode = async () => {
        setIsCodeLoading(true)
        await generateEmailCode(user)
        setIsCodeLoading(false)
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
    }

    const handlePasswordRecover = async (e) => {
        e.preventDefault()

        const receivedErrors = validateNewPasswords(formData)

        setErrors(receivedErrors)
        if (Object.keys(receivedErrors).length > 0) {
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.patch(API_URL + '/auth/recover-password', formData)
            if (response.status === 200) {
                navigate('/login')
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    setErrors(error.response.data)
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <PresentationHeader/>
            <div className="flex-grow flex justify-center items-center">
                <div
                    className="rounded-3xl bg-base-300 w-11/12 max-w-xl p-10 flex flex-col justify-center items-center my-10">
                    {step === 1 && (
                        <>
                            <h2 className="text-center">Adresse courriel</h2>
                            <p className="mb-5 text-center">Entrez l'adresse courriel associée à votre compte.</p>
                            <form onSubmit={handleEmailSubmit} className="w-full">
                                <label className="form-control block w-full">
                                    <div className="label">
                                        <span className="label-text text-lg">Adresse courriel</span>
                                    </div>
                                    <input name="email" type="text" placeholder="Tapez ici" value={formData.email}
                                           onChange={handleChange}
                                           className="input input-bordered input-primary w-full rounded-full block"/>
                                </label>
                                {errors.email && (
                                    <div className="flex justify-start items-center gap-1">
                                        <FiAlertCircle size={24} className="text-red-500"/>
                                        <p className="text-red-500 text-lg">{errors.email}</p>
                                    </div>
                                )}

                                <div className="flex flex-col justify-start items-center">
                                    {isLoading ? (
                                        <button className="btn btn-primary rounded-full w-44 my-5">
                                            <span className="loading loading-spinner"></span>
                                            Chargement
                                        </button>
                                    ) : (
                                        <input type="submit" className="btn btn-primary rounded-full w-44 my-5"
                                               value="Suivant"/>
                                    )}

                                    <Link to="/home" className="link link-primary text-xl">Accueil</Link>
                                </div>
                            </form>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <h2 className="text-center">Validation</h2>
                            <p className="text-center mb-3">Un code de validation a été envoyé à l'adresse
                                courriel <span
                                    className="underline">{user.email}</span>.</p>
                            <p className="mb-5 text-center">Entrez le code pour réinitialiser votre mot de passe.</p>
                            <form onSubmit={handleCodeSubmit} className="w-full">
                                <label className="form-control block w-full">
                                    <div className="label">
                                        <span className="label-text text-lg">Code</span>
                                    </div>
                                    <input name="code" type="text" placeholder="Tapez ici" value={formData.code}
                                           onChange={handleChange}
                                           className="input input-bordered input-primary w-full rounded-full block"/>
                                </label>
                                {errors.code && (
                                    <div className="flex justify-start items-center gap-1">
                                        <FiAlertCircle size={24} className="text-red-500"/>
                                        <p className="text-red-500 text-lg">{errors.code}</p>
                                    </div>
                                )}

                                <div className="flex flex-col justify-start items-center">
                                    {isLoading ? (
                                        <button className="btn btn-primary rounded-full w-44 mt-5">
                                            <span className="loading loading-spinner"></span>
                                            Chargement
                                        </button>
                                    ) : (
                                        <input type="submit" className="btn btn-primary rounded-full w-44 mt-5"
                                               value="Suivant"/>
                                    )}

                                    {isCodeLoading ? (
                                        <button type="button" className="btn btn-secondary rounded-full w-44 mt-5">
                                            <span className="loading loading-spinner"></span>
                                            Chargement
                                        </button>
                                    ) : (
                                        <button type="button" onClick={resendCode}
                                                className="btn btn-secondary rounded-full w-44 mt-5">
                                            Envoyer un autre code
                                        </button>
                                    )}

                                    <Link to="/" className="link link-primary text-xl mt-5">Accueil</Link>
                                </div>
                            </form>
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <h2 className="text-center">Réinitialisation</h2>
                            <p className="text-center">Surnom: <span className="underline">{user.username}</span></p>
                            <p className="mb-5 text-center">Créez un nouveau mot de passe pour le réinitialiser.</p>
                            <form onSubmit={handlePasswordRecover} className="w-full">
                                <label className="form-control block w-full">
                                    <div className="label">
                                        <span className="label-text text-lg">Nouveau mot de passe</span>
                                    </div>
                                    <input name="newPassword1" type="password" placeholder="Tapez ici"
                                           value={formData.newPassword1}
                                           onChange={handleChange}
                                           className="input input-bordered input-primary w-full rounded-full block"/>
                                </label>
                                {errors.newPassword1 && (
                                    <div className="flex justify-start items-center gap-1">
                                        <FiAlertCircle size={24} className="text-red-500"/>
                                        <p className="text-red-500 text-lg">{errors.newPassword1}</p>
                                    </div>
                                )}

                                <label className="form-control block w-full mt-5">
                                    <div className="label">
                                        <span className="label-text text-lg">Confirmez le nouveau mot de passe</span>
                                    </div>
                                    <input name="newPassword2" type="password" placeholder="Tapez ici"
                                           value={formData.newPassword2}
                                           onChange={handleChange}
                                           className="input input-bordered input-primary w-full rounded-full block"/>
                                </label>
                                {errors.newPassword2 && (
                                    <div className="flex justify-start items-center gap-1">
                                        <FiAlertCircle size={24} className="text-red-500"/>
                                        <p className="text-red-500 text-lg">{errors.newPassword2}</p>
                                    </div>
                                )}

                                <div className="flex flex-col justify-start items-center">
                                    {isLoading ? (
                                        <button className="btn btn-primary rounded-full w-52 mt-5">
                                            <span className="loading loading-spinner"></span>
                                            Chargement
                                        </button>
                                    ) : (
                                        <input type="submit" className="btn btn-primary rounded-full w-52 mt-5"
                                               value="Réinitialiser le mot de passe"/>
                                    )}

                                    <Link to="/" className="link link-primary text-xl mt-5">Accueil</Link>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
            <Footer/>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>Code de validation renvoyé</span>
                </div>
            </div>
        </div>
    )
}

export default ForgottenPassword

const validateEmail = (formData) => {
    const errors = {}

    const emailRegex = new RegExp("^((?!\\.)[\\w-_.]*[^.])(@\\w+)(\\.\\w+(\\.\\w+)?[^.\\W])$")

    if (!formData.email) {
        errors.email = "Ce champ est requis."
    } else if (!emailRegex.test(formData.email)) {
        errors.email = "Adresse courriel invalide."
    }

    return errors
}

const validateCode = (formData) => {
    const errors = {}

    if (!formData.code) {
        errors.code = "Ce champ est requis."
    }

    return errors
}

const validateNewPasswords = (formData) => {
    const errors = {}

    const passwordRegex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')

    if (!formData.newPassword1) {
        errors.newPassword1 = "Ce champ est requis."
    } else if (!passwordRegex.test(formData.newPassword1)) {
        errors.newPassword1 = "Le mot de passe doit contenir au moins 8 caractères dont une lettre majuscule, une lettre minuscule et un chiffre."
    }

    if (!formData.newPassword2) {
        errors.newPassword2 = "Ce champ est requis."
    } else if (formData.newPassword1 !== formData.newPassword2) {
        errors.newPassword2 = "Les mots de passe ne correspondent pas."
    }

    return errors
}