import React, {useContext, useEffect, useState} from "react"
import Footer from "../components/Footer";
import axios from "axios";

import {AuthContext} from "../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import Header from "../components/Header";
import Reports from "../components/Reports";
import {IoMdArrowRoundBack} from "react-icons/io";
import ModActions from "../components/ModActions";

const ControlPanel = () => {
    const navigate = useNavigate()
    const {authUser, setAuthUser} = useContext(AuthContext)

    useEffect(() => {
        document.title = "Panneau de contrôle | RetroRealm"

        if (authUser.userType === "STANDARD") {
            navigate("/403")
        }
    }, [])

    return (
        <>
            <Header />
            <section className="container">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56}
                                                                                                               className="text-white"/></Link>
                    <h2 className="text-5xl">Panneau de contrôle</h2>
                    <p className="mr-14"></p>
                </div>

                <div role="tablist" className="tabs tabs-bordered tabs-lg">
                    <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Rechercher"
                           defaultChecked/>
                    <div role="tabpanel" className="tab-content p-10">
                        <ModActions/>
                    </div>

                    <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Signalements"/>
                    <div role="tabpanel" className="tab-content p-10">
                        <Reports/>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default ControlPanel