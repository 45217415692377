import {useNavigate} from "react-router-dom"
import {useState} from "react"
import axios from "axios"


const useTreatGameInvitations = () => {
    const navigate = useNavigate()

    const [title, setTitle] = useState("")

    const createGameInvitation = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            await axios.post(API_URL + '/game-invitation', {senderId: senderId, receiverId: receiverId, gameTitle: title}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const deleteGameInvitation = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.delete(API_URL + `/game-invitation/delete/${senderId}/${receiverId}`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            return response.status === 201
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const handleJoin = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/game-invitation/accept/${senderId}/${receiverId}`, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const handleDecline = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/game-invitation/decline/${senderId}/${receiverId}`, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const createGame = async (senderId, receiverId, game) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/game`, {
                players: [senderId, receiverId],
                game: game
            }, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 201) {
                navigate(`/play/${game.toLowerCase()}/${response.data._id}`)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    return {createGameInvitation, deleteGameInvitation, handleJoin, handleDecline, createGame, title, setTitle}
}

export default useTreatGameInvitations