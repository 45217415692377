import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ThreadForm = ({ updateThread, thread, categoryId, categories, onClose, user }) => {
    const [formData, setFormData] = useState({
        title: thread ? thread.title : '',
        message: thread ? thread.message : '',
        category: thread ? thread.category : categoryId ? categoryId : '',
        user: user
    });
    const [errors, setErrors] = useState({ title: '', message: '', category: '' });
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

    const validate = () => {
        const newErrors = { title: '', message: '', category: '' };
        let isValid = true;

        if (formData.title.length === 0) {
            newErrors.title = 'Champ obligatoire';
            isValid = false;
        }
        else if (formData.title.length < 2) {
            newErrors.title = 'Le titre doit contenir au moins 2 caractères';
            isValid = false;
        }
        else if (formData.title.length > 50) {
            newErrors.title = 'Le titre ne doit pas dépasser 50 caractères';
            isValid = false;
        }
        if (formData.message.length === 0) {
            newErrors.message = 'Champ obligatoire';
            isValid = false;
        }
        if (formData.message.length > 500) {
            newErrors.message = 'Le message ne doit pas dépasser 500 caractères';
            isValid = false;
        }
        if (!formData.category) {
            newErrors.category = 'Champ obligatoire';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        if (thread) {
            setFormData({ title: thread.title, message: thread.message, category: thread.category, user: thread.user });
        } else if (categoryId) {
            setFormData((prevData) => ({ ...prevData, category: categoryId }));
        }
    }, [thread, categoryId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({title: '', message: '', category: '' })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate())
            return

        try {
            if (thread) {
                const response = await axios.put(`${API_URL}/forum/threads/${thread._id}`, formData, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("auth-user"))}`}
                });
                updateThread(response.data);
                onClose(true, false);
            } else {
                const response = await axios.post(`${API_URL}/forum`, formData, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
                });
                navigate(`/forum/threads/${response.data._id}`)
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <dialog id={thread ? 'edit_thread_modal' : 'thread_modal'} className="modal">
            <div className="modal-box bg-neutral text-white">
                <form method="dialog" className="absolute right-2 top-2" onClick={onClose}>
                    <button className="btn btn-sm btn-circle btn-ghost text-white">✕</button>
                </form>
                <h3 className="font-bold text-lg text-white">{thread ? 'Modifier' : 'Ajouter'} une discussion</h3>
                <form onSubmit={handleSubmit} className="mt-4" noValidate>
                    <label htmlFor="title" className="block font-semibold text-white">
                        Titre*
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Entrez un titre"
                        value={formData.title}
                        onChange={handleChange}
                        className="input input-bordered w-full mt-2 bg-neutral text-white mb-2"
                        required
                    />
                    {errors.title && <p className="text-red-500">{errors.title}</p>}
                    <label htmlFor="message" className="block font-semibold text-white">
                        Message*
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        placeholder="Entrez un message"
                        value={formData.message}
                        onChange={handleChange}
                        className="textarea textarea-bordered h-32 w-full my-2 bg-neutral text-white"
                        required
                    />
                    {errors.message && <p className="text-red-500">{errors.message}</p>}
                    <label htmlFor="category" className="block font-semibold text-white">
                        Catégorie*
                    </label>
                    <select
                        name="category"
                        id="category"
                        className="select select-bordered w-full max-w-xs mb-2"
                        value={formData.category}
                        onChange={handleChange}
                    >
                        {!categoryId &&
                            <option className="opacity-80" value="" disabled>Sélectionnez une catégorie</option>}
                    {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    {errors.category && <p className="text-red-500">{errors.category}</p>}
                    <div className="modal-action">
                        <button type="submit" className="btn btn-primary font-bold">
                            {thread ? 'Mettre à jour' : 'Ajouter'}
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    );
};

export default ThreadForm;
