import React, {useState, useEffect, useContext, useRef} from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {
    BiSolidSend,
    BiSolidLike,
    BiSolidDislike,
    BiSolidMessageDetail,
    BiDotsVerticalRounded, BiX
} from "react-icons/bi";
import {AuthContext} from "../contexts/AuthContext";
import Header from "../components/Header";
import Footer from "../components/Footer"
import ForumReply from "../components/ForumReply";
import {format} from 'date-fns';
import {frCA} from 'date-fns/locale';
import {IoIosWarning} from "react-icons/io";
import ReportModal from "../components/ReportModal";

const Thread = () => {
    const {threadId} = useParams();
    const {authUser} = useContext(AuthContext);
    const [formDataReply, setFormDataReply] = useState({
        message: '',
        user: authUser._id,
        forumThread: threadId
    });
    const [formData, setFormData] = useState({
        title: '',
        message: '',
        category: '',
        user: ''
    });
    const [errorReply, setErrorReply] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFormActivated, setIsFormActivated] = useState(false);
    const [deleteButtonText, setDeleteButtonText] = useState("Supprimer");
    const [newThread, setNewThread] = useState({})
    const [forumThread, setForumThread] = useState({});
    const [forumReplies, setForumReplies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [mentionedUsers, setMentionedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [likesCount, setLikesCount] = useState(0);
    const [dislikesCount, setDislikesCount] = useState(0);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Forum | RetroRealm";
    }, []);

    useEffect(() => {
        const fetchThread = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${API_URL}/forum/threads/${threadId}`, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
                });
                setForumThread(response.data);
                setNewThread({
                    title: response.data.title,
                    message: response.data.message,
                    category: response.data.category
                })
                setForumReplies(response.data.replies);
                setLikesCount(response.data.likes);
                setDislikesCount(response.data.dislikes);

                const mentionedUsernames = getAllMentionedUsernames(response.data.replies);
                if (mentionedUsernames.length > 0) {
                    await fetchMentionedUsers(mentionedUsernames);
                }

                const userResponse = await axios.get(`${API_URL}/user/${authUser._id}`, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
                });

                setLiked(userResponse.data.likedThreads.includes(threadId));
                setDisliked(userResponse.data.dislikedThreads.includes(threadId));
            } catch (error) {
                console.log(error);
                switch (error.response.status) {
                    case 400:
                        navigate("/400")
                        break
                    case 401:
                        localStorage.removeItem("token")
                        navigate("/401")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate('/404')
                        break
                    case 409:
                        navigate("/409")
                        break
                    case 422:
                        navigate('/422')
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
                setForumThread({});
            } finally {
                setLoading(false);
            }
        };

        fetchThread();
    }, [threadId, authUser._id]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_URL}/forum/categories`, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            setCategories(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getAllMentionedUsernames = (replies) => {
        const mentionRegex = /@(\w+)/g;
        const usernames = new Set();

        replies.forEach(reply => {
            let match;
            while ((match = mentionRegex.exec(reply.message)) !== null) {
                usernames.add(match[1]);
            }
        });

        return Array.from(usernames);
    };

    const fetchMentionedUsers = async (usernames) => {
        try {
            const response = await axios.post(`${API_URL}/user/mentions`, {usernames}, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            const users = response.data.users;

            const userMap = users.reduce((map, user) => {
                map[user.username] = user;
                return map;
            }, {});

            setMentionedUsers(userMap);
        } catch (error) {
            console.log(error);
        }
    };

    const activateEditForm = () => {
        fetchCategories();
        setFormData({
            title: forumThread.title,
            message: forumThread.message,
            category: forumThread.category._id,
            user: forumThread.user._id
        });
        setIsFormActivated(true);
    }

    const confirmDelete = async () => {
        if (deleteButtonText === 'Supprimer') {
            setDeleteButtonText('Confirmer');
        } else {
            try {
                await axios.delete(`${API_URL}/forum/threads/${threadId}`, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
                });
                navigate(`/forum`);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const deleteReply = (replyId) => {
        setForumReplies((prevReplies) =>
            prevReplies.filter((reply) => reply._id !== replyId)
        );
    };

    const handleInput = (e) => {
        const target = e.target;
        target.style.height = '3rem';
        target.style.height = `${target.scrollHeight}px`;
        handleChangeReply(e);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmitReply(e);
        }
    };

    const handleChangeReply = (e) => {
        setFormDataReply({...formDataReply, [e.target.name]: e.target.value});
        setErrorReply(false);
    };

    const handleSubmitReply = async (e) => {
        e.preventDefault();

        if (formDataReply.message.length === 0 || formDataReply.message.length > 500) {
            setErrorReply(true);
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/forum/replies`, formDataReply, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            //notif mention ?
            document.getElementById('message').style.height = '3rem';
            setForumReplies([...forumReplies, response.data]);
            setFormDataReply({...formDataReply, message: ''});
        } catch (error) {
            console.error(error);
        }
    };

    const handleThreadLike = async (threadId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/threads/${threadId}/like`, {}, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    const handleThreadDislike = async (threadId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/threads/${threadId}/dislike`, {}, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    const validate = () => {
        const newErrors = {title: '', message: '', category: ''};
        let isValid = true;

        if (formData.title.length === 0) {
            newErrors.title = 'Champ obligatoire.';
            isValid = false;
        } else if (formData.title.length < 2) {
            newErrors.title = 'Le titre doit contenir au moins 2 caractères.';
            isValid = false;
        } else if (formData.title.length > 50) {
            newErrors.title = 'Le titre ne doit pas dépasser 50 caractères.';
            isValid = false;
        }
        if (formData.message.length === 0) {
            newErrors.message = 'Champ obligatoire.';
            isValid = false;
        }
        if (formData.message.length > 500) {
            newErrors.message = 'Le message ne doit pas dépasser 500 caractères';
            isValid = false;
        }
        if (!formData.category) {
            newErrors.category = 'Champ obligatoire';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleThreadEdit = async (e) => {
        e.preventDefault();
        if (!validate())
            return;
        try {
            await axios.put(`${API_URL}/forum/threads/${threadId}`, formData, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            setIsFormActivated(false);
            const selectedCategory = categories.find(cat => cat._id === formData.category);
            setNewThread({
                title: formData.title,
                message: formData.message,
                category: selectedCategory
            })
        } catch (error) {
            console.error(error);
        }
    }

    const handleThreadChange = (e) => {
        const {name, value} = e.target;

        if (name === 'category') {
            setFormData({...formData, category: value});
        } else {
            setFormData({...formData, [name]: value});
        }
    };

    const handleReplyTo = (username) => {
        setFormDataReply((prevData) => ({
            ...prevData,
            message: prevData.message ? prevData.message + ` @${username} ` : prevData.message + `@${username} `,
        }));
    }

    if (loading) {
        return (
            <div className="min-h-screen flex flex-col justify-between">
                <Header/>
                <main className="container flex-grow">
                    <p>Chargement...</p>
                </main>
                <Footer/>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <main className="container flex-grow">
                <Link to={'/forum'}><h2 className="pixel-font my-4 text-5xl inline-block">Forum</h2></Link>
                <div>
                    {isFormActivated ? (
                        <form onSubmit={handleThreadEdit} className="rounded-2xl border-4 border-secondary w-full"
                              noValidate>
                            <div
                                className="flex flex-row self-center h-20 bg-secondary p-3 rounded-t font-bold">

                                <select
                                    name="category"
                                    id="category"
                                    className="select select-bordered me-2"
                                    value={formData.category}
                                    onChange={handleThreadChange}
                                >
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                                {errors.category && <p className="text-red-700">{errors.category}</p>}
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    value={formData.title}
                                    onChange={handleThreadChange}
                                    className="input input-bordered w-full me-2 text-xl"
                                    required
                                />
                                {errors.title && <p className="text-red-700">{errors.title}</p>}
                                <button type="button" className="me-2 text-black"
                                        onClick={() => setIsFormActivated(false)}><BiX
                                    size={42}/></button>

                            </div>
                            <div className="bg-secondary bg-opacity-10">
                                <div className="flex justify-between p-4">
                                    {forumThread.user && (
                                        <Link to={`/profile/${forumThread.user._id}`}
                                              className="inline-flex items-center align-middle hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                                            <img
                                                className="rounded-full w-16 ring-2 ring-white ring-opacity-65"
                                                src={`https://robohash.org/${forumThread.user.pfpFileName}?set=set2`}
                                                alt={forumThread.user.username}
                                            />
                                            <span className="text-xl leading-9 ms-4">{forumThread.user.username}</span>
                                        </Link>
                                    )}
                                    <div className="self-center">
                                        {forumThread.createdAt && (
                                            <p>{format(new Date(forumThread.createdAt), "'Publié le' d MMMM yyyy 'à' H'h'mm", {locale: frCA})}</p>
                                        )}
                                        {forumThread.modifiedAt > forumThread.createdAt && (
                                            <p>{format(new Date(forumThread.modifiedAt), "'Modifié le' d MMMM yyyy 'à' H'h'mm", {locale: frCA})}</p>
                                        )}
                                    </div>
                                </div>
                                <hr className="opacity-50 mb-4 mx-4"/>
                                <div className="flex flex-col">
                                <textarea
                                    name="message"
                                    id="message"
                                    value={formData.message}
                                    onChange={handleThreadChange}
                                    className="textarea textarea-bordered text-lg w-11/12 h-32 my-2 ms-3"
                                    required
                                />
                                    {errors.message && <p className="text-red-500 ms-4">{errors.message}</p>}
                                    <button type="submit"
                                            className="ms-3 my-3 hover:opacity-80 ease-in-out duration-300 delay-300">
                                        <BiSolidSend className="text-accent" size={32}/>
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="rounded-2xl border-4 border-secondary w-full">
                            <div
                                className="flex flex-row self-center h-16 bg-secondary p-3 rounded-t text-black font-bold">

                                {newThread && newThread.category && (
                                    <div
                                        className="badge badge-primary border-2 border-accent-content font-bold whitespace-nowrap h-9 text-xl">
                                        {newThread.category.name}
                                    </div>
                                )}
                                <h3 className="ms-4 truncate text-clip">{newThread.title}</h3>
                                {(authUser.userType === "ADMIN" || authUser.userType === "MOD") && (
                                    <button tabIndex={0}
                                            className="dropdown ms-auto">
                                        <BiDotsVerticalRounded
                                            className="hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"
                                            size={32}/>
                                        <ul tabIndex={0}
                                            className="dropdown-content menu bg-base-300 text-white font-normal rounded-box z-[1] w-52 p-2 shadow">
                                            <li><span onClick={() => activateEditForm()}>Modifier</span></li>
                                            <li><span className="text-red-600"
                                                      onClick={() => confirmDelete()}>{deleteButtonText}</span></li>
                                        </ul>
                                    </button>
                                )}

                            </div>
                            <div className="bg-secondary bg-opacity-10">
                                <div className="flex justify-between p-4">
                                    {forumThread.user && (
                                        <Link to={`/profile/${forumThread.user._id}`}
                                              className="inline-flex items-center align-middle hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                                            <img
                                                className="rounded-full w-16 ring-2 ring-white ring-opacity-65"
                                                src={`https://robohash.org/${forumThread.user.pfpFileName}?set=set2`}
                                                alt={forumThread.user.username}
                                            />
                                            <span className="text-xl leading-9 ms-4">{forumThread.user.username}</span>
                                        </Link>
                                    )}
                                    <div className="self-center">
                                        {forumThread.createdAt && (
                                            <p>{format(new Date(forumThread.createdAt), "'Publié le' d MMMM yyyy 'à' H'h'mm", {locale: frCA})}</p>
                                        )}
                                        {forumThread.modifiedAt > forumThread.createdAt && (
                                            <p>{format(new Date(forumThread.modifiedAt), "'Modifié le' d MMMM yyyy 'à' H'h'mm", {locale: frCA})}</p>
                                        )}
                                    </div>
                                </div>
                                <hr className="opacity-50 mb-4 mx-4"/>
                                <p className="text-xl mx-4">{newThread.message}</p>
                                <div id="threadButtons"
                                     className="flex align-middle justify-between rounded-b-2xl bg-gradient-to-t from-gray-900 from-70% to-blue-950 mt-8 p-4 pt-6">
                                    <div className="flex">
                                        <BiSolidMessageDetail className="mt-1" size={32}/>
                                        {forumThread.replies && (
                                            <span className="leading-9 ms-1 me-2">{forumThread.replies.length}</span>
                                        )}
                                    </div>
                                    <div className="flex align-middle">
                                        <button
                                            className={`${liked ? 'text-primary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300'}`}
                                            onClick={() => handleThreadLike(forumThread._id)}>
                                            <BiSolidLike size={32}/>
                                        </button>
                                        <span className="leading-9 ms-1 me-2">{likesCount}</span>
                                        <button
                                            className={`${disliked ? 'text-secondary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300'}`}
                                            onClick={() => handleThreadDislike(forumThread._id)}>
                                            <BiSolidDislike size={32}/>
                                        </button>
                                        <span className="leading-9 ms-1 me-2">{dislikesCount}</span>
                                        <button onClick={() => {document.getElementById('modal_report').showModal()}}
                                            className="text-yellow-500 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300">
                                            <IoIosWarning size={32}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="rounded-2xl border-4 border-accent w-full mt-3">
                        <div className="flex flex-row self-center h-16 bg-accent p-3 rounded-t text-black font-bold">
                            <h4 className="leading-9">Réponses</h4>
                        </div>
                        <div className="bg-secondary bg-opacity-10">
                            <div className="flex flex-row w-full">
                                <form className="flex items-center w-full" onSubmit={handleSubmitReply} noValidate>
                                    <h5 className="p-4 py-5 whitespace-nowrap">Répondre à la discussion :</h5>
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Saisissez votre message..."
                                        value={formDataReply.message}
                                        onInput={handleInput}
                                        onKeyDown={handleKeyDown}
                                        className={`textarea textarea-bordered text-lg h-12 my-2 flex-grow bg-neutral text-white resize-none ${errorReply ? 'border-red-500' : ''}`}
                                        required
                                    />
                                    <button type="submit"
                                            className="mx-4 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300">
                                        <BiSolidSend className="text-accent" size={32}/>
                                    </button>
                                </form>
                            </div>
                        </div>
                        <ul>
                            {forumReplies.length > 0 ? (
                                forumReplies.map((forumReply) => (
                                    <ForumReply
                                        key={forumReply._id}
                                        id={forumReply._id}
                                        forumThread={forumThread._id}
                                        message={forumReply.message}
                                        createdAt={forumReply.createdAt}
                                        modifiedAt={forumReply.modifiedAt}
                                        user={forumReply.user}
                                        currentUser={authUser}
                                        isLiked={forumReply.isLiked}
                                        isDisliked={forumReply.isDisliked}
                                        likes={forumReply.likes}
                                        dislikes={forumReply.dislikes}
                                        mentionedUsers={mentionedUsers}
                                        replyTo={handleReplyTo}
                                        deleteReply={deleteReply}
                                    />
                                ))
                            ) : (
                                <li className="p-4 text-center text-xl">Aucune réponse</li>
                            )}
                        </ul>
                    </div>
                </div>
            </main>

            <ReportModal user={forumThread.user} proof={{type: 'FORUM-THREAD', postId: forumThread._id}} />
        </div>
    );
};

export default Thread;
