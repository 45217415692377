import React, {useContext, useEffect, useState} from "react";
import {FaArrowUp, FaArrowDown} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {AuthContext} from "../contexts/AuthContext";
import {SocketContext} from "../contexts/SocketContext";
import OdysseyCard from "./OdysseyCard";
import {MapInteractionCSS} from "react-map-interaction";
import OdysseyPawn from "./OdysseyPawn";
import {getCardProperties, validatePawnMovement} from "../utils/odysseyCardActions";
import {TbCardsFilled} from "react-icons/tb";

const OdysseyGame = ({gameId}) => {
    const {authUser} = useContext(AuthContext);
    const {socket} = useContext(SocketContext)

    const [myPlayerId, setMyPlayerId] = useState(0);
    const [currentGame, setCurrentGame] = useState({});
    const [opponent, setOpponent] = useState({});
    const [myHand, setMyHand] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [opponentHand, setOpponentHand] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [isLoading, setIsLoading] = useState(true);

    const [cardToPlay, setCardToPlay] = useState(-1);
    const [pawnToPlay, setPawnToPlay] = useState(-1);
    const [pawnToTP, setPawnToTP] = useState(-1);

    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")

    const [hasWon, setHasWon] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        socket?.on('turn-played', (data) => {
            getGameData()
            console.log("Got the socket UwU");
        })

        return () => socket?.off('turn-played')
    }, [socket])

    useEffect(() => {
        socket?.on('refuse-endgame', (data) => {
            showPopupTip("Demande d'abandon refusée.")
        })

        return () => socket?.off('refuse-endgame')
    }, [socket])

    useEffect(() => {
        socket?.on('ask-endgame', (data) => {
            document.getElementById("giveupModal").showModal();
        })

        return () => socket?.off('ask-endgame')
    })

    useEffect(() => {
        socket?.on('confirm-endgame', (args) => {
            if (args.win === null) {
                setHasWon(null)
            } else {
                setHasWon(args.win)
            }
            document.getElementById('endgameModal').showModal()
        })

        return () => socket?.off('confirm-endgame')
    }, [socket])

    useEffect(() => {
        setIsLoading(true)
        getGameData()
        // setInterval(getGameData, 15000);
    }, []);

    useEffect(() => {
        playTurn()
    }, [cardToPlay, pawnToPlay, pawnToTP])


    const showPopupTip = (msg) => {
        setToastMessage(msg)
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
    }

    const playTurn = async () => {
        if (cardToPlay === -1 || pawnToPlay === -1) {
            return;
        }

        if (currentGame.playerTurn !== myPlayerId) return;

        let tempArray = [...myHand]
        let newTurnMessage = ""
        let tempGame = {...currentGame};

        let cardProperties = getCardProperties(tempArray[cardToPlay]);
        if (cardProperties.value === 6 && pawnToTP === -1) return;

        let indexOfCardPlayed = myHand.indexOf(cardProperties.value);
        if (indexOfCardPlayed !== -1) {
            tempGame.playedStack.push(cardProperties.value)
            tempArray.splice(indexOfCardPlayed, 1)
        }
        newTurnMessage = `${authUser.username} a joué [${cardProperties.name}]`
        setMyHand(tempArray)

        tempGame.hands[myPlayerId] = tempArray;
        tempGame.turnMessage = newTurnMessage

        if (cardProperties.value === 6) {
            tempGame.data.pawns = await cardProperties.action(pawnToPlay, pawnToTP, tempGame.data.pawns, false)
        } else {
            tempGame.data.pawns = await cardProperties.action(cardProperties.value, pawnToPlay, tempGame.data.pawns, false)
        }


        await endTurn(tempGame)
    }

    const endTurn = async (gameToSave) => {
        if ((gameToSave.hands[0].length + gameToSave.hands[1].length) === 0) {
            gameToSave = await giveCards()
        }

        if (myPlayerId === 0) {
            if (gameToSave.hands[1].length === 0) {
                console.log("SPECIAL")
                gameToSave.playerTurn = 0;
            } else {
                gameToSave.playerTurn = 1
            }
        } else {
            if (gameToSave.hands[0].length === 0) {
                console.log("SPECIAL")
                gameToSave.playerTurn = 1;
            } else {
                gameToSave.playerTurn = 0
            }
        }


        setPawnToPlay(-1)
        setCardToPlay(-1)
        setPawnToTP(-1)

        const hasP1Won = (gameToSave.data.pawns[0].isFinished && gameToSave.data.pawns[2].isFinished)
        const hasP2Won = (gameToSave.data.pawns[1].isFinished && gameToSave.data.pawns[3].isFinished)


        if (hasP1Won) {
            gameToSave.data.winner = 0
        } else if (hasP2Won) {
            gameToSave.data.winner = 1
        } else {
            gameToSave.data.winner = null
        }


        console.log("Saving...")
        setCurrentGame(gameToSave)
        await saveGameData(gameToSave)
        await getGameData()

    }


    const dropCards = async () => {
        if (currentGame.playerTurn !== myPlayerId) return;
        for (let c = 0; c < myHand.length; c++) {
            let canPlay = true
            if (myPlayerId === 0) {
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 0, 1, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 0, 3, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 2, 1, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 2, 3, currentGame.data.pawns))) canPlay = false
            } else {
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 1, 0, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 1, 0, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 3, 2, currentGame.data.pawns))) canPlay = false
                if (!(await validatePawnMovement(getCardProperties(myHand[c]).value, 3, 2, currentGame.data.pawns))) canPlay = false
            }
            if (canPlay) {
                showPopupTip("Vous avez des cartes à jouer")
                return
            }
        }

        const tempGame = {...currentGame}
        let count = myHand.length
        for (let i = 0; i < count; i++) {
            tempGame.playedStack.push(myHand.pop())
        }

        if (myPlayerId === 1) {
            tempGame.playerTurn = 0
        } else {
            tempGame.playerTurn = 1
        }

        tempGame.turnMessage = `${authUser.username} a jeté ses cartes`

        await endTurn(tempGame)
    }


    const giveCards = async () => {
        let tempGame = {...currentGame};
        if (tempGame.drawStack.length < 8) {
            const nbCardsToSwap = tempGame.playedStack.length
            for (let i = 0; i < nbCardsToSwap; i++) {
                tempGame.drawStack.push(tempGame.playedStack.pop());
            }
            tempGame.drawStack = shuffleCards(tempGame.drawStack);
        }

        for (let i = 0; i < 4; i++) {
            tempGame.hands[0].push(tempGame.drawStack.pop())
            tempGame.hands[1].push(tempGame.drawStack.pop())
        }
        setCurrentGame(tempGame);
        setMyHand(tempGame.hands[myPlayerId]);
        await saveGameData(tempGame)
        await getGameData()

        return tempGame
    }

    function shuffleCards(stack) {
        let currentIndex = stack.length;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {

            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [stack[currentIndex], stack[randomIndex]] = [
                stack[randomIndex], stack[currentIndex]];
        }
        return stack;
    }

    const saveGameData = async (gameData) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.put(url, gameData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const getGameData = async () => {

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                const game = response.data
                setCurrentGame(game)

                if (game.players[0].username === authUser.username) {
                    // Si joueur 0
                    setMyPlayerId(0)
                    setMyHand(game.hands[0])
                    setOpponent(game.players[1])
                    setOpponentHand(game.hands[1])
                } else {
                    // Si joueur 1
                    setMyPlayerId(1)
                    setMyHand(game.hands[1])
                    setOpponent(game.players[0])
                    setOpponentHand(game.hands[0])
                }

                if (game.data.winner !== null) {
                    setHasWon(game.data.winner === myPlayerId)
                    document.getElementById('endgameModal').showModal()
                    if (game.data.winner === myPlayerId) {
                        await winGame()
                    }
                }

            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const endGame = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                navigate("/games")
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const askEndGame = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/ask-endgame/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.post(url, {playerId: myPlayerId}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                navigate("/games")
                //showPopupTip("Demande d'abandon envoyée.")
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const confirmEndgame = async (gameClaimed) => {
        document.getElementById("giveupModal").close()

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/confirm-endgame/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.post(url, {playerId: myPlayerId, gameClaimed: gameClaimed}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const winGame = async () => {

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/win-endgame/${gameId}`

            console.log("Fetching... " + url)
            const response = await axios.post(url, {playerId: myPlayerId}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }



    const playPawn = async (pawn) => {
        if (pawn % 2 === myPlayerId) {
            if (pawnToPlay !== -1 || cardToPlay === -1) return;

            let canBePlayed = true;
            if ((await getCardProperties(myHand[cardToPlay])).value !== 6) {
                canBePlayed = await validatePawnMovement(myHand[cardToPlay], pawn, -1, currentGame.data.pawns)

            }
            if (canBePlayed) {
                setPawnToPlay(pawn)
            }
        } else {
            if (currentGame.data.pawns[pawn].currentTile === -1) return;
            if (cardToPlay === -1) return;
            if ((await getCardProperties(myHand[cardToPlay])).value !== 6) return;
            setPawnToTP(pawn)
        }
    }

    const playCard = (card) => {
        // if (cardToPlay !== null) return;
        setPawnToTP(-1)
        setPawnToPlay(-1)
        setCardToPlay(card)
    }


    return (
        <>
            {isLoading ? (
                <div>
                    LOADING
                </div>
            ) : (
                <div className="flex flex-col justify-between items-center gap-4 h-full odyssey py-4 relative">
                    <img src={require('../images/logo-odyssey.png')} alt="Odyssey logo" className="w-1/5 absolute top-10 left-10" />
                    <div className="flex justify-center items-center">
                        <div className="flex justify-center items-center">
                            {opponentHand.sort().map((cardValue, index) => (
                                <OdysseyCard key={"oppCards-" + index} value={cardValue} callback={() => {
                                }}
                                             id={index} canBePlayed={false} hidden={true}
                                             isPlayer1={myPlayerId % 2 === 0}/>
                            ))}
                        </div>
                    </div>

                    <div className="flex-grow flex justify-around w-full items-center">
                        <section className="odyssey-lastplay w-1/5 flex justify-center items-center">
                            <div>
                                <p className={"text-center text-3xl"}>{currentGame.turnMessage}</p>
                            </div>
                        </section>

                        <section className="odyssey-board rounded-full flex justify-center items-center">
                            <div className="odyssey-tiles">
                                <MapInteractionCSS scale={1.25} disablePan={true} disableZoom={true}>
                                    {currentGame.data.pawns.map((pawn, i) => (
                                        <OdysseyPawn key={"pawn-" + i} isFinished={pawn.isFinished} player={myPlayerId}
                                                     id={i} currentTile={pawn.currentTile} callback={playPawn}/>
                                    ))}
                                </MapInteractionCSS>
                            </div>
                        </section>

                        <section className="odyssey-turn w-1/5 flex justify-center items-center">
                            <div>
                                {currentGame.playerTurn !== myPlayerId && <FaArrowUp size={64}/>}
                                <p className={"text-center text-4xl"}>Tour</p>
                                {currentGame.playerTurn === myPlayerId && <FaArrowDown size={64}/>}
                            </div>
                        </section>
                    </div>


                    <div className="flex justify-center items-center gap-16">
                        <div className={"flex justify-center items-center"}>
                            <button className="btn btn-primary btn-lg rounded-full h-40 w-40" onClick={dropCards}>
                                <div className="flex justify-center items-center flex-col gap-2">
                                    <TbCardsFilled size={56}/>
                                    Jeter les cartes
                                </div>
                            </button>
                        </div>

                        <div className="flex justify-center items-center">
                            {myHand.sort().map((cardValue, index) => (
                                <OdysseyCard key={"myCards-" + index} value={cardValue} callback={playCard}
                                             canBePlayed={currentGame.playerTurn === myPlayerId} id={index}
                                             isSelected={cardToPlay === index}
                                             hidden={false} isPlayer1={myPlayerId % 2 !== 0}/>
                            ))}
                        </div>

                        <div className={"flex flex-col justify-between h-full"}>
                            <button className="btn btn-primary btn-lg rounded-full w-40" onClick={() => {
                                document.getElementById("instructionModal").showModal()
                            }}>
                                Instructions
                            </button>
                            <button className="btn btn-error btn-lg rounded-full w-40" onClick={askEndGame}>
                                Quitter la partie
                            </button>
                        </div>
                    </div>
                </div>
            )

            }
            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-info">
                    <span>{toastMessage}</span>
                </div>
            </div>
            <dialog id="endgameModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-2xl text-center">Partie teminée!</h3>
                    {hasWon !== null ? (
                        <>
                            {hasWon ? (
                                <p className="py-4 text-center text-lg">Vous avez gagné la partie. Félicitations!</p>
                            ) : (
                                <p className="py-4 text-center text-lg">Vous avez perdu la partie. Dommage...</p>
                            )
                            }
                        </>
                        ) : (
                        <p className="py-4 text-center text-lg">Match nul</p>
                    )}
                    <div className="flex items-center justify-center">
                        <button className={"btn btn-primary"} onClick={endGame}>Retour à l'accueil</button>
                    </div>
                </div>
            </dialog>
            <dialog id="giveupModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-2xl text-center">Demande d'abandon</h3>
                    <p className="py-4 text-center text-lg">Votre adversaire a abandonné la partie. Souhaitez-vous
                        prendre la victoire?</p>
                    <div className="flex items-center justify-center gap-4">
                        <button className={"btn btn-primary"} onClick={async () => {await confirmEndgame(false)}}>Déclarer match nul</button>
                        <button className={"btn btn-primary"} onClick={async () => {await confirmEndgame(true)}}>Prendre la victoire</button>
                    </div>
                </div>
            </dialog>
            <dialog id="instructionModal" className="modal">
                <div className="modal-box h-3/5 max-h-6xl w-3/5 max-w-6xl p-10">
                    <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    </form>
                    <h3 className="font-bold text-4xl">Instructions</h3>
                    <h4 className="py-4 text-3xl">Description</h4>
                    <p className="py-4 text-2xl">
                        Originaire du Canada, le jeu de Toc reprend les principes de Trouble™, mais en y remplaçant,
                        pour le déplacement des pions, le lancer de dé par un jeu de cartes.
                    </p>
                    <p className="py-4 text-2xl">
                        Le but du jeu est d'être
                        le plus rapide à rentrer ses planètes au centre du système solaire.
                    </p>
                    <p className="py-4 text-2xl">
                        Au début du jeu, toutes les planètes se trouvent en dehors du jeu. À chaque tour, 4 cartes sont
                        distribuées à chaque joueur. Lorsque c'est son tour, chaque joueur doit jouer une carte pour
                        déplacer une planète. Pour faire rentrer un pion en jeu, il faut jouer un 1. La planète est
                        alors posé sur la case de départ du système solaire. Si le joueur ne possède pas de 1 et qu'il
                        ne peut avancer de planète, il doit jeter son paquet et attendre le prochain tour.
                    </p>

                    <h4 className="py-4 text-3xl">Cartes et déplacements</h4>
                    <p className="py-4 text-2xl">
                        Les planètes se déplacent en fonction de la valeur des cartes jouées :
                    </p>
                    <div className="py-4 text-2xl">
                        <ul>
                            <li className={"my-3"}><strong>1:</strong> Entre une planète en jeu ou fait avancer une
                                planète de 1 case.
                            </li>
                            <li className={"my-3"}><strong>2:</strong> Fait avancer une planète de 2 cases.</li>
                            <li className={"my-3"}><strong>3:</strong> Fait avancer une planète de 3 cases.</li>
                            <li className={"my-3"}><strong>4:</strong> Fait obligatoirement RECULER une planète de 4
                                cases (si la planète
                                venait juste de sortir et qu'elle recule, on considère le tour déjà fait; elle peut
                                donc rentrer au prochain coup au centre du système solaire).
                            </li>
                            <li className={"my-3"}><strong>5:</strong> Fait avancer une planète de 5 cases.</li>
                            <li className={"my-3"}><strong>Trou de Ver:</strong> Échange la position d'une planète avec
                                une planète de
                                l'adversaire.
                            </li>
                            <li className={"my-3"}><strong>Trou Noir:</strong> Fait avancer une planète de 7 cases et
                                élimine toutes les
                                planètes sur son passage.
                            </li>
                        </ul>
                    </div>
                    <p className="py-4 text-2xl">
                        Un joueur est toujours obligé de jouer une de ses cartes, même si cela le désavantage.
                    </p>
                    <p className="py-4 text-2xl">
                        Une planète arrivée au centre de son système solaire est protégée (elle ne peut être éliminée).
                    </p>
                    <p className="py-4 text-2xl">
                        Une planète peut dépasser d'autres planètes sans effet, sauf si le joueur a joué un Trou Noir:
                        la planète déplacée capture alors toutes les planètes sur son passage.
                    </p>
                    <h4 className="py-4 text-3xl">Élimination d'une planète</h4>
                    <p className="py-4 text-2xl">
                        L'élimination d'une planète se fait en finissant sur une case occupée par l'adversaire, la
                        planète capturée retourne alors dans son camp de départ et doit recommencer son parcours depuis
                        le début.
                    </p>
                    <p className="py-4 text-2xl">
                        Il peut arriver qu'un joueur élimine sa propre planète (puisqu'il est toujours obligé de jouer
                        ses cartes).
                    </p>
                    <p className="py-4 text-2xl">
                        Le Trou Noir permet d'avancer de 7 cases et d'éliminer toutes les planètes présentes sur son
                        passage, elles sont alors remises au départ.
                    </p>

                    <h4 className="py-4 text-3xl">Conditions de victoire</h4>
                    <p className="py-4 text-2xl">
                        Pour gagner la partie, un joueur doit faire entrer ses deux planètes au centre du système
                        solaire.
                    </p>
                    <p className="py-4 text-2xl">
                        Pour faire entrer une planète au centre du système solaire, celle-ci doit se trouver sur la
                        case directement en-dessous de l'entrée, et le joueur doit jouer un 1. Si la planète se trouve
                        sur cette case, mais que le joueur ne possède pas de 1, la planète doit recommencer son tour.
                    </p>
                </div>
            </dialog>
        </>
    )
}

export default OdysseyGame