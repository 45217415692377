import {calculateAge, displayFavouriteGame, displayGender} from "../utils/UtilMethods"
import React, {useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import {MdDeleteForever} from "react-icons/md"
import {useNavigate} from "react-router-dom"
import axios from "axios"
import {SocketContext} from "../contexts/SocketContext"


const ProfileFriends = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const {onlineUsers} = useContext(SocketContext)
    const [friendToDelete, setFriendToDelete] = useState({})

    const [showToast, setShowToast] = useState(false)

    const handleDelete = async () => {

        const updatedFriends = authUser.friends.filter(friend => friend._id !== friendToDelete._id);
        setAuthUser({
            ...authUser,
            friends: updatedFriends
        })

        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            await axios.post(API_URL + "/user/friends/delete", {
                authUserId: authUser._id,
                friendUserId: friendToDelete._id
            }, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }

        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
    }

    return (
        <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
            <h2 className="text-3xl mb-4">Vos amis</h2>
            {authUser.friends.length > 0 ? (
                authUser.friends.map((friend, i) => (
                    <div
                        className="bg-base-200 hover:bg-base-100 transition p-5 flex justify-between items-center mb-3 rounded-2xl  hover:cursor-pointer"
                        onClick={() => {navigate(`/profile/${friend._id}`)}}
                        key={i}>
                        <div className="flex justify-start items-center gap-5">
                            <div className={`avatar ${onlineUsers.includes(friend._id) ? 'online' : 'offline'}`}>
                                <div className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                    <img className="rounded-full"
                                         src={`https://robohash.org/${friend.pfpFileName}?set=set2`}
                                         alt="Photo de profil"/>
                                </div>
                            </div>
                            <h3>{friend.username}</h3>
                        </div>
                        <button onClick={(e) => {
                            e.stopPropagation()
                            document.getElementById('validationModal').showModal()
                            setFriendToDelete(friend)
                        }}>
                            <MdDeleteForever size={32} className="text-white"/>
                        </button>
                    </div>
                ))
            ) : (
                <p className="text-center text-xl">Vous n'avez pas encore d'amis</p>
            )}

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>Ami supprimé de la liste d'amis</span>
                </div>
            </div>

            <dialog id="validationModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-3xl">Supprimer un ami</h3>
                    <p className="py-4 text-xl">Souhaitez-vous vraiment supprimer cet utilisateur de votre liste
                        d'amis?</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button onClick={handleDelete} className="btn btn-primary mr-2 text-lg">Confirmer</button>
                            <button className="btn text-lg">Annuler</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default ProfileFriends