export const boardPositions1 = [
    { position: 0, x: 50, y: 1815, rotation: 0 },
    { position: 25, x: 50, y: 1710, rotation: 0 },
    { position: 50, x: 50, y: 1615, rotation: 0 },
    { position: 75, x: 50, y: 1530, rotation: 0 },
    { position: 100, x: 50, y: 1450, rotation: 0 },
    { position: 125, x: 50, y: 1375, rotation: 0 },
    { position: 150, x: 50, y: 1300, rotation: 0 },
    { position: 175, x: 50, y: 1225, rotation: 0 },
    { position: 200, x: 50, y: 1130, rotation: 0 },
    { position: 225, x: 50, y: 1050, rotation: 0 },
    { position: 250, x: 50, y: 970, rotation: 0 },
    { position: 275, x: 50, y: 890, rotation: 0 },
    { position: 300, x: 50, y: 800, rotation: 0 },
    { position: 325, x: 50, y: 710, rotation: 0 },
    { position: 350, x: 60, y: 610, rotation: 7 },
    { position: 375, x: 80, y: 500, rotation: 15 },
    { position: 400, x: 120, y: 350, rotation: 30 },
    { position: 425, x: 230, y: 200, rotation: 45 },
    { position: 450, x: 375, y: 100, rotation: 60 },
    { position: 475, x: 535, y: 35, rotation: 75 },
    { position: 500, x: 690, y: 10, rotation: 90 },
    { position: 525, x: 860, y: 20, rotation: 105 },
    { position: 550, x: 1050, y: 95, rotation: 120 },
    { position: 575, x: 1180, y: 200, rotation: 135 },
    { position: 600, x: 1270, y: 330, rotation: 150 },
    { position: 625, x: 1340, y: 480, rotation: 165 },
    { position: 650, x: 1350, y: 470, rotation: 170 },
    { position: 675, x: 1370, y: 540, rotation: 180 },
    { position: 700, x: 1370, y: 775, rotation: 180 },
    { position: 725, x: 1370, y: 850, rotation: 180 },
    { position: 750, x: 1370, y: 925, rotation: 180 },
    { position: 775, x: 1370, y: 1010, rotation: 180 },
    { position: 800, x: 1370, y: 1095, rotation: 180 },
    { position: 825, x: 1370, y: 1180, rotation: 180 },
    { position: 850, x: 1370, y: 1260, rotation: 180 },
    { position: 875, x: 1370, y: 1330, rotation: 180 },
    { position: 900, x: 1370, y: 1410, rotation: 180 },
    { position: 925, x: 1370, y: 1500, rotation: 180 },
    { position: 950, x: 1370, y: 1580, rotation: 180 },
    { position: 975, x: 1370, y: 1650, rotation: 180 },
    { position: 1000, x: 1370, y: 1740, rotation: 180 }
]

export const boardPositions2 = [
    { position: 0, x: 293, y: 1815, rotation: 0 },
    { position: 25, x: 293, y: 1705, rotation: 0 },
    { position: 50, x: 293, y: 1620, rotation: 0 },
    { position: 75, x: 293, y: 1530, rotation: 0 },
    { position: 100, x: 293, y: 1450, rotation: 0 },
    { position: 125, x: 293, y: 1375, rotation: 0 },
    { position: 150, x: 293, y: 1292, rotation: 0 },
    { position: 175, x: 293, y: 1210, rotation: 0 },
    { position: 200, x: 293, y: 1128, rotation: 0 },
    { position: 225, x: 293, y: 1047, rotation: 0 },
    { position: 250, x: 293, y: 965, rotation: 0 },
    { position: 275, x: 293, y: 890, rotation: 0 },
    { position: 300, x: 293, y: 810, rotation: 0 },
    { position: 325, x: 295, y: 725, rotation: 0 },
    { position: 350, x: 285, y: 635, rotation: 8 },
    { position: 375, x: 288, y: 535, rotation: 14 },
    { position: 400, x: 325, y: 420, rotation: 27 },
    { position: 425, x: 380, y: 340, rotation: 42 },
    { position: 450, x: 465, y: 268, rotation: 55 },
    { position: 475, x: 560, y: 215, rotation: 75 },
    { position: 500, x: 675, y: 190, rotation: 90 },
    { position: 525, x: 790, y: 215, rotation: 105 },
    { position: 550, x: 895, y: 260, rotation: 125 },
    { position: 575, x: 990, y: 325, rotation: 138 },
    { position: 600, x: 1085, y: 420, rotation: 153 },
    { position: 625, x: 1130, y: 535, rotation: 166 },
    { position: 650, x: 1150, y: 635, rotation: 172 },
    { position: 675, x: 1150, y: 725, rotation: 180 },
    { position: 700, x: 1150, y: 810, rotation: 180 },
    { position: 725, x: 1150, y: 890, rotation: 180 },
    { position: 750, x: 1150, y: 965, rotation: 180 },
    { position: 775, x: 1150, y: 1047, rotation: 180 },
    { position: 800, x: 1150, y: 1128, rotation: 180 },
    { position: 825, x: 1150, y: 1210, rotation: 180 },
    { position: 850, x: 1150, y: 1292, rotation: 180 },
    { position: 875, x: 1150, y: 1375, rotation: 180 },
    { position: 900, x: 1150, y: 1450, rotation: 180 },
    { position: 925, x: 1150, y: 1530, rotation: 180 },
    { position: 950, x: 1150, y: 1560, rotation: 180 },
    { position: 975, x: 1150, y: 1650, rotation: 180 },
    { position: 1000, x: 1150, y: 1740, rotation: 180 }
]

export const CardType = {
    TWENTY_FIVE: 0,
    FIFTY: 1,
    SEVENTY_FIVE: 2,
    ONE_HUNDRED: 3,
    TWO_HUNDRED: 4,
    ROULEZ: 5,
    ARRET: 6,
    LIMITE: 7,
    FIN_LIMITE: 8,
    PANNE: 9,
    ESSENCE: 10,
    CREVAISON: 11,
    ROUE_SECOURS: 12,
    ACCIDENT: 13,
    REPARATION: 14,
    PRIORITAIRE: 15,
    CITERNE: 16,
    INCREVABLE: 17,
    AS: 18
}