const moveForward = async (amount, pawnId, allPawns, validateMovement = false) => {
    const pawn = {...allPawns[pawnId]}

    let targetTile = (pawn.currentTile + amount) % 16

    while (pawn.currentTile !== targetTile) {
        pawn.currentTile = (pawn.currentTile + 1) % 16
        if (pawn.currentTile === targetTile) {
            eatPawn(pawn, pawnId, allPawns)
        }
    }

    allPawns[pawnId] = pawn;
    return allPawns
}

const moveBackward = async (amount, pawnId, allPawns, validateMovement = false) => {
    const pawn = {...allPawns[pawnId]}

    let targetTile = (pawn.currentTile - amount) % 16
    if (targetTile < 0) {
        targetTile = targetTile + 16
    }
    if (validateMovement) {
        return !(pawn.currentTile === -1);
    }

    while (pawn.currentTile !== targetTile) {
        pawn.currentTile = (pawn.currentTile + 1) % 16
        if (pawn.currentTile === targetTile) {
            eatPawn(pawn, pawnId, allPawns)
        }
    }

    allPawns[pawnId] = pawn;
    return allPawns
}


const moveBlackHole = async (amount, pawnId, allPawns, validateMovement = false) => {
    const pawn = {...allPawns[pawnId]}

    let targetTile = (pawn.currentTile + amount) % 16

    if (validateMovement) {
        return !(pawn.currentTile === -1);
    }

    while (pawn.currentTile !== targetTile) {
        pawn.currentTile = (pawn.currentTile + 1) % 16
        eatPawn(pawn, pawnId, allPawns)
    }

    allPawns[pawnId] = pawn;
    return allPawns
}


const moveWormHole = async (myPawnId, pawnToWarpId, allPawns) => {
    let myPawn = allPawns[myPawnId]
    let opponentPawn = allPawns[pawnToWarpId]
    let temp = myPawn.currentTile

    myPawn.currentTile = opponentPawn.currentTile
    opponentPawn.currentTile = temp

    allPawns[myPawnId] = myPawn
    allPawns[pawnToWarpId] = opponentPawn
    return allPawns
}


const move1 = async (amount, pawnId, allPawns) => {
    const pawn = {...allPawns[pawnId]}

    let tileBeforeHeaven = (pawnId % 2 === 0 ? 15 : 7)

    if (pawn.currentTile === tileBeforeHeaven) {
        pawn.currentTile = 99
        pawn.isFinished = true
    }
    else if (pawn.currentTile === -1) {
        pawn.currentTile = (pawnId % 2 === 0 ? 0 : 8)
        eatPawn(pawn, pawnId, allPawns)
    } else {
        return (await moveForward(amount, pawnId, allPawns))
    }

    allPawns[pawnId] = pawn;
    return allPawns
}

const eatPawn = (pawn, pawnId, allPawns) => {
    for (let i = 0; i < allPawns.length; i++) {
        if (i === pawnId) continue
        if (pawn.currentTile === allPawns[i].currentTile) {
            allPawns[i].currentTile = -1
        }
    }
}

const cards = [
    {
        name: "1",
        value: 1,
        action: move1,
        parameter: 1
    },
    {
        name: "2",
        value: 2,
        action: moveForward,
        parameter: 2
    },
    {
        name: "3",
        value: 3,
        action: moveForward,
        parameter: 3
    },
    {
        name: "4",
        value: 4,
        action: moveBackward,
        parameter: 4
    },
    {
        name: "5",
        value: 5,
        action: moveForward,
        parameter: 5,
    },
    {
        name: "Trou de Ver",
        value: 6,
        action: moveWormHole,
        parameter: 6,
    },
    {
        name: "Trou Noir",
        value: 7,
        action: moveBlackHole,
        parameter: 7
    }
]

const getCardProperties = (value) => {
    return (cards.filter(card => card.value === value))[0]
}

const validatePawnMovement = async (value, myPawnId, pawnToWarpId, allPawns) => {
    const pawn = allPawns[myPawnId]
    const oppPawn = allPawns[pawnToWarpId]


    switch (value) {
        case 1:
            return true;

        case 2:
        case 3:
        case 4:
        case 5:
        case 7:
            return !(pawn.currentTile === -1 || pawn.isFinished);

        case 6:
            return !(pawn.currentTile === -1 || pawn.isFinished || oppPawn.currentTile === -1 || oppPawn.isFinished)
    }
}

export {getCardProperties, validatePawnMovement}