import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {formatTimeAgo} from '../utils/formatTimeAgo';
import {BiSolidLike, BiSolidDislike, BiSolidMessageDetail} from 'react-icons/bi';
import { IoIosWarning } from "react-icons/io";
import axios from "axios";

const ForumThread = ({id, title, category, createdAt, user, isLiked, isDisliked, likes, dislikes, replies}) => {
    const [likesCount, setLikesCount] = useState(likes);
    const [dislikesCount, setDislikesCount] = useState(dislikes);
    const [liked, setLiked] = useState(isLiked);
    const [disliked, setDisliked] = useState(isDisliked);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

    const handleThreadLike = async (threadId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/threads/${threadId}/like`, {}, {
                headers:  { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
            });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    const handleThreadDislike = async (threadId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/threads/${threadId}/dislike`, {}, {
                headers:  { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
            });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <li key={id}
            className="card card-compact bg-neutral border-2 border-secondary border-opacity-50 rounded-2xl shadow-xl m-3">
            <Link to={`/forum/threads/${id}`} className="card-body flex-row justify-between align-middle hover:opacity-75 ease-in-out duration-300 delay-300">
                <div className="inline-flex truncate text-clip">
                    {category && <div className="badge badge-primary font-bold whitespace-nowrap">{category.name}</div>}
                    <h3 className="col-span-5 leading-4 ms-2 text-xl">{title}</h3>
                </div>
                <span className="text-xl leading-4">Il y a {formatTimeAgo(createdAt)}</span>
            </Link>
            <div className="card-actions justify-between align-middle mx-4 mb-2">
                <Link to={`/profile/${user._id}`} className="inline-flex align-middle hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                    <img
                        className="rounded-full w-10 ring-2 ring-white ring-opacity-65"
                        src={`https://robohash.org/${user.pfpFileName}?set=set2`}
                        alt={user.username}
                    />
                    <span className="text-xl leading-9 ms-2">{user.username}</span>
                </Link>
                <div className="flex align-middle">
                    <button
                        className={`${liked ? 'text-primary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"'}`}
                        onClick={() => handleThreadLike(id)}>
                        <BiSolidLike size={32}/>
                    </button>
                    <span className="leading-9 ms-1 me-2">{likesCount}</span>
                    <button
                        className={`${disliked ? 'text-secondary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300'}`}
                        onClick={() => handleThreadDislike(id)}>
                        <BiSolidDislike size={32}/>
                    </button>
                    <span className="leading-9 ms-1 me-2">{dislikesCount}</span>
                    <BiSolidMessageDetail className="mt-1" size={32}/>
                    <span className="leading-9 ms-1 me-2">{replies}</span>
                    <button
                        className="text-yellow-500 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300">
                        <IoIosWarning size={32}/>
                    </button>
                </div>
            </div>
        </li>
    );
};

export default ForumThread;
