import axios from "axios"
import {useNavigate} from "react-router-dom"
import {useState} from "react"


const useTreatFriendRequest = () => {
    const navigate = useNavigate()

    const [friendRequests, setFriendRequests] = useState([])

    const sendFriendRequest = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + '/friend-request', {senderId: senderId, receiverId: receiverId}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                return response.data
            } else {
                return null
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const deleteFriendRequest = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.delete(API_URL + `/friend-request/delete/${senderId}/${receiverId}`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            return response.status === 201
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const handleAccept = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/friend-request/accept/${senderId}/${receiverId}`, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const handleDeny = async (senderId, receiverId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/friend-request/deny/${senderId}/${receiverId}`, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    return {sendFriendRequest, deleteFriendRequest, handleAccept, handleDeny, friendRequests, setFriendRequests}
}

export default useTreatFriendRequest