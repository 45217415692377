import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import { FaHouseChimney } from "react-icons/fa6"
import axios from "axios"
import {AuthContext} from "../contexts/AuthContext"
import {FiAlertCircle} from "react-icons/fi"
import error from "./Error"

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const Login = () => {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    const [errors, setErrors] = useState({})
    const {authUser, setAuthUser} = useContext(AuthContext)

    const [modalFormData, setModalFormData] = useState({})
    const [modalErrors, setModalErrors] = useState({})
    const [userNotExists, setUserNotExists] = useState(false)
    const [isModalLoading, setIsModalLoading] = useState(false)

    const [toastMessage, setToastMessage] = useState("")
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {
        document.title = "Connexion | RetroRealm"

        if (localStorage.getItem("token")) {
            navigate('/home')
        }
    }, [localStorage.getItem("token")])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const checkUsername = async (username) => {
        try {
            const response = await axios.get(`${API_URL}/user/check-username/${username}`)
            setUserNotExists(response.data.isAvailable)
        } catch (error) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    const errors = error.response.data.errors
                    setModalErrors(errors)
                    break
                default:
                    navigate("/500")
            }
        }
    }

    const handleModalChange = (e) => {
        const {name, value} = e.target
        setModalFormData({
            ...modalFormData,
            [name]: value
        })

        if (value !== "") {
            if (name === "username") {
                checkUsername(value)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const receivedErrors = validateSubmit(formData)
        if (Object.keys(receivedErrors).length > 0) {
            setErrors(receivedErrors)
            return
        }

        try {
            setIsLoading(true)
            const url = `${API_URL}/auth/login`
            const response = await axios.post(url, formData)

            localStorage.setItem('token', JSON.stringify(response.data.token))
            setAuthUser(response.data.user)

            if (!response.data.user.isAccountValidated) {
                navigate('/validate-email')
            } else {
                navigate('/home')
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    setErrors({
                        username: "Nom d'utilisateur ou mot de passe incorrect.",
                        password: "Nom d'utilisateur ou mot de passe incorrect."
                    })
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    setErrors({
                        username: "Nom d'utilisateur ou mot de passe incorrect.",
                        password: "Nom d'utilisateur ou mot de passe incorrect."
                    })
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleModalSubmit = async () => {
        const errors = validateModalSubmit(modalFormData)
        setModalErrors(errors)
        if (Object.keys(modalErrors).length > 0) {
            console.log(modalErrors)
            return
        }

        try {
            setIsModalLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + '/message/contactAdmin', modalFormData)
            if (response.status === 201) {
                setModalFormData({
                    username: '',
                    object: '',
                    message: ''
                })
                document.getElementById('contact-modal').close()
                setToastMessage("Message envoyé à l'administrateur")
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false)
                }, [5000])
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate("/422")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsModalLoading(false)
        }
    }

    return (
        <div className="h-screen flex justify-between">
            <div className="w-1/2 h-full bg-base-100 flex flex-col justify-center">
                <div className="p-20 overflow-auto">
                    <div className="flex justify-between items-baseline gap-4 mb-10">
                        <h2 className="text-5xl text-white pixel-font">RetroRealm</h2>
                        <button onClick={() => {
                            navigate('/')
                        }}><FaHouseChimney size={36} className="text-white"/></button>
                    </div>
                    <form method="POST" onSubmit={handleSubmit}>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-white text-lg">Nom d'utilisateur</span>
                            </div>
                            <input type="text" placeholder="Tapez ici" name="username" value={formData.username}
                                   onChange={handleChange}
                                   className="input text-white input-bordered w-full rounded-full"/>
                        </label>
                        {errors.username && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.username}</p>
                            </div>
                        )}

                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-white text-lg">Mot de passe</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="password" value={formData.password}
                                   onChange={handleChange}
                                   className="input text-white input-bordered w-full rounded-full"/>
                        </label>
                        {errors.password && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.password}</p>
                            </div>
                        )}

                        <div className="flex justify-between mt-5">
                            {isLoading ? (
                                <button className="btn btn-primary rounded-full">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-primary rounded-full">Se connecter</button>
                            )}

                            <div>
                                <button type="button" className="link link-primary block text-right" onClick={() => {
                                    navigate('/forgotten-password')
                                }}>Mot de passe oublié
                                </button>
                                <button type="button" className="link link-primary block text-right" onClick={() => {
                                    document.getElementById('contact-modal').showModal()
                                }}>Nous contacter
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="flex justify-center items-center px-20 gap-10 my-10">
                    <p className="text-white">Vous n'avez pas de compte?</p>
                    <p className="text-white">|</p>
                    <button className="link link-primary block text-center" onClick={() => {
                        navigate('/register')
                    }}>Créer un compte
                    </button>
                </div>
            </div>
            <div className="w-1/2 h-full login-landscape"></div>

            <dialog id="contact-modal" className="modal">
                <div className="modal-box w-1/2 max-w-2xl">
                    <form method="dialog">
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    </form>
                    <h2 className="text-2xl">Contacter l'administrateur</h2>
                    <label className="form-control w-full mt-5">
                        <input name="username" type="text" maxLength="150" placeholder="Votre nom d'utilisateur"
                               value={modalFormData.username}
                               onChange={handleModalChange}
                               className="input input-bordered w-full text-white"/>
                    </label>
                    {modalErrors.username && (
                        <div className="flex justify-start items-center gap-1">
                            <FiAlertCircle size={24} className="text-red-500"/>
                            <p className="text-red-500 text-lg">{modalErrors.object}</p>
                        </div>
                    )}
                    {userNotExists && (
                        <div className="flex justify-start items-center gap-1">
                            <FiAlertCircle size={24} className="text-red-500"/>
                            <p className="text-red-500 text-lg">Aucun utilisateur avec ce nom d'utilisateur</p>
                        </div>
                    )}
                    <hr className="mt-5"/>

                    <label className="form-control w-full mt-5">
                        <input name="object" type="text" maxLength="150" placeholder="Ajoutez un objet"
                               value={modalFormData.object}
                               onChange={handleModalChange}
                               className="input input-bordered w-full text-white"/>
                    </label>
                    {modalErrors.object && (
                        <div className="flex justify-start items-center gap-1">
                            <FiAlertCircle size={24} className="text-red-500"/>
                            <p className="text-red-500 text-lg">{modalErrors.object}</p>
                        </div>
                    )}

                    <label className="form-control w-full mt-5 flex-grow">
                    <textarea name="message" placeholder="Tapez ici" value={modalFormData.message}
                              onChange={handleModalChange}
                              className="input input-bordered w-full resize-none h-48 text-white p-4 whitespace-pre-wrap"/>
                    </label>
                    {modalErrors.message && (
                        <div className="flex justify-start items-center gap-1">
                            <FiAlertCircle size={24} className="text-red-500"/>
                            <p className="text-red-500 text-lg">{modalErrors.message}</p>
                        </div>
                    )}
                    {isModalLoading ? (
                        <button className="btn btn-primary w-36 mt-4">
                            <span className="loading loading-spinner"></span>
                            Chargement
                        </button>
                    ) : (
                        <button onClick={handleModalSubmit} className="btn btn-primary w-36 mt-4">Envoyer</button>
                    )}
                </div>
            </dialog>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>{toastMessage}</span>
                </div>
            </div>
        </div>
    )
}

export default Login

const validateSubmit = (formData) => {
    const errors = {}

    if (!formData.username) {
        errors.username = "Ce champ est requis"
    }

    if (!formData.password) {
        errors.password = "Ce champ est requis"
    }

    return errors
}

const validateModalSubmit = (formData) => {
    const errors = {}

    if (!formData.username) {
        errors.username = "Ce champ est requis"
    }

    if (!formData.object) {
        errors.object = "Ce champ est requis"
    }

    if (!formData.message) {
        errors.message = "Ce champ est requis"
    }

    return errors
}