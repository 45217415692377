import {Link, useNavigate} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import {IoMdSettings} from "react-icons/io"
import {FaClipboardList, FaUserFriends} from "react-icons/fa"
import {MdLogout, MdNotifications, MdNotificationsActive} from "react-icons/md"
import {SocketContext} from "../contexts/SocketContext"
import useTreatFriendRequest from "../hooks/useTreatFriendRequest"
import useTreatGameInvitations from "../hooks/useTreatGameInvitations"
import axios from "axios"


const Header = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const {socket, onlineUsers} = useContext(SocketContext)

    const [notifications, setNotifications] = useState([])

    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [isActionLoading, setIsActionLoading] = useState(false)

    const {handleAccept, handleDeny} = useTreatFriendRequest()
    const {handleJoin, handleDecline} = useTreatGameInvitations()

    const handleLogout = async () => {
        localStorage.removeItem('token')
        setAuthUser(null)
        navigate('/login')
    }

    const handleAcceptClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleAccept(senderId, receiverId)
        setAuthUser(newAuthUser)
        setToastMessage("Demande d'ami acceptée")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDenyClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleDeny(senderId, receiverId)
        setAuthUser(newAuthUser)
        setToastMessage("Demande d'ami refusée.")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleJoinClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleJoin(senderId, receiverId)
        setAuthUser(newAuthUser)
        setIsActionLoading(false)
    }

    const handleDeclineClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleDecline(senderId, receiverId)
        setAuthUser(newAuthUser)
        setToastMessage("Invitation à jouer refusée.")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleMessageClick = async (messageId) => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/message/deleteNotification/${messageId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setAuthUser(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/messenger")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/messenger")
                    break
                case 404:
                    navigate("/messenger")
                    break
                case 409:
                    navigate("/messenger")
                    break
                case 422:
                    navigate("/messenger")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    useEffect(() => {
        setNotifications(authUser.notifications || [])
    }, [authUser])

    useEffect(() => {
        socket?.on('friend-request', (data) => {
            setNotifications([...notifications, data.notification])
            setToastMessage("Vous avez reçu une nouvelle demande d'ami.")
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        })

        return () => socket?.off('friend-request')
    }, [socket, setNotifications, notifications])

    useEffect(() => {
        socket?.on('game-invitation', (data) => {
            setNotifications([...notifications, data.notification])
            setToastMessage(data.message)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        })

        return () => socket?.off('game-invitation')
    }, [socket, setNotifications, notifications])

    useEffect(() => {
        socket?.on('new-message', (data) => {
            setNotifications([...notifications, data.notification])
            setToastMessage(data.notification.content)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        })

        return () => socket?.off('new-message')
    }, [socket, setNotifications, notifications])

    useEffect(() => {
        socket?.on('friend-request-delete', (data) => {
            setNotifications(data.notifications)
        })

        return () => socket?.off('friend-request-delete')
    }, [socket, setNotifications, notifications])

    useEffect(() => {
        socket?.on('game-invitation-delete', (data) => {
            setNotifications(data.notifications)
        })

        return () => socket?.off('game-invitation-delete')
    }, [socket, setNotifications, notifications])

    useEffect(() => {
        socket?.on('friend-request-accept', (data) => {
            setAuthUser(data.sender)
            setToastMessage(data.message)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        })

        return () => socket?.off('friend-request-accept')
    }, [socket])

    useEffect(() => {
        socket?.on('join-game', (data) => {
            navigate(`/play/${data.game.toLowerCase()}/${data.gameId}`)
        })

        return () => socket?.off('join-game')
    }, [socket, setNotifications, notifications])

    return (
        <header className="my-5">
            <div
                className="rounded-2xl mx-auto max-w-7xl w-11/12 bg-base-300 px-10 py-5 flex justify-between items-center">
                <h1><Link className="pixel-font" to={'/home'}>RetroRealm</Link></h1>
                <div className="flex justify-end items-center gap-4">
                    <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-circle btn-ghost m-1">
                            {notifications.length > 0 ? (
                                <MdNotificationsActive size={36} className="text-white" />
                            ) : (
                                <MdNotifications size={36} className="text-white"/>
                            )}
                        </div>
                        <ul tabIndex={0}
                            className="dropdown-content menu bg-base-200 rounded-box z-[1] min-w-fit p-2 shadow">
                            {notifications.length > 0 ? (
                                <>
                                    {notifications.map((notification) => (
                                        <>
                                            {notification.type === 'friend-request' ? (
                                                <li onClick={() => {
                                                    navigate(`/profile/${notification.additionalData.senderId}`)
                                                }} key={notification.id}>
                                                    <div role="link"
                                                         className="flex justify-between gap-4 items-center">
                                                        <div className="flex justify-start gap-4 items-center">
                                                            <div
                                                                className={`avatar ${onlineUsers.includes(notification.additionalData.senderId) ? 'online' : 'offline'}`}>
                                                                <div
                                                                    className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                                                    <img className="rounded-full"
                                                                         src={`https://robohash.org/${notification.additionalData.pfp}?set=set2`}
                                                                         alt="Photo de profil"/>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="text-white text-lg w-64">{notification.content}</p>
                                                            </div>
                                                        </div>
                                                        {isActionLoading ? (
                                                            <span className="loading loading-spinner loading-lg"></span>
                                                        ) : (
                                                            <div
                                                                className="flex flex-col gap-1 justify-center items-center">
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleAcceptClick(notification.additionalData.senderId, authUser._id)
                                                                }} className="btn btn-success btn-sm w-20">Accepter
                                                                </button>
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDenyClick(notification.additionalData.senderId, authUser._id)
                                                                }} className="btn btn-error btn-sm w-20">Refuser
                                                                </button>
                                                            </div>
                                                        )}

                                                    </div>
                                                </li>
                                            ) : notification.type === 'message' ? (
                                                <li onClick={() => {
                                                    handleMessageClick(notification.additionalData.messageId)
                                                    navigate(`/messenger/${notification.additionalData.messageId}`)
                                                }} key={notification.id}>
                                                    <div role="link" className="flex justify-start gap-4 items-center">
                                                        <div
                                                            className={`avatar ${onlineUsers.includes(notification.additionalData.senderId) ? 'online' : 'offline'}`}>
                                                            <div
                                                                className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                                                <img className="rounded-full"
                                                                     src={`https://robohash.org/${notification.additionalData.pfp}?set=set2`}
                                                                     alt="Photo de profil"/>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="text-white text-lg w-64">{notification.content}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ) : notification.type === 'game-invitation' ? (
                                                <li onClick={() => {
                                                    navigate(`/profile/${notification.additionalData.senderId}`)
                                                }} key={notification.id}>
                                                    <div role="link"
                                                         className="flex justify-between gap-4 items-center">
                                                        <div className="flex justify-start gap-4 items-center">
                                                            <div
                                                                className={`avatar ${onlineUsers.includes(notification.additionalData.senderId) ? 'online' : 'offline'}`}>
                                                                <div
                                                                    className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                                                    <img className="rounded-full"
                                                                         src={`https://robohash.org/${notification.additionalData.pfp}?set=set2`}
                                                                         alt="Photo de profil"/>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className="text-white text-lg w-64">{notification.content}</p>
                                                            </div>
                                                        </div>
                                                        {isActionLoading ? (
                                                            <span className="loading loading-spinner loading-lg"></span>
                                                        ) : (
                                                            <div
                                                                className="flex flex-col gap-1 justify-center items-center">
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleJoinClick(notification.additionalData.senderId, authUser._id)
                                                                }} className="btn btn-success btn-sm w-20">Joindre
                                                                </button>
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDeclineClick(notification.additionalData.senderId, authUser._id)
                                                                }} className="btn btn-error btn-sm w-20">Refuser
                                                                </button>
                                                            </div>
                                                        )}

                                                    </div>
                                                </li>
                                            ) : (
                                                <li className="text-center text-lg p-5"></li>
                                            )}
                                        </>
                                    ))}
                                </>
                            ) : (
                                <li className="text-center text-lg p-5 w-96">Aucune nouvelle notification</li>
                            )}
                        </ul>
                    </div>
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} className="avatar cursor-pointer" role="button">
                            <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                                <img src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}/>
                            </div>
                        </div>
                        <ul tabIndex={0}
                            className="dropdown-content menu bg-base-200 rounded-box z-[1] w-auto p-2 mt-2 shadow">
                            <li onClick={() => {
                                navigate('/profile')
                            }}>
                                <div role="link" className="flex justify-start gap-4 items-center">
                                    <div
                                        className="ring-primary ring-offset-base-100 w-10 rounded-full ring ring-offset-2">
                                        <img className="rounded-full" alt="Photo de profil"
                                             src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}/>
                                    </div>
                                    <div>
                                        <h2 className="text-white font-bold text-lg">{authUser.username}</h2>
                                        <p className="text-white text-md underline">Votre compte</p>
                                    </div>
                                </div>
                            </li>
                            <li onClick={() => {
                                navigate('/profile/friends')
                            }}>
                                <div role="link" className="flex justify-start gap-4 items-center">
                                    <FaUserFriends size={24} className="text-white"/>
                                    <p className="text-white text-lg">Amis</p>
                                </div>
                            </li>
                            <li onClick={() => {
                                navigate('/profile-edit')
                            }}>
                                <div role="link" className="flex justify-start gap-4 items-center">
                                    <IoMdSettings size={24} className="text-white"/>
                                    <p className="text-white text-lg">Paramètres</p>
                                </div>
                            </li>
                            {authUser.userType !== "STANDARD" &&
                                <li onClick={() => {
                                    navigate('/control-panel')
                                }}>
                                    <div role="link" className="flex justify-start gap-4 items-center">
                                        <FaClipboardList size={24} className="text-white"/>
                                        <p className="text-white text-lg">Panneau de contrôle</p>
                                    </div>
                                </li>
                            }
                            <li onClick={handleLogout}>
                                <div role="link" className="flex justify-start gap-4 items-center">
                                    <MdLogout size={24} className="text-white"/>
                                    <p className="text-white text-lg">Déconnexion</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-info">
                    <span>{toastMessage}</span>
                </div>
            </div>

        </header>
    )
}

export default Header