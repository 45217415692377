import React, {useContext, useEffect, useRef, useState} from "react";
import Board from "../images/allure/Plateau.jpg"
import Info from "../images/allure/info.png"
import Player1_On from "../images/allure/p1on.png"
import Player1_Off from "../images/allure/p1off.png"
import Player2_On from "../images/allure/p2on.png"
import Player2_Off from "../images/allure/p2off.png"
import {AuthContext} from "../contexts/AuthContext";
import {SocketContext} from "../contexts/SocketContext";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import AllureRules from "./AllureRules";
import {boardPositions1, boardPositions2, CardType} from "../utils/allureMethods";

const AllureGame = ({gameId}) => {
    const {authUser} = useContext(AuthContext);
    const {socket} = useContext(SocketContext)

    const [myIndex, setMyIndex] = useState(0);
    const [opponentIndex, setOpponentIndex] = useState(0);
    const [currentGame, setCurrentGame] = useState(null);
    const [opponent, setOpponent] = useState({});
    const [drawStack, setDrawStack] = useState([]);
    const [discardStack, setDiscardStack] = useState([]);
    const [myHand, setMyHand] = useState([]);
    const [myPosition, setMyPosition] = useState(0);
    const [myPawnPosition, setMyPawnPosition] = useState({});
    const [opponentPawnPosition, setOpponentPawnPosition] = useState({});
    const [myLastPlayedCard, setMyLastPlayedCard] = useState("Aucune");
    const [myCondition, setMyCondition] = useState(1);
    const [myBattleDeck, setMyBattleDeck] = useState([]);
    const [myLimitDeck, setMyLimitDeck] = useState([]);
    const [mySafetyDeck, setMySafetyDeck] = useState([]);
    const [opponentPosition, setOpponentPosition] = useState(0);
    const [opponentLastPlayedCard, setOpponentLastPlayedCard] = useState("Aucune");
    const [opponentCondition, setOpponentCondition] = useState(1);
    const [opponentBattleDeck, setOpponentBattleDeck] = useState([]);
    const [opponentLimitDeck, setOpponentLimitDeck] = useState([]);
    const [opponentSafetyDeck, setOpponentSafetyDeck] = useState([]);

    const [turn, setTurn] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const boardRef = useRef(null);
    const [scaledObjects, setScaledObjects] = useState([]);
    const [toast, setToast] = useState(null);


    const [hasWon, setHasWon] = useState(null);

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
            return;
        }
        saveGameData(currentGame)
    }, [turn]);

    useEffect(() => {
        socket?.on('refuse-endgame', (data) => {
            showToast("Demande d'abandon refusée.", "alert-info");
        })

        return () => socket?.off('refuse-endgame')
    }, [socket])

    useEffect(() => {
        socket?.on('ask-endgame', (data) => {
            document.getElementById("giveupModal").showModal();
        })

        return () => socket?.off('ask-endgame')
    })

    useEffect(() => {
        socket?.on('confirm-endgame', (args) => {
            if (args.win === null) {
                setHasWon(null)
            } else {
                setHasWon(args.win)
            }
            document.getElementById('endgameModal').showModal()
        })

        return () => socket?.off('confirm-endgame')
    }, [socket])

    const scaleCoordinates = (x, y, rotation = 0) => {
        if (!boardRef.current) return {scaledX: 0, scaledY: 0, rotation: 0};

        const boardWidth = boardRef.current.offsetWidth;
        const boardHeight = boardRef.current.offsetHeight;

        const scaleX = boardWidth / boardRef.current.naturalWidth;
        const scaleY = boardHeight / boardRef.current.naturalHeight;

        const scaledX = x * scaleX
        const scaledY = y * scaleY

        return {scaledX, scaledY, rotation}
    };

    const boardObjects = [
        {id: 0, type: 'deck', x: 655, y: 1040},
        {id: 1, type: 'deck', x: 655, y: 1305},
        {id: 2, type: 'help', x: 50, y: 50},
        {id: 3, type: 'quit', x: 620, y: 1860}
    ];

    useEffect(() => {
        setMyPawnPosition(myIndex === 0 ? boardPositions1.find(pos => pos.position === myPosition) : boardPositions2.find(pos => pos.position === myPosition))
        setOpponentPawnPosition(opponentIndex === 0 ? boardPositions1.find(pos => pos.position === opponentPosition) : boardPositions2.find(pos => pos.position === opponentPosition))
    }, [isLoading, myPosition, opponentPosition]);

    const myScaleds = scaleCoordinates(myPawnPosition.x, myPawnPosition.y, myPawnPosition.rotation);
    const opponentScaleds = scaleCoordinates(opponentPawnPosition.x, opponentPawnPosition.y, opponentPawnPosition.rotation);

    const myPawnStyle = {
        position: 'absolute',
        left: `${myScaleds.scaledX}px`,
        top: `${myScaleds.scaledY}px`,
        rotate: `${myScaleds.rotation}deg`
    };

    const opponentPawnStyle = {
        position: 'absolute',
        left: `${opponentScaleds.scaledX}px`,
        top: `${opponentScaleds.scaledY}px`,
        rotate: `${opponentScaleds.rotation}deg`
    };

    const handleImageLoad = () => {
        const scaled = boardObjects.map((obj) => {
            const {scaledX, scaledY} = scaleCoordinates(obj.x, obj.y);
            return {...obj, scaledX, scaledY};
        });

        setScaledObjects(scaled);
    };

    const Condition = {
        GOING: 0,
        STOPPED: 1,
        OUT_OF_GAS: 2,
        FLAT_TIRE: 3,
        ACCIDENT: 4
    }

    useEffect(() => {
        if (!currentGame || !currentGame._id) return;

        const handleTurnPlayed = (data) => {
            if (data.id === currentGame._id) {
                getGameData().then(checkCardsCountAndDraw)
            }
        }

        socket?.on('turn-played', handleTurnPlayed)

        return () => socket?.off('turn-played', handleTurnPlayed);
    }, [socket, currentGame]);

    useEffect(() => {
        setIsLoading(true)
        getGameData()
    }, []);

    const saveGameData = async (gameData) => {
        try {
            const updatedGameData = updateGameData(gameData)
            setCurrentGame(updatedGameData)

            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`

            const response = await axios.put(url, updatedGameData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            });

            const game = response.data

            if (game.data.playerData[myIndex].parsec === 1000)
                await winGame()

            if(game.drawStack.length === 0) {
                const url = `${API_URL}/game/game-ended/${gameId}`
                await axios.post(url, {}, {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                });
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const getGameData = async () => {

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                const game = response.data

                const newMyIndex = game.players.findIndex(player => player._id === authUser._id);
                const newOpponentIndex = (newMyIndex + 1) % game.players.length;

                setCurrentGame(game)
                setMyIndex(newMyIndex)
                setOpponentIndex(newOpponentIndex)

                if (game.playerTurn === newMyIndex) {
                    showToast("C'est à votre tour !", "alert-success");
                }

                setDrawStack(game.drawStack);
                setDiscardStack(game.playedStack);
                setMyHand(game.hands[newMyIndex])
                setMyPosition(game.data.playerData[newMyIndex].position);
                setMyLastPlayedCard(game.data.playerData[newMyIndex].lastPlayedCard);
                setMyCondition(game.data.playerData[newMyIndex].condition);
                setMyBattleDeck(game.data.playerData[newMyIndex].battles);
                setMyLimitDeck(game.data.playerData[newMyIndex].limits);
                setMySafetyDeck(game.data.playerData[newMyIndex].safeties);

                setOpponent(game.players[newOpponentIndex]);
                setOpponentPosition(game.data.playerData[newOpponentIndex].position);
                setOpponentLastPlayedCard(game.data.playerData[newOpponentIndex].lastPlayedCard);
                setOpponentCondition(game.data.playerData[newOpponentIndex].condition);
                setOpponentBattleDeck(game.data.playerData[newOpponentIndex].battles);
                setOpponentLimitDeck(game.data.playerData[newOpponentIndex].limits);
                setOpponentSafetyDeck(game.data.playerData[newOpponentIndex].safeties);
            }

        } catch (error) {
            console.dir(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const showToast = (message, classes) => {
        setToast({message: message, classes: classes});
        setTimeout(() => setToast(null), 3000);
    };

    const checkCardsCountAndDraw = () => {
        setMyHand(x => {
            const hand = [...x]
            const deck = [...drawStack]
            while (hand.length < 7) {
                const drawnCard = deck.pop()
                hand.push(drawnCard)
            }
            setDrawStack(deck)
            return hand
        })
    }

    const updateGameData = gameData => {
        const newGameData = {...gameData}

        newGameData.drawStack = drawStack
        newGameData.playedStack = discardStack
        newGameData.hands[myIndex] = myHand
        newGameData.data.playerData[myIndex] = {
            ...newGameData.data.playerData[myIndex],
            position: myPosition,
            safeties: mySafetyDeck,
            battles: myBattleDeck,
            limits: myLimitDeck,
            condition: myCondition,
            lastPlayedCard: myLastPlayedCard
        }
        newGameData.data.playerData[opponentIndex] = {
            ...newGameData.data.playerData[opponentIndex],
            battles: opponentBattleDeck,
            limits: opponentLimitDeck,
            condition: opponentCondition
        }

        return newGameData
    }

    const handleCardAction = async card => {
        switch (card) {
            case CardType.TWENTY_FIVE:
                if (!goForward(25))
                    return
                setMyLastPlayedCard("25 Parsecs")
                break
            case CardType.FIFTY:
                if (!goForward(50))
                    return
                setMyLastPlayedCard("50 Parsecs")
                break
            case CardType.SEVENTY_FIVE:
                if (!goForward(75))
                    return
                setMyLastPlayedCard("75 Parsecs")
                break
            case CardType.ONE_HUNDRED:
                if (!goForward(100))
                    return
                setMyLastPlayedCard("100 Parsecs")
                break
            case CardType.TWO_HUNDRED:
                if (!goForward(200))
                    return
                setMyLastPlayedCard("200 Parsecs")
                break
            case CardType.ROULEZ:
                if (!fixMyCondition(Condition.STOPPED))
                    return showToast("Vous avez déjà démarré.", "alert-error")

                myBattleDeck.push(card)
                setMyLastPlayedCard("Démarrage")
                break
            case CardType.ARRET:
                if (!changeOpponentCondition(Condition.STOPPED))
                    return showToast("L'autre joueur est déjà arrêté.", "alert-error")

                if (opponentSafetyDeck.includes(CardType.PRIORITAIRE))
                    return showToast("L'autre joueur est un vaisseau prioritaire.", "alert-error")

                opponentBattleDeck.push(card)
                setMyLastPlayedCard("Arrêt")
                break
            case CardType.LIMITE:
                if (currentGame.data.playerData[opponentIndex].hasSpeedLimit)
                    return showToast("L'autre joueur a déjà une limite de vitesse.", "alert-error")

                if (opponentSafetyDeck.includes(CardType.PRIORITAIRE))
                    return showToast("L'autre joueur est un vaisseau prioritaire.", "alert-error")

                opponentLimitDeck.push(card)
                currentGame.data.playerData[opponentIndex].hasSpeedLimit = true
                setMyLastPlayedCard("Limite de Vitesse")
                break
            case CardType.FIN_LIMITE:
                if (!currentGame.data.playerData[myIndex].hasSpeedLimit)
                    return showToast("Vous n'avez pas de limite de vitesse.", "alert-error")

                myLimitDeck.push(card)
                currentGame.data.playerData[myIndex].hasSpeedLimit = false
                setMyLastPlayedCard("Fin Limite de Vitesse")
                break
            case CardType.PANNE:
                if (!changeOpponentCondition(Condition.OUT_OF_GAS))
                    return showToast("L'autre joueur est déjà arrêté.", "alert-error")

                if (opponentSafetyDeck.includes(CardType.CITERNE))
                    return showToast("L'autre joueur est un vaisseau citerne.", "alert-error")

                opponentBattleDeck.push(card)
                setMyLastPlayedCard("Panne de Carburant")
                break
            case CardType.ESSENCE:
                if (!fixMyCondition(Condition.OUT_OF_GAS))
                    return showToast("Vous n'êtes pas en panne de carburant.", "alert-error")

                myBattleDeck.push(card)
                break
            case CardType.CREVAISON:
                if (!changeOpponentCondition(Condition.FLAT_TIRE))
                    return showToast("L'autre joueur est déjà arrêté.", "alert-error")

                if (opponentSafetyDeck.includes(CardType.INCREVABLE))
                    return showToast("L'autre joueur a un module incassable.", "alert-error")

                opponentBattleDeck.push(card)
                setMyLastPlayedCard("Module Endommagé")
                break
            case CardType.ROUE_SECOURS:
                if (!fixMyCondition(Condition.FLAT_TIRE))
                    return showToast("Vous n'avez aucun module endommagé.", "alert-error")

                myBattleDeck.push(card)
                setMyLastPlayedCard("Module de Secours")
                break
            case CardType.ACCIDENT:
                if (!changeOpponentCondition(Condition.ACCIDENT))
                    return showToast("L'autre joueur est déjà arrêté.", "alert-error")

                if (opponentSafetyDeck.includes(CardType.AS))
                    return showToast("L'autre joueur est un as des commandes.", "alert-error")

                opponentBattleDeck.push(card)
                setMyLastPlayedCard("Accident")
                break
            case CardType.REPARATION:
                if (!fixMyCondition(Condition.ACCIDENT))
                    return showToast("Vous n'êtes pas accidenté.", "alert-error")

                myBattleDeck.push(card)
                setMyLastPlayedCard("Réparations")
                break
            case CardType.PRIORITAIRE:
                mySafetyDeck.push(CardType.PRIORITAIRE)

                fixMyCondition(Condition.STOPPED)
                removeFromHand(card)
                await saveGameData(currentGame)
                setMyLastPlayedCard("Vaisseau Prioritaire")
                return
            case CardType.CITERNE:
                mySafetyDeck.push(CardType.CITERNE)

                fixMyCondition(Condition.OUT_OF_GAS)
                removeFromHand(card)
                setMyLastPlayedCard("Vaisseau Citerne")
                return
            case CardType.INCREVABLE:
                mySafetyDeck.push(CardType.INCREVABLE)

                fixMyCondition(Condition.FLAT_TIRE)
                removeFromHand(card)
                setMyLastPlayedCard("Module Incassable")
                return
            case CardType.AS:
                mySafetyDeck.push(CardType.AS)

                fixMyCondition(Condition.ACCIDENT)
                removeFromHand(card)
                setMyLastPlayedCard("As des Commandes")
                return
        }

        removeFromHand(card)

        // TODO animation retirer carte

        setCurrentGame(game => {
            return {
                ...game,
                playerTurn: opponentIndex
            }
        })

        setTurn(i => !i)
    }

    const goForward = speed => {
        if (myCondition !== Condition.GOING && !mySafetyDeck.includes(CardType.PRIORITAIRE)) {
            showToast("Vous devez démarrer votre moteur avant d'avancer !", "alert-error")
            return false
        }

        if (currentGame.data.playerData[myIndex].hasSpeedLimit && speed > 50 && !mySafetyDeck.includes(CardType.PRIORITAIRE)) {
            showToast("Vous avez une limite de vitesse ! 50 Parsecs maximum.", "alert-error")
            return false
        }

        if (myPosition + speed > 1000) {
            showToast("Vous devez arriver exactement à 1000 Parsecs pour gagner.", "alert-error")
            return false
        }

        setMyPosition(p => p + speed)
        return true
    }

    const changeOpponentCondition = condition => {
        if (opponentCondition !== Condition.GOING)
            return false

        setOpponentCondition(condition)
        return true
    }

    const fixMyCondition = condition => {
        if (myCondition !== condition)
            return false

        setMyCondition(Condition.GOING)
        return true
    }

    const handleDiscard = async (card) => {
        removeFromHand(card);
        setDiscardStack(x => [...x, card])

        currentGame.playerTurn = opponentIndex
        setTurn(x => !x)
    };

    const removeFromHand = card => {
        const index = myHand.indexOf(card)
        if (index !== -1) {
            setMyHand(x => {
                const hand = [...x]
                hand.splice(index, 1)
                return hand
            })
        }
    }


    const endGame = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/${gameId}`
            const response = await axios.delete(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                navigate("/games")
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const askEndGame = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/ask-endgame/${gameId}`

            const response = await axios.post(url, {playerId: myIndex}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                navigate("/games")
                //showToast("Demande d'abandon envoyée.", "alert-info");
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const confirmEndgame = async (gameClaimed) => {
        document.getElementById("giveupModal").close()

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/confirm-endgame/${gameId}`

            const response = await axios.post(url, {playerId: myIndex, gameClaimed: gameClaimed}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const winGame = async () => {

        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const url = `${API_URL}/game/win-endgame/${gameId}`

            await axios.post(url, {playerId: myIndex}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/home")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/home")
                    break
                case 404:
                    navigate("/home")
                    break
                case 409:
                    navigate("/home")
                    break
                case 422:
                    navigate("/home")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    return (
        <>
            {isLoading || !currentGame ? (
                <div>LOADING</div>
            ) : (
                <div className="flex flex-row">
                    {toast && (
                        <div className="toast toast-center toast-middle z-20">
                            <div className={`alert ${toast.classes}`}>
                                <span className="text-lg font-bold">{toast.message}</span>
                            </div>
                        </div>
                    )}
                    <div className="relative w-full">
                        <img
                            ref={boardRef}
                            src={Board}
                            alt="Curved U-Shape Board"
                            className="w-full max-h-screen"
                            onLoad={handleImageLoad}
                        />
                        {scaledObjects.map((obj) => {
                            let tooltipText
                            let additionalClass

                            switch (obj.id) {
                                case 0:
                                    tooltipText = drawStack.length + " cartes"
                                    break;
                                case 1:
                                    tooltipText = discardStack.length + " cartes"
                                    additionalClass = discardStack.length === 0 ? "hidden" : ""
                                    break;
                                case 2:
                                    return (
                                        <>
                                            <button
                                                className="text-base-100 hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300 tooltip tooltip-bottom tooltip-secondary"
                                                style={{
                                                    position: 'absolute',
                                                    left: `${obj.scaledX}px`,
                                                    top: `${obj.scaledY}px`,
                                                }}
                                                onClick={() => document.getElementById('helpModal').showModal()}
                                                data-tip="Règles du jeu">
                                                <img src={Info} alt="Règles du jeu"/>
                                            </button>
                                            <AllureRules/>
                                        </>
                                    )
                                case 3:
                                    return (
                                        <button className="btn btn-error btn-lg rounded-full w-40"
                                                onClick={askEndGame}
                                                style={{
                                                    position: 'absolute',
                                                    left: `${obj.scaledX}px`,
                                                    top: `${obj.scaledY}px`,
                                                }}>
                                            Quitter la partie
                                        </button>
                                    )
                                default:
                                    break;
                            }
                            return (
                                <div
                                    key={obj.id}
                                    style={{
                                        position: 'absolute',
                                        left: `${obj.scaledX}px`,
                                        top: `${obj.scaledY}px`,
                                    }}
                                    className={`tooltip tooltip-secondary ${
                                        obj.type === 'pawn'
                                            ? `pawn w-8 h-8 rounded-full ${additionalClass}`
                                            : `draw-stack ${additionalClass}`
                                    }`}
                                    data-tip={tooltipText}
                                ></div>
                            )
                        })}
                        {myIndex === 0 ? (
                            <div
                                style={myPawnStyle}
                                className="tooltip tooltip-secondary pawn"
                                data-tip={authUser.username}
                            ><img src={myCondition !== Condition.GOING ? Player1_Off : Player1_On} alt="Joueur 1"
                                  className="w-14"/></div>
                        ) : (
                            <div
                                style={opponentPawnStyle}
                                className="tooltip tooltip-secondary pawn"
                                data-tip={opponent.username}
                            ><img src={opponentCondition !== Condition.GOING ? Player1_Off : Player1_On}
                                  alt="Joueur 1" className="w-14"/></div>
                        )}
                        {myIndex === 1 ? (
                            <div
                                style={myPawnStyle}
                                className="tooltip tooltip-secondary pawn"
                                data-tip={authUser.username}
                            ><img src={myCondition !== Condition.GOING ? Player2_Off : Player2_On} alt="Joueur 2"
                                  className="w-14"/></div>
                        ) : (
                            <div
                                style={opponentPawnStyle}
                                className="tooltip tooltip-secondary pawn"
                                data-tip={opponent.username}
                            ><img src={opponentCondition !== Condition.GOING ? Player2_Off : Player2_On} alt="Joueur 2"
                                  className="w-14"/></div>
                        )}
                    </div>

                    <div className="flex flex-col justify-between px-3 gap-3 h-screen w-full">
                        <div
                            className={`flex flex-col justify-between items-center px-2 h-2/5 border-8 border-t-0 rounded-lg ${currentGame.playerTurn !== myIndex ? (
                                'border-accent'
                            ) : (
                                'border-secondary'
                            )}`}>

                            <div className="flex flex-row justify-between gap-2 w-full">
                                <div className="border-4 border-secondary border-dashed w-1/3 p-1">
                                    <h4 className="ms-2">Pile de Vitesse</h4>
                                    <div className="flex flex-row ms-16 min-h-24">
                                        {opponentLimitDeck.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`playing-card limit-battle-deck card-${card}`}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="border-4 border-secondary border-dashed w-2/3 p-1">
                                    <h4 className="ms-2">Pile de Bataille</h4>
                                    <div className="flex flex-row ms-16 min-h-24">
                                        {opponentBattleDeck.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`playing-card limit-battle-deck card-${card}`}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="border-4 border-secondary border-dashed w-full p-1">
                                <h4 className="ms-5">Bottes</h4>
                                <div className="flex flex-row min-h-24">
                                    {opponentSafetyDeck.map((card, index) => (
                                        <div
                                            key={index}
                                            className={`playing-card safety-deck card-${card}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>

                            <div className="flex flex-row justify-between w-full pb-2 px-3 text-2xl text-error">
                                <p>Parsecs parcourus: {opponentPosition}</p>
                                <p>Dernière carte jouée: {opponentLastPlayedCard}</p>
                            </div>
                        </div>
                        <div
                            className={`flex flex-col justify-between items-center h-3/5 px-2 border-8 border-b-0 rounded-lg ${currentGame.playerTurn !== myIndex ? (
                                'border-secondary'
                            ) : (
                                'border-accent'
                            )}`}>
                            <div className="flex flex-row justify-between w-full pt-2 px-3 text-2xl">
                                <p>Parsecs parcourus: {myPosition}</p>
                                <p>Dernière carte jouée: {myLastPlayedCard}</p>
                            </div>

                            <div className="border-4 border-primary border-dashed w-full p-1">
                                <h4 className="ms-5">Bottes</h4>
                                <div className="flex flex-row min-h-24">
                                    {mySafetyDeck.map((card, index) => (
                                        <div
                                            key={index}
                                            className={`playing-card safety-deck card-${card}`}
                                        ></div>
                                    ))}
                                </div>
                            </div>

                            <div className="flex flex-row justify-between gap-2 w-full">
                                <div className="border-4 border-primary border-dashed w-1/3 p-1">
                                    <h4 className="ms-2">Pile de Vitesse</h4>
                                    <div className="flex flex-row ms-16 min-h-24">
                                        {myLimitDeck.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`playing-card limit-battle-deck card-${card}`}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                                <div className="border-4 border-primary border-dashed w-2/3 p-1">
                                    <h4 className="ms-2">Pile de Bataille</h4>
                                    <div className="flex flex-row ms-16 min-h-26">
                                        {myBattleDeck.map((card, index) => (
                                            <div
                                                key={index}
                                                className={`playing-card limit-battle-deck card-${card}`}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`flex flex-row group-hover:hidden relative ${currentGame.playerTurn !== myIndex ? (
                                    'opacity-75'
                                ) : (
                                    ''
                                )}`}>
                                {myHand.map((card, index) => (
                                    <div key={index} className="dropdown dropdown-top">
                                        <button
                                            className={`playing-card card-${card}`}
                                        ></button>
                                        {currentGame.playerTurn === myIndex && (
                                            <ul className="dropdown-content menu bg-base-100 rounded-box z-[11] w-52 p-4 shadow">
                                                <li>
                                                    <button className="btn btn-success mb-2 text-xl"
                                                            onClick={() => handleCardAction(card)}>Jouer
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="btn btn-error text-xl"
                                                            onClick={() => handleDiscard(card)}>Défausser
                                                    </button>
                                                </li>
                                            </ul>
                                        )}
                                    </div>)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <dialog id="endgameModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-2xl text-center">Partie teminée!</h3>
                    {hasWon !== null ? (
                        <>
                            {hasWon ? (
                                <p className="py-4 text-center text-lg">Vous avez gagné la partie. Félicitations!</p>
                            ) : (
                                <p className="py-4 text-center text-lg">Vous avez perdu la partie. Dommage...</p>
                            )
                            }
                        </>
                    ) : (
                        <p className="py-4 text-center text-lg">Match nul</p>
                    )}
                    <div className="flex items-center justify-center">
                        <button className={"btn btn-primary"} onClick={endGame}>Retour à l'accueil</button>
                    </div>
                </div>
            </dialog>
            <dialog id="giveupModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-2xl text-center">Demande d'abandon</h3>
                    <p className="py-4 text-center text-lg">Votre adversaire a abandonné la partie. Souhaitez-vous
                        prendre la victoire?</p>
                    <div className="flex items-center justify-center gap-4">
                        <button className={"btn btn-primary"} onClick={async () => {
                            await confirmEndgame(false)
                        }}>Déclarer match nul
                        </button>
                        <button className={"btn btn-primary"} onClick={async () => {
                            await confirmEndgame(true)
                        }}>Prendre la victoire
                        </button>
                    </div>
                </div>
            </dialog>
        </>
    );
}

export default AllureGame;