import {useEffect, useState} from "react";


/**
 * Les pions sont placés en cercle, sur 16 cases.
 * Le joueur [0] est bleu et le joueur [1] est orange
 */
const OdysseyPawn = ({isFinished, player, id, currentTile, callback }) => {
    const [x, setX] = useState(90)
    const [y, setY] = useState(0)

    useEffect(() => {
        setX(tileToCoords(currentTile).x)
        setY(tileToCoords(currentTile).y)
    }, [currentTile]);


    const convertCoords = (coords) => {
        return {x: coords.x + 228, y: coords.y + 208}
    }

    const tileToCoords = (tile) => {
        // le tableau fait 456x434px
        // Le centre est (228,208)

        // -18 -> 434

        // Si joueur 2 [1] alors +8 pour flip le board
        const flipped = player === 0
        const viewTile = (flipped ? tile : tile + 8) % 16

        let angle = (360/16*viewTile) + 90 + 22.5
        let radius = 150

        if (isFinished) {
            if (id % 2 === player) {
                return [
                    convertCoords({x: 0, y: 35}),
                    convertCoords({x: 0, y: 35}),
                    convertCoords({x: -0, y: 90}),
                    convertCoords({x: -0, y: 90}),
                ][id%4]
            } else {
                return [
                    convertCoords({x: 0, y: -35}),
                    convertCoords({x: 0, y: -35}),
                    convertCoords({x: -0, y: -90}),
                    convertCoords({x: -0, y: -90}),
                    convertCoords({x: -0, y: -90}),
                ][id%4]
            }
        } else {
            if (tile === -1) {
                if (id % 2 === player) {
                    return [
                        convertCoords({x: 50, y: 220}),
                        convertCoords({x: 50, y: 220}),
                        convertCoords({x: -50, y: 220}),
                        convertCoords({x: -50, y: 220}),
                    ][id%4]
                } else {
                    return [
                        convertCoords({x: 50, y: -220}),
                        convertCoords({x: 50, y: -220}),
                        convertCoords({x: -50, y: -220}),
                        convertCoords({x: -50, y: -220}),
                    ][id%4]
                }
            }
        }

        const calculatedX = radius * (Math.cos( angle * Math.PI / 180)) + 228
        const calculatedY = radius * (Math.sin(angle * Math.PI / 180)) + 208
        return {x:calculatedX, y:calculatedY};
    }


    const handleClick = async () => {
        if (isFinished) return;
        await callback(id)
    }


    return (
        <button className={"odyssey-pawn btn rounded-full border-0 w-12 h-12 " + (id % 2 === 0 ? "purple-planet" : "orange-planet")} onClick={handleClick} style={{
            position: "absolute",
            left: x,
            top: y,

        }}>
            {}
        </button>
    )
}




export default OdysseyPawn;