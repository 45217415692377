import React, {useEffect} from "react"
import Footer from "../components/Footer"
import {Link, useNavigate} from "react-router-dom";

import { MdLogin } from "react-icons/md";
import PresentationHeader from "../components/PresentationHeader"

const Presentation = () => {
    useEffect(() => {
        document.title = "RetroRealm"
    }, [])
    const navigate = useNavigate();


    return (
        <>
            <PresentationHeader />

            <main className="presentation-body">
                <section className="presentation-text p-10 mt-10">
                    <div className="container">
                        <h2>Bienvenue!</h2>
                        <p>
                            RetroRealm est un site de rencontre et de divertissement sur lequel vous pouvez clavader
                            avec
                            d'autres utilisateurs, échanger sur le forum et, bien sûr, jouer à des jeux!
                            Connectez-vous dès maintenant pour accéder au contenu incroyable de l'application.
                        </p>
                        <button className="btn btn-primary rounded-full text-white mt-8" onClick={() => {navigate("/register")}}>
                            Rejoindre la communauté
                        </button>
                    </div>
                </section>

                <section className="presentation-games bg-base-300 p-10 mt-10">
                    <div className="container">
                        <h2>Jeux</h2>
                        <p>Voici les jeux qui sont disponibles</p>
                        <div className="game-cards-wrapper">
                            <div className="game-cards ">
                                <div className="game-card bg-accent">
                                    <h3 className="game-title">Odyssey</h3>
                                    <img className="game-picture"
                                         src={require('../images/odyssey-image.png')}
                                         alt="placeholder"/>
                                    <p className="game-slogan">Saurez-vous être plus rusé que votre adversaire?</p>
                                    <p className="game-description flex-none">
                                        Un jeu de stratégie implicant des cartes, des billes et un plateau de jeu dans
                                        le thème de l'espace!
                                    </p>
                                </div>
                                <div className="game-card bg-accent">
                                    <h3 className="game-title">À Vive Allure!</h3>
                                    <img className="game-picture"
                                         src={require('../images/allure-image.png')}
                                         alt="placeholder"/>
                                    <p className="game-slogan">Serez-vous plus rapide que votre adversaire?</p>
                                    <p className="game-description flex-none">
                                        Un jeu de course de vaisseaux stimulant la
                                        réflexion avec ses nombreuses cartes amusantes et son plateau de jeu original.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <div className="bg-base-300 pt-5">
                <Footer/>
            </div>

        </>
    )
}

export default Presentation