import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {IoMdFemale, IoMdMale, IoMdTransgender} from "react-icons/io";
import { TbGalaxy, TbRocket } from "react-icons/tb";
import useTreatFriendRequest from "../hooks/useTreatFriendRequest";
import {IoPerson, IoPersonAdd, IoPersonRemove} from "react-icons/io5";
import {AuthContext} from "../contexts/AuthContext";

const User = ({id, username, pfpFileName, gender, age, favoriteGame, isFriend}) => {
    const {sendFriendRequest, deleteFriendRequest, friendRequests, setFriendRequests} = useTreatFriendRequest()
    const {authUser} = useContext(AuthContext);
    const [isActionLoading, setIsActionLoading] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [showToast, setShowToast] = useState(false)

    const handleCreateClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const receivedFriendRequest = await sendFriendRequest(senderId, receiverId)
        setFriendRequests([...friendRequests, receivedFriendRequest])
        setToastMessage("Demande d'ami envoyée")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDeleteClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const isDeleted = await deleteFriendRequest(senderId, receiverId)
        if (isDeleted) {
            setFriendRequests(friendRequests.filter(friendRequest => friendRequest.sender !== senderId && friendRequest.receiver !== receiverId))
            setToastMessage("Demande d'ami annulée")
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        }
        setIsActionLoading(false)
    }

    return (
        <li key={id}
            className="card card-compact flex-row bg-neutral border-2 border-secondary border-opacity-50 rounded-2xl shadow-xl m-3 p-5">
            <Link to={`/profile/${id}`}
                  className="inline-flex align-middle hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                <img
                    className="rounded-full w-12 ring-2 ring-white ring-opacity-65"
                    src={`https://robohash.org/${pfpFileName}?set=set2`}
                    alt={username}
                />
                <span className="text-xl leading-10 ms-3 me-2">{username}</span>
            </Link>
            <span className="text-xl text-lime-100 leading-10 me-2">{age} ans</span>
            {gender === "MALE" && (
                <IoMdMale className="text-indigo-400 mt-1 me-2" size={32}/>
            )}
            {gender === "FEMALE" && (
                <IoMdFemale className="text-fuchsia-400 mt-1 me-2" size={32}/>
            )}
            {gender === "OTHER" && (
                <IoMdTransgender className="text-amber-400 mt-1 me-2" size={32}/>
            )}
            {favoriteGame === "ODYSSEY" && (
                <TbGalaxy className="text-primary mt-1 me-2" size={32}/>
            )}
            {favoriteGame === "ALLURE" && (
                <TbRocket className="text-secondary mt-1" size={32}/>
            )}
            {(!authUser.friends.some(authFriend => authFriend._id === id) &&
                !friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                    friendRequest.receiver === id) &&
                !friendRequests.some(friendRequest => friendRequest.sender === id &&
                    friendRequest.receiver === authUser._id)) ? (
                <>
                    {isActionLoading ? (
                        <span className="loading loading-spinner loading-lg"></span>
                    ) : (
                        <button className="ms-auto" onClick={(e) => {
                            e.stopPropagation()
                            handleCreateClick(authUser._id, id)
                        }}>
                            <IoPersonAdd size={32} className="text-white"/>
                        </button>
                    )}
                </>
            ) : (!authUser.friends.some(authFriend => authFriend._id === id) &&
                friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                    friendRequest.receiver === id)) ? (
                <>
                    {isActionLoading ? (
                        <span className="loading loading-spinner loading-lg"></span>
                    ) : (
                        <button className="ms-auto" onClick={() => {
                            handleDeleteClick(authUser._id, id)
                        }}>
                            <IoPersonRemove size={32} className="text-white"/>
                        </button>
                    )}
                </>
            ) : (
                <IoPerson size={32} className="ms-auto self-center text-primary"/>
            )}
            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>{toastMessage}</span>
                </div>
            </div>
        </li>
    );
};

export default User;
