import React, {useContext, useState} from "react"
import axios from "axios";

import {AuthContext} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {SocketContext} from "../contexts/SocketContext";

const ModActions = () => {
    const navigate = useNavigate()
    const {authUser} = useContext(AuthContext)

    const totalUsers = useContext(SocketContext).users
    const {onlineUsers} = useContext(SocketContext)
    const [text, setText] = useState("")

    const [suspendFormData, setSuspendFormData] = useState({
        time: 3
    })

    const handleChange = async e => {
        setText(e.target.value.trim().toUpperCase())
    }

    const changeSupendFormData = e => {
        const {value} = e.target
        setSuspendFormData({
            ...suspendFormData,
            time: parseInt(value)
        })
    }

    const promote = async id => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${id}/promote`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const demote = async id => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${id}/demote`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const suspend = id => {
        document.getElementById('my_modal_3xx').showModal()
        setSuspendFormData({
            user: totalUsers.find(x => x._id = id),
            time: 3
        })
    }

    const unsuspend = async id => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${id}/unsuspend`
        try {
            await axios.patch(url,{}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const ban = async id => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${id}/ban`
        try {
            await axios.patch(url,{}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const unban = async id => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${id}/unban`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const confirm_dialog = async e => {
        e.preventDefault()

        const now = new Date()
        const hours = suspendFormData.time * 60 * 60 * 1000
        const date = now.setTime(now.getTime() + hours)
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${suspendFormData.user._id}/suspend`

        try {
            await axios.patch(url, {suspendedUntil: date},{
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }

        close_dialog()
    }

    const close_dialog = () => {
        document.getElementById('my_modal_3xx').close()
    }

    const getUserOptions = user => {
        if (user.userType === 'ADMIN')
            return

        if (user._id === authUser._id) {
            return <p>Moi</p>
        }

        if (user.isBanned)
            return (
                <div className="gap-3 flex">
                    <span className="text-red-500 inline-block align-middle">{user.username} a été banni</span>
                    <button className="btn btn-sm btn-error" onClick={() => unban(user._id)}>
                        Annuler
                    </button>
                </div>
            )

        if (user.suspendedUntil)
            return (
                <div className="gap-3 flex">
                    <p className="text-amber-400">{user.username} a été suspendu
                        jusqu'au {new Intl.DateTimeFormat('fr-ca', {
                            dateStyle: 'long',
                            timeStyle: 'short'
                        }).format(new Date(user.suspendedUntil))}</p>

                    <button className="btn btn-sm btn-error" onClick={() => unsuspend(user._id)}>
                        Annuler
                    </button>
                </div>

            )

        return (
            <div className="gap-3 flex">
                {authUser?.userType === 'ADMIN' && user?.userType === 'STANDARD' &&
                    <button className="btn btn-sm btn-success" onClick={() => promote(user._id)}>
                        Promouvoir
                    </button>
                }

                {authUser?.userType === 'ADMIN' && user?.userType === 'MOD' &&
                    <button className="btn btn-sm btn-success" onClick={() => demote(user._id)}>
                        Rétrograder
                    </button>
                }

                <button className="btn btn-sm btn-warning" onClick={() => suspend(user._id)}>
                    Suspendre
                </button>
                <button className="btn btn-sm btn-error" onClick={() => ban(user._id)}>
                    Bannir
                </button>
            </div>
        )
    }

    return (
        <>
            <div className="m-auto max-w-3xl p-6 border rounded-lg shadow bg-gray-800 border-gray-700 mt-7">
                <form className="mb-6">
                    <div className="relative">
                        <div
                            className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-400" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search" name="text" onChange={handleChange}
                               className="block w-full p-4 ps-10 text-sm border rounded-lg border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                               placeholder="Rechercher un utilisateur"/>
                    </div>
                </form>

                <ul className="menu bg-base-200 rounded-box w-full mb-3">
                    {totalUsers.filter(x => x.username.toUpperCase().includes(text)).map((user) => (
                        <li key={user._id}>
                            <div className="flex flex-row justify-between">
                                <a href={"/profile/" + user._id}>
                                    <div className="flex items-center gap-3">
                                        <div
                                            className={`avatar ${onlineUsers.includes(user._id) ? 'online' : 'offline'}`}>
                                            <div
                                                className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                                <img className="rounded-full"
                                                     src={`https://robohash.org/${user.pfpFileName}?set=set2`}
                                                     alt="Photo de profil"/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="font-bold">{user.username}</div>
                                            <div className="text-sm opacity-50">{user.userType}</div>
                                        </div>
                                    </div>
                                </a>
                                {getUserOptions(user)}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <dialog id="my_modal_3xx" className="modal modal-bottom sm:modal-middle">
                <form className="modal-box" method="post" onSubmit={confirm_dialog}>
                    <h3 className="font-bold text-lg">{"Suspendre " + suspendFormData.user?.username}</h3>

                    <label htmlFor="countries" className="block mb-2 text-sm font-medium text-white">Durée de la
                        suspension</label>
                    <select name="time" value={suspendFormData.time} onChange={changeSupendFormData}
                            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                        <option value={3}>3 heures</option>
                        <option value={12}>12 heures</option>
                        <option value={24}>24 heures</option>
                        <option value={72}>3 jours</option>
                    </select>

                    <div className="modal-action">
                        <div className="w-full grid sm:grid-cols-2 gap-3">
                            <button type="submit" className="btn btn-sm btn-success"
                                    onClick={confirm_dialog}>Confirmer
                            </button>
                            <button type="reset" className="btn btn-sm btn-error" onClick={close_dialog}>Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </dialog>
        </>
    )
}

export default ModActions