import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {IoMdTrash} from "react-icons/io";
import {SocketContext} from "../contexts/SocketContext"
import {MdDeleteForever, MdRestoreFromTrash} from "react-icons/md"


const MessageList = ({currentBox}) => {
    const navigate = useNavigate();
    const {socket, onlineUsers} = useContext(SocketContext)

    const [messages, setMessages] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")

    const fetchMessages = async () => {
        setIsLoading(true)
        let url
        const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
        switch (currentBox) {
            case "INBOX": url =  `${API_URL}/message/received`; break;
            case "OUTBOX": url =  `${API_URL}/message/sent`; break;
            case "TRASHED": url =  `${API_URL}/message/trashed`; break;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                setMessages(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchMessages()
    }, [currentBox])

    useEffect(() => {
        socket?.on('new-message', (data) => {
            fetchMessages()
            setToastMessage(data.notification.content)
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        })

        return () => socket?.off('new-message')
    }, [socket])


    const MessageItem = ({msg}) => {
        const [message, setMessage] = useState(msg)
        const [object, setObject] = useState("")
        const [text, setText] = useState("")

        useEffect(() => {
            setObjectAndMessageDisplay(message)
        }, [message])

        const setObjectAndMessageDisplay = (message) => {
            let msgObject = message.object;
            if (msgObject.length > 25) {
                msgObject = msgObject.substring(0, 25);
                msgObject += "..."
            }
            setObject(msgObject)

            let msgText = message.message;
            if (msgText.length > 25) {
                msgText = msgText.substring(0, 25);
                msgText += "..."
            }
            setText(msgText)
        }

        const deleteMessage = async () => {
            try {
                const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
                const url = `${API_URL}/message/delete/${message._id}`

                await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                })
            } catch (error) {
                console.error(error)
                switch (error.response.status) {
                    case 400:
                        navigate("/400")
                        break
                    case 401:
                        localStorage.removeItem('token')
                        navigate("/login")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate('/404')
                        break
                    case 409:
                        navigate("/409")
                        break
                    case 422:
                        navigate('/422')
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
            }

            const currentId = window.location.href.split('/')[window.location.href.split('/').length - 1]
            if (message._id === currentId) {
                navigate("/messenger")
            }

            await fetchMessages()
        }

        const restoreMessage = async () => {
            try {
                const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
                const url = `${API_URL}/message/restore/${message._id}`

                await axios.post(url, {}, {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                })
            } catch (error) {
                console.error(error)
                switch (error.response.status) {
                    case 400:
                        navigate("/400")
                        break
                    case 401:
                        localStorage.removeItem('token')
                        navigate("/login")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate('/404')
                        break
                    case 409:
                        navigate("/409")
                        break
                    case 422:
                        navigate('/422')
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
            }

            navigate("/messenger/" + message._id)

            await fetchMessages()
        }

        const markAsRead = async () => {
            try {
                const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
                const response = await axios.post(API_URL + `/message/markAsRead/${message._id}`, {}, {
                    headers: {
                        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                })
                if (response.status === 200) {
                    setMessage(response.data)
                    navigate(`/messenger/${message._id}`)
                }
            } catch (error) {
                console.error(error)
                switch (error.response.status) {
                    case 400:
                        navigate("/400")
                        break
                    case 401:
                        localStorage.removeItem("token")
                        navigate("/login")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate('/404')
                        break
                    case 409:
                        navigate("/409")
                        break
                    case 422:
                        navigate('/422')
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
            }
        }

        const handleClick = async () => {
            if (currentBox === "OUTBOX") {
                return navigate(`/messenger/${message._id}`)
            }

            await markAsRead()
        }

        switch (currentBox) {
            case "INBOX":
                return (
                    <div
                        className={`mb-4 p-5 rounded-2xl ${window.location.pathname.match(new RegExp(`${message._id}$`)) ? 'bg-base-200' : 'bg-base-100 hover:bg-base-200'} hover:cursor-pointer transition flex justify-between items-center w-full`}
                        onClick={handleClick}>
                        <div className="flex justify-start items-center gap-4">
                            <div
                                className={`avatar ${onlineUsers.includes(message.sender._id) ? 'online' : 'offline'}`}>
                                <div className="ring-primary ring-offset-base-300 w-12 rounded-full ring ring-offset-2">
                                    <img className="rounded-full"
                                         src={`https://robohash.org/${message.sender.pfpFileName}?set=set2`}
                                         alt="Photo de profil"/>
                                </div>
                            </div>
                            <div>
                                <h3 className={`${!message.readByReceiver && 'font-bold'} text-xl`}>{message.sender.username}</h3>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-lg`}>{object}</p>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-base`}>{text}</p>
                            </div>
                        </div>

                        <button onClick={(e) => {
                            e.stopPropagation()
                            deleteMessage()
                        }} className="hover:bg-base-100 transition rounded-full p-1">
                            <IoMdTrash size={32}/>
                        </button>
                    </div>
                )
            case "OUTBOX":
                return (
                    <div
                        className={`mb-4 p-5 rounded-2xl ${window.location.pathname.match(new RegExp(`${message._id}$`)) ? 'bg-base-200' : 'bg-base-100'} hover:bg-base-200 hover:cursor-pointer transition flex justify-between items-center w-full`}
                        onClick={handleClick}>
                        <div className="flex justify-start items-center gap-4">
                            <div
                                className={`avatar ${onlineUsers.includes(message.receiver._id) ? 'online' : 'offline'}`}>
                                <div className="ring-primary ring-offset-base-300 w-12 rounded-full ring ring-offset-2">
                                    <img className="rounded-full"
                                         src={`https://robohash.org/${message.receiver.pfpFileName}?set=set2`}
                                         alt="Photo de profil"/>
                                </div>
                            </div>
                            <div>
                                <h3 className={`${!message.readByReceiver && 'font-bold'} text-xl`}>{message.receiver.username}</h3>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-lg`}>{object}</p>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-base`}>{text}</p>
                            </div>
                        </div>

                        <button onClick={(e) => {
                            e.stopPropagation()
                            deleteMessage()
                        }} className="hover:bg-base-100 transition rounded-full p-1">
                            <IoMdTrash size={32}/>
                        </button>
                    </div>
                )
            case "TRASHED":
                return (
                    <div
                        className={`mb-4 p-5 rounded-2xl ${window.location.pathname.match(new RegExp(`${message._id}$`)) ? 'bg-base-200' : 'bg-base-100'} hover:bg-base-200 hover:cursor-pointer transition flex justify-between items-center w-full`}
                        onClick={handleClick}>
                        <div className="flex justify-start items-center gap-4">
                            <div
                                className={`avatar ${onlineUsers.includes(message.receiver._id) ? 'online' : 'offline'}`}>
                                <div className="ring-primary ring-offset-base-300 w-12 rounded-full ring ring-offset-2">
                                    <img className="rounded-full"
                                         src={`https://robohash.org/${message.receiver.pfpFileName}?set=set2`}
                                         alt="Photo de profil"/>
                                </div>
                            </div>
                            <div>
                                <h3 className={`${!message.readByReceiver && 'font-bold'} text-xl`}>{message.receiver.username}</h3>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-lg`}>{object}</p>
                                <p className={`${!message.readByReceiver && 'font-bold'} text-base`}>{text}</p>
                            </div>
                        </div>

                        <div className="flex justify-between items-center">
                            <button onClick={(e) => {
                                e.stopPropagation()
                                restoreMessage()
                            }} className="hover:bg-base-100 transition rounded-full p-1">
                                <MdRestoreFromTrash size={32}/>
                            </button>
                            <button onClick={(e) => {
                                e.stopPropagation()
                                deleteMessage()
                            }} className="hover:bg-base-100 transition rounded-full p-1">
                                <MdDeleteForever size={32}/>
                            </button>
                        </div>

                    </div>
                )
            default:
                return <></>
        }
    }


    return (
        <>
            {isLoading ? (
                <div>
                    <div className="skeleton bg-base-100 animation-correction h-28 w-full mb-4"></div>
                    <div className="skeleton bg-base-100 animation-correction h-28 w-full mb-4"></div>
                    <div className="skeleton bg-base-100 animation-correction h-28 w-full mb-4"></div>
                </div>
            ) : (
                <div className="flex-grow overflow-auto">
                    {messages.length > 0 ? (
                        <>
                            {messages.map(msg => (
                                <MessageItem msg={msg} key={msg._id}/>
                            ))}
                        </>
                    ) : (
                        <p className="text-center text-lg">Aucun message à afficher</p>
                    )}
                </div>
            )
            }

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-info">
                    <span>{toastMessage}</span>
                </div>
            </div>
        </>
    )
}

export default MessageList