import Header from "../components/Header"
import {Link, Outlet, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack, IoMdSettings} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import Footer from "../components/Footer"
import React, {useEffect} from "react"
import AllureLogo from "../images/allure/logo.png"


const Games = () => {
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Jeux | RetroRealm"
    }, [])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56}
                                                                                                               className="text-white"/></Link>
                    <h2 className="text-5xl">Jeux</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="flex justify-between gap-4 mb-5">
                    <div onClick={() => navigate('/matchmaking/odyssey')} className="card bg-accent hover:cursor-pointer hover:brightness-75 transition duration-300 w-1/2 shadow-xl">
                        <figure className="px-10 pt-10">
                            <img
                                src={require('../images/odyssey-image.png')}
                                alt="Odyssey"
                                className="rounded-xl"/>
                        </figure>
                        <div className="card-body items-center text-center text-black">
                            <h2 className="card-title text-4xl">Odyssey</h2>
                            <p className="text-xl">Un jeu de stratégie implicant des cartes, des billes et un plateau de jeu dans le thème de l'espace!</p>
                        </div>
                    </div>

                    <div onClick={() => navigate('/matchmaking/allure')} className="card bg-accent hover:cursor-pointer hover:brightness-75 transition duration-300 w-1/2 shadow-xl">
                        <figure className="px-10 pt-10">
                            <img
                                src={require('../images/allure-image.png')}
                                alt="À Vive Allure!"
                                className="rounded-xl"/>
                        </figure>
                        <div className="card-body items-center text-center text-black">
                            <h2 className="card-title text-4xl">À vive allure!</h2>
                            <p className="text-xl">Un jeu de course de vaisseaux stimulant la réflexion avec ses nombreuses cartes amusantes et son plateau de jeu original.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Games