import React from "react";
import { IoIosArrowUp } from "react-icons/io";

const Footer = () => {
    return (
        <>
            <footer className="bg-neutral">
                <div className="mx-auto w-11/12 max-w-7xl">
                    <button onClick={() => {window.scroll(0, 0)}} className="btn btn-primary btn-circle btn-icon"><IoIosArrowUp size={32}/></button>
                    <p className="text-center">Copyright © 2024 - Tous droits réservés par Adam Demers, Loïc Boiteux, Samuel Lavoie et Keven Ouellet</p>
                    <div className="mr-12">
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer