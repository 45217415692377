import {Navigate, Route, Routes} from "react-router-dom"
import Presentation from "./pages/Presentation"
import Login from "./pages/Login"
import Inscription from "./pages/Inscription";
import Home from "./pages/Home"
import ControlPanel from "./pages/ControlPanel"
import ValidationEmail from "./pages/ValidationEmail"
import Error from "./pages/Error"
import ProtectedRoute from "./utils/ProtectedRoute"
import ProfileAuth from "./pages/ProfileAuth"
import ProfileOverview from "./components/ProfileOverview"
import ProfileFriends from "./components/ProfileFriends"
import ProfileStats from "./components/ProfileStats"
import ProfileEdit from "./components/ProfileEdit"
import ProfileEmailEdit from "./pages/ProfileEmailEdit"
import ProfilePasswordEdit from "./pages/ProfilePasswordEdit"
import ForgottenPassword from "./pages/ForgottenPassword"
import ProfileDelete from "./pages/ProfileDelete"
import Messenger from "./pages/Messenger";
import MessageWriter from "./components/MessageWriter";
import MessageViewer from "./components/MessageViewer";
import ProfileForeign from "./pages/ProfileForeign"
import ProfileForeignFriends from "./components/ProfileForeignFriends"
import Forum from "./pages/Forum";
import Category from "./pages/Category";
import Thread from "./pages/Thread";
import SearchUser from "./pages/SearchUser";
import MessageResponder from "./components/MessageResponder"
import Play from "./pages/Play"
import Games from "./pages/Games"
import Matchmaking from "./pages/Matchmaking"
import Banned from "./pages/Banned";

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Presentation />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Inscription />} />
            <Route path="/validate-email" element={<ValidationEmail/>}/>
            <Route path="/control-panel" element={<ProtectedRoute><ControlPanel /></ProtectedRoute>} />
            <Route path="/forgotten-password" element={<ForgottenPassword />} />
            <Route path="/banned" element={<Banned />} />
            {/* Routes pour les pages principales du site */}
            <Route path="/home" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
            <Route path="/profile" element={<ProtectedRoute><ProfileAuth/></ProtectedRoute>}>
                <Route path="" element={<ProfileOverview/>}/>
                <Route path="friends" element={<ProfileFriends/>}/>
                <Route path="stats" element={<ProfileStats/>}/>
            </Route>
            <Route path="profile-edit" element={<ProtectedRoute><ProfileEdit/></ProtectedRoute>}/>
            <Route path="profile-edit/email" element={<ProtectedRoute><ProfileEmailEdit/></ProtectedRoute>}/>
            <Route path="profile-edit/password" element={<ProtectedRoute><ProfilePasswordEdit/></ProtectedRoute>}/>
            <Route path="profile-delete" element={<ProtectedRoute><ProfileDelete /></ProtectedRoute>} />

            <Route path="/messenger" element={<ProtectedRoute><Messenger/></ProtectedRoute>}>
                <Route path="new" element={<MessageWriter/>}/>
                <Route path="reply" element={<MessageResponder />} />
                <Route path=":messageId" element={<MessageViewer/>}/>
            </Route>
            <Route path="/profile/:id" element={<ProtectedRoute><ProfileForeign /></ProtectedRoute>}>
                <Route path="" element={<ProfileOverview/>}/>
                <Route path="friends" element={<ProfileForeignFriends/>}/>
                <Route path="stats" element={<ProfileStats/>}/>
            </Route>

            <Route path="/search-users" element={<ProtectedRoute><SearchUser /></ProtectedRoute>} />

            <Route path="/forum" element={<ProtectedRoute><Forum /></ProtectedRoute>} />
            <Route path="/forum/threads/:threadId" element={<ProtectedRoute><Thread /></ProtectedRoute>} />
            <Route path="/forum/threads" element={<Navigate to="/forum"/>} />
            <Route path="/forum/categories/:categoryId" element={<ProtectedRoute><Category /></ProtectedRoute>} />

            <Route path="/games" element={<ProtectedRoute><Games /></ProtectedRoute>}/>
            <Route path="/matchmaking/odyssey" element={<ProtectedRoute><Matchmaking /></ProtectedRoute>} />
            <Route path="/matchmaking/allure" element={<ProtectedRoute><Matchmaking /></ProtectedRoute>}/>
            <Route path="/play/odyssey/:gameId" element={<ProtectedRoute><Play/></ProtectedRoute>}/>
            <Route path="/play/allure/:gameId" element={<ProtectedRoute><Play/></ProtectedRoute>}/>

            {/* Routes pour les pages d'erreur */}
            <Route path="/400" element={<Error code={400}/>}/>
            <Route path="/401" element={<Error code={401}/>}/>
            <Route path="/403" element={<Error code={403}/>}/>
            <Route path="/404" element={<Error code={404}/>}/>
            <Route path="/409" element={<Error code={409}/>}/>
            <Route path="/422" element={<Error code={422}/>}/>
            <Route path="/500" element={<Error code={500}/>}/>

            {/* Routes pour les pages inexistantes */}
            <Route path="/*" element={<Navigate to="/404"/>}/>
        </Routes>
    )
}

export default App