import {Link, Outlet, useNavigate, useParams} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import axios from "axios"
import Header from "../components/Header"
import {IoIosWarning, IoMdArrowRoundBack, IoMdSettings} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import Footer from "../components/Footer"
import {AuthContext} from "../contexts/AuthContext"
import {IoPersonAdd, IoPersonRemove} from "react-icons/io5"
import {MdDeleteForever} from "react-icons/md"
import {SocketContext} from "../contexts/SocketContext"
import useTreatFriendRequest from "../hooks/useTreatFriendRequest"
import ReportModal from "../components/ReportModal"


const ProfileForeign = () => {
    const navigate = useNavigate()
    const {id} = useParams()

    const [user, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isActionLoading, setIsActionLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [friendToDelete, setFriendToDelete] = useState({})

    const {authUser, setAuthUser} = useContext(AuthContext)
    const {socket} = useContext(SocketContext)
    const {sendFriendRequest, deleteFriendRequest, handleAccept, handleDeny, friendRequests, setFriendRequests} = useTreatFriendRequest()

    const fetchUser = async () => {
        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.get(API_URL + `/user/${id}`, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setUser(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    navigate("/400")
                    break
                case 422:
                    navigate('/422')
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const fetchFriendRequests = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.get(API_URL + '/friend-request', {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setFriendRequests(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    useEffect(() => {
        document.title = "Profil | RetroRealm"

        if (id === authUser._id) {
            navigate('/profile')
        }

        fetchUser()
        fetchFriendRequests()
    }, [id])

    useEffect(() => {
        socket?.on('friend-request', (data) => {
            setFriendRequests([...friendRequests, data.friendRequest])
        })

        return () => socket?.off('friend-request')
    }, [socket, setFriendRequests, friendRequests])

    const handleCreateClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const receivedFriendRequest = await sendFriendRequest(senderId, receiverId)
        setFriendRequests([...friendRequests, receivedFriendRequest])
        setToastMessage("Demande d'ami envoyée")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDeleteClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const isDeleted = await deleteFriendRequest(senderId, receiverId)
        if (isDeleted) {
            setFriendRequests(friendRequests.filter(friendRequest => friendRequest.sender !== senderId && friendRequest.receiver !== receiverId))
            setToastMessage("Demande d'ami annulée")
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        }
        setIsActionLoading(false)
    }

    const handleAcceptClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleAccept(senderId, receiverId)
        setAuthUser(newAuthUser)
        setToastMessage("Demande d'ami acceptée")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDenyClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const newAuthUser = await handleDeny(senderId, receiverId)
        setAuthUser(newAuthUser)
        setToastMessage("Demande d'ami refusée.")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDelete = async () => {
        setIsActionLoading(true)
        const updatedFriends = authUser.friends.filter(friend => friend._id !== friendToDelete._id)
        setAuthUser({
            ...authUser,
            friends: updatedFriends
        })

        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            await axios.post(API_URL + "/user/friends/delete", {
                authUserId: authUser._id,
                friendUserId: friendToDelete._id
            }, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
        setIsActionLoading(false)
        setToastMessage("Ami supprimé de la liste d'amis")
        fetchUser()
        fetchFriendRequests()
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56}
                                                                                                               className="text-white"/></Link>
                    <h2 className="text-5xl">Profil</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 flex justify-between items-center mb-5">
                    <div className="flex gap-6 justify-start items-center">
                        <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                            <img className="rounded-full" src={`https://robohash.org/${user.pfpFileName}?set=set2`}
                                 alt="Photo de profil"/>
                        </div>
                        <div>
                            <h3 className="text-3xl">{user.username}</h3>
                            <p className="text-xl">{calculateAge(user.dob)} ans</p>
                        </div>
                    </div>
                    {isActionLoading ? (
                        <span className="loading loading-spinner loading-lg"></span>
                    ) : (
                        <div className="flex gap-4 justify-end items-center">
                            <button onClick={() => {document.getElementById('modal_report').showModal()}}>
                                <IoIosWarning size={32}/>
                            </button>
                            {(!authUser.friends.some(authFriend => authFriend._id === user._id) &&
                                !friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                                    friendRequest.receiver === user._id) &&
                                !friendRequests.some(friendRequest => friendRequest.sender === user._id &&
                                    friendRequest.receiver === authUser._id)) ? (
                                <button onClick={() => {
                                    handleCreateClick(authUser._id, user._id)
                                }}>
                                    <IoPersonAdd size={32} className="text-white"/>
                                </button>
                            ) : (!authUser.friends.some(authFriend => authFriend._id === user._id) &&
                                friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                                    friendRequest.receiver === user._id)) ? (
                                <button onClick={() => {
                                    handleDeleteClick(authUser._id, user._id)
                                }}>
                                    <IoPersonRemove size={32} className="text-white"/>
                                </button>
                            ) : (!authUser.friends.some(authFriend => authFriend._id === user._id) &&
                                friendRequests.some(friendRequest => friendRequest.sender === user._id &&
                                    friendRequest.receiver === authUser._id)) ? (
                                <>
                                    <button className="btn btn-success" onClick={() => {
                                        handleAcceptClick(user._id, authUser._id)}}>
                                        Accepter
                                    </button>
                                    <button className="btn btn-error" onClick={() => {
                                        handleDenyClick(user._id, authUser._id)}}>
                                        Refuser
                                    </button>
                                </>

                            ) : authUser.friends.some(authFriend => authFriend._id === user._id) ? (
                                <button onClick={(e) => {
                                    e.stopPropagation()
                                    document.getElementById('validationModal').showModal()
                                    setFriendToDelete(user)
                                }}>
                                    <MdDeleteForever size={32} className="text-white"/>
                                </button>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </div>

                {isLoading ? (
                    <div className="skeleton h-64 w-full mb-5 rounded-2xl"></div>
                ) : (
                    <>
                        <div className="flex justify-around items-center mb-5">
                            <Link to={`/profile/${user._id}`}
                                  className={`p-3 ${window.location.pathname === `/profile/${user._id}` ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                                Aperçu
                            </Link>

                            <Link to={`/profile/${user._id}/friends`}
                                  className={`p-3 ${window.location.pathname === `/profile/${user._id}/friends` ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                                Amis
                            </Link>

                            <Link to={`/profile/${user._id}/stats`}
                                  className={`p-3 ${window.location.pathname === `/profile/${user._id}/stats` ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                                Statistiques
                            </Link>
                        </div>
                        <Outlet context={user}/>
                    </>
                )}
            </div>
            <Footer/>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>{toastMessage}</span>
                </div>
            </div>

            <dialog id="validationModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-3xl">Supprimer un ami</h3>
                    <p className="py-4 text-xl">Souhaitez-vous vraiment supprimer cet utilisateur de votre liste
                        d'amis?</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button onClick={handleDelete} className="btn btn-primary mr-2 text-lg">Confirmer</button>
                            <button className="btn text-lg">Annuler</button>
                        </form>
                    </div>
                </div>
            </dialog>

            <ReportModal user={user} />
        </div>
    )
}

export default ProfileForeign