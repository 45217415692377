import React, {useContext, useEffect, useState} from "react"
import Footer from "../components/Footer"
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {AuthContext} from "../contexts/AuthContext";
import PresentationHeader from "../components/PresentationHeader";
import {FiAlertCircle} from "react-icons/fi"

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const ValidationEmail = () => {
    const {authUser, setAuthUser} = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Validation | RetroRealm"
        if (authUser.isAccountValidated) {
            navigate("/home")
        }
        if (!localStorage.getItem("token")) {
            navigate("/")
        }
        generateEmailCode()
    }, [])

    const [emailSent, setEmailSent] = useState(false)
    const [codeError, setCodeError] = useState("")
    const [code, setCode] = useState("")

    const [isCodeLoading, setIsCodeLoading] = useState(false)
    const [isValidationLoading, setIsValidationLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCode(value);
    }

    const generateEmailCode = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            await axios.post(API_URL + '/auth/generate-code', {},
                { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` } })
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 401:
                    navigate('/401')
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const submitForm = async (e) => {
        e.preventDefault()

        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            setIsValidationLoading(true)
            const response = await axios.post(API_URL + '/auth/validate-email', {code: code},
                { headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` } })
            if (response.status === 200) {
                navigate("/home")
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    navigate("/400")
                    break
                case 422:
                    setCodeError(error.response.data)
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsValidationLoading(false)
        }
    }

    const resendCode = async () => {
        setIsCodeLoading(true)
        await generateEmailCode()
        setIsCodeLoading(false)
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <PresentationHeader/>
            <div className="flex-grow flex justify-center items-center">
                <div className="rounded-3xl bg-base-300 w-11/12 max-w-xl p-10 flex flex-col justify-center items-center my-10">
                    <h1 className="text-2xl mb-4 text-center">Validation par courriel</h1>
                    <p className="text-center">
                        Un courriel contenant un code de validation à été envoyé à l'adresse <span
                        className="font-bold text-white">{authUser.email}</span>
                    </p>

                    <p className="text-center">Entrez le code pour valider votre compte.</p>
                    <form onSubmit={submitForm} className="w-full">
                        <label className="form-control block w-full">
                            <div className="label">
                                <span className="label-text text-lg">Code</span>
                            </div>
                            <input name="validation-code" type="text" placeholder="Tapez ici" value={code}
                                   onChange={handleChange}
                                   className="input input-bordered input-primary w-full rounded-full block"/>
                        </label>
                        {codeError.code && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{codeError.code}</p>
                            </div>
                        )}
                        <div className="flex flex-col gap-4 items-center">
                            {isValidationLoading ? (
                                <button className="btn btn-primary rounded-full w-44 mt-8">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <input type="submit" className="btn btn-primary rounded-full w-44 mt-8"
                                       value="Valider"/>
                            )}

                            {isCodeLoading ? (
                                <button type="button" className="btn btn-secondary rounded-full w-44">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <button type="button" className="btn btn-secondary rounded-full w-44"
                                        onClick={resendCode}>
                                    Envoyer un autre code
                                </button>
                            )}


                        </div>
                    </form>
                    <Link to="/home" className="link link-primary block text-center text-lg mt-8">Accueil</Link>
                </div>
            </div>
            <Footer/>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>Code de validation renvoyé</span>
                </div>
            </div>
        </div>
    )
}

export default ValidationEmail