import Header from "../components/Header"
import Footer from "../components/Footer"
import {Link, Outlet, useNavigate, useOutlet} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import MessageWriter from "../components/MessageWriter";
import {MdMoveToInbox, MdOutbox} from "react-icons/md";
import {IoAdd, IoMailOpen} from "react-icons/io5"
import MessageList from "../components/MessageList";
import {IoMdArrowRoundBack, IoMdTrash} from "react-icons/io";

const Messenger = () => {
    const navigate = useNavigate()
    const {authUser} = useContext(AuthContext)
    const hasOutlet = useOutlet()

    const [currentBox, setCurrentBox] = useState("INBOX")

    useEffect(() => {
        document.title = "Messagerie | RetroRealm"
    }, [authUser, navigate])

    return (
        <div className="flex justify-between items-center h-screen">
            <div className="w-1/4 bg-base-300 h-full px-5 py-2 flex flex-col">
                <div>
                    <div className="flex justify-between items-center mb-5">
                        <Link to="/home" className="rounded-full hover:bg-base-100 transition"><IoMdArrowRoundBack
                            size={44}
                            className="text-white"/></Link>
                        <h1 className="pb-1"><Link className="pixel-font" to={'/home'}>RetroRealm</Link></h1>
                        <p className="mr-11"></p>
                    </div>

                    <div className="mb-2">
                        <div className="flex justify-center">
                            <button onClick={() => navigate("/messenger/new")}
                                    className="btn btn-primary rounded-full w-40 text-lg mb-5">
                                <IoAdd size={32}/>
                                Nouveau
                            </button>
                        </div>

                        <div className="flex justify-around items-center mb-5">
                            <button onClick={() => {
                                setCurrentBox("INBOX")
                            }}
                                    className={`p-3 ${currentBox === "INBOX" ? "border-white" : "border-base-300"} hover:border-white border-b-2 transition text-lg text-white text-center w-28`}>
                                Réception
                            </button>

                            <button onClick={() => {
                                setCurrentBox("OUTBOX")
                            }}
                                    className={`p-3 ${currentBox === "OUTBOX" ? "border-white" : "border-base-300"} hover:border-white border-b-2 transition text-lg text-white text-center w-28`}>
                                Envoi
                            </button>

                            <button onClick={() => {
                                setCurrentBox("TRASHED")
                            }}
                                    className={`p-3 ${currentBox === "TRASHED" ? "border-white" : "border-base-300"} hover:border-white border-b-2 transition text-lg text-white text-center w-28`}>
                                Corbeille
                            </button>
                        </div>
                    </div>
                </div>

                <MessageList currentBox={currentBox}/>
            </div>
            <div className={"w-3/4 bg-base-100 rounded-2xl h-full overflow-y-scroll px-1 py-2"}>
                {hasOutlet ? (
                    <Outlet context={currentBox}/>
                ) : (
                    <div className="flex justify-center items-center h-full">
                        <div className="flex flex-col justify-between items-center">
                            <IoMailOpen className="text-white text-center mb-4" size={148}/>
                            <h2>Aucun message sélectionné</h2>
                            <p className="text-xl">Sélectionnez un message pour le lire</p>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default Messenger