import {useContext, useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
import axios from "axios"
import {IoSend} from "react-icons/io5"
import {AuthContext} from "../contexts/AuthContext"
import {SocketContext} from "../contexts/SocketContext"


const Chat = ({opponent}) => {
    const navigate = useNavigate()

    const lastMessageSent = useRef()
    const {authUser} = useContext(AuthContext)
    const {socket} = useContext(SocketContext)

    const [chats, setChats] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [messageLoading, setMessageLoading] = useState(false)

    useEffect(() => {
        socket?.on("new-chat", (newMessage) => {
            setChats([...chats, newMessage])
        })

        return () => socket?.off("new-chat")
    }, [socket, setChats, chats])

    useEffect(() => {
        setTimeout(() => {
            lastMessageSent.current?.scrollIntoView({ behavior: "smooth" })
        }, 100)
    }, [chats])

    const getMessages = async () => {
        setMessageLoading(true)
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.get(API_URL + `/chat/${opponent._id}`, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                setChats(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setMessageLoading(false)
        }
    }

    const sendMessage = async (message) => {
        setLoading(true);
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/chat/send/${opponent._id}`, {message}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 201) {
                setChats([...chats, response.data])
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!message) return
        await sendMessage(message)
        setMessage("")
    }

    useEffect(() => {
        if (Object.keys(opponent).length > 0) {
            getMessages()
        }
    }, [opponent])

    const ChatMessage = ({ chat }) => {
        const fromMe = chat.senderId === authUser._id;
        const formattedTime = extractTime(chat.createdAt);
        const chatClassName = fromMe ? "chat-end" : "chat-start";
        const profilePic = fromMe ? authUser.pfpFileName : opponent?.pfpFileName;
        const bubbleBgColor = fromMe ? "bg-accent text-black" : "";

        return (
            <div className={`chat ${chatClassName}`}>
                <div className='chat-image avatar'>
                    <div className='w-10 rounded-full'>
                        <img alt='Tailwind CSS chat bubble component' src={`https://robohash.org/${profilePic}?set=set2`} />
                    </div>
                </div>
                <div className={`chat-bubble ${bubbleBgColor} pb-2`}>{chat.message}</div>
                <div className='chat-footer opacity-50 text-xs flex gap-1 items-center'>{formattedTime}</div>
            </div>
        );
    };

    return (
        <div className="bg-base-100 rounded-2xl flex-grow overflow-hidden flex flex-col justify-between items-center px-5 pb-5 mb-2">
            <div className="flex-grow overflow-auto w-full mb-2">
                {!loading && chats.length > 0 &&
                chats.map((chat) => (
                    <div key={chat._id} ref={lastMessageSent}>
                        <ChatMessage chat={chat} />
                    </div>
                ))}
                {!loading && chats.length === 0 && (
                    <div className="h-full flex flex-col justify-center">
                        <p className="text-center">Envoyez un message pour commencer la conversation</p>
                    </div>
                )}
                {messageLoading && (
                    <div className="flex w-full flex-col gap-4">
                        <div className="skeleton bg-base-300 animation-correction h-6 w-1/3"></div>
                        <div className="skeleton bg-base-300 animation-correction h-6 w-full"></div>
                        <div className="skeleton bg-base-300 animation-correction h-6 w-full"></div>
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit} className="flex justify-between items-center gap-4 w-full">
                <input type="text" placeholder="Aa" value={message}
                       onChange={(e) => setMessage(e.target.value)} className="input input-bordered rounded-full w-full"/>
                {loading ? (
                    <button type="button" className="btn btn-primary btn-circle">
                        <span className="loading loading-spinner loading-md"></span>
                    </button>
                ) : (
                    <button type="submit" className="btn btn-primary btn-circle">
                        <IoSend size={24}/>
                    </button>
                )}
            </form>
        </div>
    )
}

export default Chat

const extractTime = (dateString) => {
    const date = new Date(dateString);
    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    return `${hours}:${minutes}`;
}

const padZero = (number) => {
    return number.toString().padStart(2, "0");
}