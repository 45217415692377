import Header from "./Header"
import React, {useContext, useEffect, useRef, useState} from "react"
import Footer from "./Footer"
import {Link, useNavigate, useOutletContext, useParams} from "react-router-dom"
import {IoMdArrowRoundBack, IoMdTrash} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FaEdit, FaReply} from "react-icons/fa"
import {AuthContext} from "../contexts/AuthContext"
import AvatarPicture from "./AvatarPicture"
import axios from "axios"
import {SocketContext} from "../contexts/SocketContext"

const MessageViewer = () => {
    const navigate = useNavigate()

    const lastMessageSent = useRef()

    const {authUser} = useContext(AuthContext)
    const {socket, onlineUsers} = useContext(SocketContext)
    const currentBox = useOutletContext()

    const {messageId} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [conversation, setConversation] = useState(null)

    const fetchConversation = async () => {
        setIsLoading(true)
        let url;
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            url = `${API_URL}/message/conversation/${messageId}`

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                setConversation(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/messenger")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/messenger")
                    break
                case 404:
                    navigate("/messenger")
                    break
                case 409:
                    navigate("/messenger")
                    break
                case 422:
                    navigate("/messenger")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            lastMessageSent.current?.scrollIntoView({ behavior: "smooth" });
        }, 100)
    }, [conversation])

    useEffect(() => {
        fetchConversation()
    }, [messageId])


    const Message = ({receivedMessage}) => {
        const date = new Date(receivedMessage.createdAt)
        const stringDate = date.toLocaleDateString("en-CA")
        const stringTime = date.toLocaleTimeString("en-CA")

        const handleRespond = () => {
            navigate("/messenger/reply", {state: {message: receivedMessage}})
        }

        return (
            <div ref={lastMessageSent} className="flex flex-col justify-start">
                <h2 className="text-4xl mb-8">{receivedMessage.object}</h2>
                <div className="flex justify-between items-center gap-4 pb-5 mb-5">
                    <div className="flex justify-start items-center gap-10 cursor-pointer" onClick={() => navigate(`/profile/${(currentBox === "INBOX" || currentBox === "TRASHED")
                        ? receivedMessage.sender._id
                        : receivedMessage.receiver._id}`)}>
                        <div
                            className={`ml-2 avatar ${(currentBox === "INBOX" || currentBox === "TRASHED")
                                ? (onlineUsers.includes(receivedMessage.sender._id) ? 'online' : 'offline')
                                : (onlineUsers.includes(receivedMessage.receiver._id) ? 'online' : 'offline')}`}>
                            <div className="ring-primary ring-offset-base-300 w-24 rounded-full ring ring-offset-2">
                                <img className="rounded-full"
                                     src={`${(currentBox === "INBOX" || currentBox === "TRASHED")
                                         ? `https://robohash.org/${receivedMessage.sender.pfpFileName}?set=set2`
                                         : `https://robohash.org/${receivedMessage.receiver.pfpFileName}?set=set2`}`}
                                     alt="Photo de profil"/>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-4xl">À: {receivedMessage.receiver.username}</h2>
                            <h3>De: {receivedMessage.sender.username}</h3>
                            <p>Envoyé le {stringDate} à {stringTime}</p>
                        </div>
                    </div>
                    <button className="btn btn-primary rounded-full text-xl" onClick={handleRespond}>
                        <FaReply size={28}/>
                        Répondre
                    </button>
                </div>
                <div>
                    <p className="text-xl whitespace-pre-wrap mb-5">{receivedMessage.message}</p>
                </div>
            </div>
        )
    }


    return (
        <div className="p-10">
            {isLoading ? (
                <div className="flex w-full flex-col gap-4">
                    <div className="flex items-center gap-4">
                        <div className="skeleton h-16 w-16 shrink-0 rounded-full"></div>
                        <div className="flex flex-col gap-4">
                            <div className="skeleton h-4 w-48"></div>
                            <div className="skeleton h-4 w-56"></div>
                        </div>
                    </div>
                    <div className="skeleton h-96 w-full"></div>
                </div>
            ) : (
                <div className="h-full overflow-auto">
                    {conversation && conversation.messages.map((message, index) => (
                        <>
                            <Message receivedMessage={message} key={index}/>
                            {index !== conversation.messages.length - 1 && (
                                <hr className="mb-3"/>
                            )}
                        </>
                    ))}
                </div>
            )}
        </div>
    )
}

export default MessageViewer