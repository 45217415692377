import Header from "./Header"
import React, {useContext, useEffect, useState} from "react"
import Footer from "./Footer"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FaEdit} from "react-icons/fa"
import {AuthContext} from "../contexts/AuthContext"
import AvatarPicture from "./AvatarPicture"
import axios from "axios"
import Select from "react-select"
import {IoSend} from "react-icons/io5"
import {FiAlertCircle} from "react-icons/fi"

const MessageWriter = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")

    const [friendList, setFriendList] = useState([])

    useEffect(() => {
        setFriendList([])
        const newFriendList = []
        authUser.friends.forEach((friend) => {
            const friendToAdd = {
                value: friend._id,
                label: friend.username
            }
            newFriendList.push(friendToAdd)
        })
        setFriendList(newFriendList)
    }, [])

    const handleChange = (e) => {
        if (typeof e.target === "undefined" ) {
            setFormData({...formData, receiver: e.value})
        } else {
            const {name, value} = e.target
            setFormData({...formData, [name]: value})
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const receivedErrors = validateFormData(formData)
        if (Object.keys(receivedErrors).length > 0) {
            setErrors(receivedErrors)
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const response = await axios.post( `${API_URL}/message`, formData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 201) {
                setShowToast(true)
                setToastMessage("Message envoyé")
                setTimeout(() => {
                    setShowToast(false)
                }, [5000])
                setFormData({
                    object: '',
                    message: ''
                })
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="p-10 h-full">
            <form onSubmit={handleSubmit} noValidate className="flex flex-col justify-start h-full">
                <div className="flex justify-between items-center mb-5">
                    <h2 className="text-4xl">Nouveau message</h2>
                        {isLoading ? (
                            <button className="btn btn-primary rounded-full text-xl w-44">
                                <span className="loading loading-spinner"></span>
                                Chargement
                            </button>
                        ) : (
                            <button type="submit"
                                    className="btn btn-primary rounded-full text-xl w-44">
                                <IoSend size={28}/>
                                Envoyer
                            </button>
                        )}
                </div>
                <div className="flex justify-start items-center">
                    <label className="text-xl mr-4">
                        À:
                    </label>
                    <label className="form-control w-full">
                        <Select options={friendList} placeholder="Sélectionnez le destinataire" styles={{
                            control: (provided, state) => ({
                                ...provided,
                                borderRadius: '7px',
                                borderColor: '#473e64',
                                backgroundColor: '#1a103d',
                                '&:hover': {
                                    borderColor: '#473e64'
                                },
                                height: '48px',
                                paddingLeft: '5px',
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                borderRadius: '7px',
                                backgroundColor: '#130b30'
                            }),
                            menuList: (provided, state) => ({
                                ...provided,
                                borderRadius: '7px'
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected ? '#130b30' : state.isFocused ? '#130b30' : '#130b30',
                                color: '#FFF',
                                '&:hover': {
                                    backgroundColor: '#E3F2FD',
                                    color: '#130b30'
                                }
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: '#9ca3af'
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: '#FFFFFF'
                            }),
                            dropdownIndicator: (provided) => ({
                                ...provided,
                                color: '#FFFFFF',
                                '&:hover': {
                                    color: '#FFFFFF'
                                }
                            }),
                            indicatorSeparator: () => ({
                                display: 'none'
                            })
                        }} className="rounded-full text-base-300" onChange={handleChange}/>
                    </label>
                </div>
                {errors.receiver && (
                    <div className="flex justify-start items-center gap-1">
                        <FiAlertCircle size={24} className="text-red-500"/>
                        <p className="text-red-500 text-lg">{errors.receiver}</p>
                    </div>
                )}

                <label className="form-control w-full mt-5">
                    <input name="object" type="text" maxLength="150" placeholder="Ajoutez un objet" value={formData.object}
                           onChange={handleChange}
                           className="input input-bordered w-full text-white"/>
                </label>
                {errors.object && (
                    <div className="flex justify-start items-center gap-1">
                        <FiAlertCircle size={24} className="text-red-500"/>
                        <p className="text-red-500 text-lg">{errors.object}</p>
                    </div>
                )}
                <hr className="mt-5"/>
                <label className="form-control w-full mt-5 flex-grow">
                    <textarea name="message" placeholder="Tapez ici" value={formData.message}
                              onChange={handleChange}
                              className="input input-bordered w-full resize-none h-full text-white p-4 whitespace-pre-wrap"/>
                </label>
                {errors.message && (
                    <div className="flex justify-start items-center gap-1">
                        <FiAlertCircle size={24} className="text-red-500"/>
                        <p className="text-red-500 text-lg">{errors.message}</p>
                    </div>
                )}
            </form>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>{toastMessage}</span>
                </div>
            </div>
        </div>
    )
}

export default MessageWriter

const validateFormData = (formData) => {
    const errors = {}

    if (!formData.receiver) {
        errors.receiver = "Ce champ est requis."
    }

    if (!formData.object) {
        errors.object = "Ce champ est requis."
    }

    if (!formData.message) {
        errors.message = "Ce champ est requis."
    }

    return errors
}