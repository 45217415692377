import {Link, Outlet, useNavigate, useParams} from "react-router-dom"
import {IoMdArrowRoundBack} from "react-icons/io"
import {IoAdd, IoMailOpen} from "react-icons/io5"
import MessageList from "../components/MessageList"
import React, {useContext, useEffect, useState} from "react"
import Chat from "../components/Chat"
import axios from "axios"
import {AuthContext} from "../contexts/AuthContext"
import OdysseyGame from "../components/OdysseyGame";
import AllureGame from "../components/AllureGame";


const Play = () => {
    const navigate = useNavigate()

    const {authUser} = useContext(AuthContext)

    const {gameId} = useParams()
    const [game, setGame] = useState({})
    const [opponent, setOpponent] = useState({})

    const fetchGame = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'
            const response = await axios.get(API_URL + `/game/${gameId}`, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                const receivedGame = response.data
                setGame(receivedGame)
                if (!receivedGame.players.find(player => player._id === authUser._id)) {
                    navigate('/403')
                }
                document.title = receivedGame.game === "ODYSSEY" ? "Odyssey | RetroRealm" : "À vive allure! | RetroRealm"
                const receivedOpponent = receivedGame.players.find(player => player._id !== authUser._id)
                setOpponent(receivedOpponent)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate("/422")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    useEffect(() => {
        fetchGame()
    }, [])

    return (
        <div className="flex justify-between items-center h-screen">
            <div className="w-1/4 bg-base-300 h-full px-5 py-2 flex flex-col gap-4">
                <div>
                    <h1 className="pb-1 mb-5 text-center"><Link className="pixel-font" to={'/home'}>RetroRealm</Link></h1>
                    <div className="mb-2 flex justify-around gap-4 items-center mb-4">
                        <div className="flex flex-col justify-center items-center">
                            <div className="ring-primary ring-offset-base-100 w-24 rounded-full ring ring-offset-2 mb-4">
                                <img className="rounded-full" src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}
                                     alt="Photo de profil"/>
                            </div>
                            <p className="text-2xl">{authUser.username}</p>
                        </div>
                        <p className="text-4xl">VS</p>
                        <div className="flex flex-col justify-center items-center">
                            <div
                                className="ring-primary ring-offset-base-100 w-24 rounded-full ring ring-offset-2 mb-4">
                                <img className="rounded-full"
                                     src={`https://robohash.org/${opponent.pfpFileName}?set=set2`}
                                     alt="Photo de profil"/>
                            </div>
                            <p className="text-2xl">{opponent.username}</p>
                        </div>
                    </div>
                    <Link to={`/profile/${opponent._id}`} target="_blank" className="btn btn-primary rounded-full w-full text-xl">Voir le profil de l'adversaire</Link>
                </div>
                <Chat opponent={opponent}/>
            </div>
            <div className={"w-3/4 bg-base-100 rounded-2xl h-screen"}>
                {window.location.pathname === `/play/odyssey/${gameId}` ? (
                    <OdysseyGame gameId={gameId} />
                ): (
                    <AllureGame gameId={gameId} />
                )}
            </div>
        </div>
    )
}

export default Play