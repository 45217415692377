import Header from "../components/Header"
import Footer from "../components/Footer"
import {useNavigate} from "react-router-dom"
import {useContext, useEffect} from "react"
import {AuthContext} from "../contexts/AuthContext"
import axios from "axios"
import {FaSearch} from "react-icons/fa"
import {MdEmail, MdForum} from "react-icons/md"
import {AiFillControl} from "react-icons/ai"
import {IoGameController} from "react-icons/io5"


const Home = () => {
    const navigate = useNavigate()
    const {authUser} = useContext(AuthContext)

    useEffect(() => {
        document.title = "Accueil | RetroRealm"
    }, [authUser, navigate])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <h2 className="mb-5">Bonjour, {authUser.username}!</h2>
                <div className="grid grid-cols-2 xl:grid-cols-4 grid-rows-2 grid-rows gap-4 mb-10">
                    <div onClick={() => navigate('/games')} className="col-span-2 row-span-2 bg-accent p-10 rounded-3xl aspect-square flex flex-col justify-center items-center hover:cursor-pointer hover:brightness-75 transition duration-300">
                        <IoGameController size={250} className="text-base-300 mb-5"/>
                        <h2 className="text-base-300">Jeux</h2>
                    </div>
                    <div onClick={() => navigate('/forum')} className={`${authUser.userType === "STANDARD" ? 'col-span-2' : 'col-span-1 aspect-square'} row-span-1 bg-accent p-10 rounded-3xl flex flex-col justify-center items-center hover:cursor-pointer hover:brightness-75 transition duration-300`}>
                        <MdForum size={150} className="text-base-300 mb-5"/>
                        <h2 className="text-base-300">Forum</h2>
                    </div>
                    <div onClick={() => navigate('/messenger')} className="row-span-1 bg-accent p-10 rounded-3xl aspect-square flex flex-col justify-center items-center hover:cursor-pointer hover:brightness-75 transition duration-300">
                        <MdEmail size={150} className="text-base-300 mb-5"/>
                        <h2 className="text-base-300">Messagerie</h2>
                    </div>
                    <div onClick={() => navigate('/search-users')} className="row-span-1 bg-accent p-10 rounded-3xl aspect-square flex flex-col justify-center items-center hover:cursor-pointer hover:brightness-75 transition duration-300">
                        <FaSearch size={150} className="text-base-300 mb-5"/>
                        <h2 className="text-base-300">Recherche</h2>
                    </div>
                    {(authUser.userType === "ADMIN" || authUser.userType === "MOD") && (
                        <div onClick={() => navigate('/control-panel')}
                            className="row-span-1 bg-accent p-10 rounded-3xl aspect-square flex flex-col justify-center items-center hover:cursor-pointer hover:brightness-75 transition duration-300">
                            <AiFillControl size={150} className="text-base-300 mb-5"/>
                            <h2 className="text-base-300 text-center text-xl">Panneau de contrôle</h2>
                        </div>
                    )}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home