import Header from "../components/Header"
import {Link, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack} from "react-icons/io"
import Footer from "../components/Footer"
import React, {useContext, useEffect, useState} from "react"
import {IoGameController} from "react-icons/io5"
import {AuthContext} from "../contexts/AuthContext"
import {SocketContext} from "../contexts/SocketContext"
import useTreatGameInvitations from "../hooks/useTreatGameInvitations"


const Matchmaking = () => {
    const navigate = useNavigate()

    const {authUser} = useContext(AuthContext)
    const {socket, onlineUsers} = useContext(SocketContext)

    const {createGameInvitation, deleteGameInvitation, createGame, title, setTitle} = useTreatGameInvitations()

    const [game, setGame] = useState("")
    const [onlineFriends, setOnlineFriends] = useState([])
    const [invitedFriend, setInvitedFriend] = useState({})
    const [invitationResult, setInvitationResult] = useState(null)
    const [isActionLoading, setIsActionLoading] = useState(false)

    useEffect(() => {
        switch (window.location.pathname) {
            case "/matchmaking/odyssey":
                document.title = "Odyssey | RetroRealm"
                setTitle("Odyssey")
                setGame("ODYSSEY")
                break
            case "/matchmaking/allure":
                document.title = "À vive allure! | RetroRealm"
                setTitle("À vive allure!")
                setGame("ALLURE")
                break
        }
    }, [])

    useEffect(() => {
        const newOnlineFriends = authUser.friends.filter(friend => onlineUsers.includes(friend._id));
        setOnlineFriends(newOnlineFriends);
    }, [authUser.friends, onlineUsers]);

    const inviteFriend = async (receiverId) => {
        setIsActionLoading(true)
        await createGameInvitation(authUser._id, receiverId)
        setIsActionLoading(false)
    }

    const cancelInvitation = async (receiverId) => {
        setIsActionLoading(true)
        await deleteGameInvitation(authUser._id, receiverId)
        setIsActionLoading(false)
        document.getElementById('invitation_modal').close()
    }

    useEffect(() => {
        socket?.on('game-invitation-accept', (data) => {
            setInvitationResult("ACCEPTED")
            createGame(authUser._id, data.receiverId, window.location.pathname === "/matchmaking/odyssey" ? "ODYSSEY" : "ALLURE")
        })

        return () => socket?.off('game-invitation-accept')
    }, [socket])

    useEffect(() => {
        socket?.on('game-invitation-decline', () => {
            setInvitationResult("DECLINED")
        })

        return () => socket?.off('game-invitation-decline')
    }, [socket])

    useEffect(() => {
        socket?.on('odysseyMatchFound', ({ opponent }) => {
            createGame(authUser._id, opponent, "ODYSSEY")
        })

        return () => socket?.off('odysseyMatchFound')
    }, [socket])

    useEffect(() => {
        socket?.on('allureMatchFound', ({ opponent }) => {
            createGame(authUser._id, opponent, "ALLURE")
        })

        return () => socket?.off('allureMatchFound')
    }, [socket])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/games" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack
                        size={56}
                        className="text-white"/></Link>
                    <h2 className="text-5xl">{title}</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
                    <div className="flex justify-between items-center">
                        <h2>Adversaire aléatoire</h2>
                        <button className="btn btn-primary w-36 rounded-full text-xl" onClick={() => {
                            document.getElementById('random-modal').showModal()
                            if (game === "ODYSSEY") {
                                socket.emit('joinOdysseyQueue')
                            } else {
                                socket.emit('joinAllureQueue')
                            }
                        }}>
                            <IoGameController size={32}/>
                            Jouer
                        </button>
                    </div>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
                    <h2 className="mb-5">Amis à inviter</h2>
                    <div className="bg-base-200 rounded-2xl p-5">
                        {onlineFriends.length > 0 ? (
                            <>
                                {onlineFriends.map((friend, i) => (
                                    <div
                                        className="bg-base-300 hover:bg-base-100 transition p-5 flex justify-between items-center mb-3 rounded-2xl  hover:cursor-pointer"
                                        onClick={() => {
                                            navigate(`/profile/${friend._id}`)
                                        }}
                                        key={i}>
                                        <div className="flex justify-start items-center gap-5">
                                            <div
                                                className={`avatar ${onlineUsers.includes(friend._id) ? 'online' : 'offline'}`}>
                                                <div
                                                    className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                                    <img className="rounded-full"
                                                         src={`https://robohash.org/${friend.pfpFileName}?set=set2`}
                                                         alt="Photo de profil"/>
                                                </div>
                                            </div>
                                            <h3>{friend.username}</h3>
                                        </div>
                                        {isActionLoading ? (
                                            <button className="btn btn-primary rounded-full text-lg w-28">
                                                <span className="loading loading-spinner"></span>
                                                Chargement
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary rounded-full text-lg w-28"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        setInvitedFriend(friend)
                                                        document.getElementById('invitation_modal').showModal()
                                                        inviteFriend(friend._id)
                                                    }}>
                                                Inviter
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p className="text-xl text-center">Aucun ami en ligne pour le moment</p>
                        )}
                    </div>
                </div>
            </div>
            <Footer/>

            <dialog id="random-modal" className="modal">
                <div className="modal-box flex flex-col justify-between items-center">
                    <h3 className="font-bold text-2xl text-center">Recherche d'un adversaire</h3>
                    <p className="py-4 text-center">En attente d'un joueur...</p>
                    <span className="loading loading-spinner loading-lg mb-4"></span>
                    {isActionLoading ? (
                        <button className="btn">
                            <span className="loading loading-spinner"></span>
                            Chargement
                        </button>
                    ) : (
                        <button className="btn"
                                onClick={() => {
                                    document.getElementById('random-modal').close()
                                    if (game === "ODYSSEY") {
                                        socket.emit('leaveOdysseyQueue')
                                    } else {
                                        socket.emit('leaveAllureQueue')
                                    }
                                }}>Annuler</button>
                    )}
                </div>
            </dialog>

            <dialog id="invitation_modal" className="modal">
                <div className="modal-box flex flex-col justify-between items-center">
                    <h3 className="font-bold text-2xl text-center">Invitation envoyée à {invitedFriend.username}</h3>
                    {invitationResult === null ? (
                        <>
                            <p className="py-4 text-center">En attente d'une réponse...</p>
                            <span className="loading loading-spinner loading-lg mb-4"></span>
                            {isActionLoading ? (
                                <button className="btn">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <button className="btn"
                                        onClick={() => cancelInvitation(invitedFriend._id)}>Annuler</button>
                            )}

                        </>
                    ) : invitationResult === "ACCEPTED" ? (
                        <>
                            <p className="py-4 text-center text-success">Invitation acceptée</p>
                            <p className="text-center text-lg mb-4">Chargement de la partie...</p>
                            <span className="loading loading-spinner loading-lg"></span>
                        </>
                    ) : invitationResult === "DECLINED" ? (
                        <>
                            <p className="py-4 text-center text-error">Invitation refusée</p>
                            <button className="btn" onClick={() => {
                                setInvitationResult(null)
                                document.getElementById("invitation_modal").close()
                            }}>
                                Fermer
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </dialog>
        </div>
    )
}

export default Matchmaking