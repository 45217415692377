import {useOutletContext} from "react-router-dom"
import {calculateAge, displayFavouriteGame, displayGender} from "../utils/UtilMethods"
import React from "react"


const ProfileStats = () => {
    const user = useOutletContext()

    return (
        <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
            <h3 className="text-3xl mb-2">Statistiques</h3>
            <p className="mb-5 text-xl">Victoires: {user.wonGames}</p>
            <p className="mb-5 text-xl">Défaites: {user.lostGames}</p>
            <p className="mb-5 text-xl">Nulles: {user.tiedGames}</p>
        </div>
    )
}

export default ProfileStats