
import {useEffect, useState} from "react";
import {getCardProperties} from "../utils/odysseyCardActions";


const OdysseyCard = ({value, callback, canBePlayed, isSelected, hidden, id, isPlayer1}) => {
    const [colorClass, setColorClass] = useState("")

    const onCardUse = async () => {
        if (!canBePlayed) return

        await callback(id)
    }

    let {name} = getCardProperties(value)

    useEffect(() => {
        let tempClass = ""
        if (!isSelected) {
            tempClass += "btn-outline "
        }
        tempClass += (isPlayer1 % 2 === 0 ? "btn-info" : "btn-error")

        setColorClass(tempClass)
    }, [isSelected]);

    return (
        <>
            <div onClick={onCardUse}
                 className={"card-animation w-14 h-24 mx-1 btn rounded " + colorClass}>
                {!hidden ? (<>{name}</>) : (<>?</>)}
            </div>
        </>
    )
}

export default OdysseyCard;