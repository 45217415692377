import React, {useState, useEffect, useContext} from 'react';
import Footer from "../components/Footer"
import PresentationHeader from "../components/PresentationHeader";
import {Link, useNavigate} from "react-router-dom";
import {FaLock} from "react-icons/fa";
import {AuthContext} from "../contexts/AuthContext";
import axios from "axios";

const Banned = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const [message, setMessage] = useState(<></>)

    useEffect(() => {
        if (!authUser || !authUser.isBanned && !authUser.suspendedUntil)
            return navigate("/404")

        if (authUser.isBanned)
            setMessage(
                <h1 className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Vous avez été banni</h1>
            )
        else
            setMessage(
                <>
                    <h1 className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Vous
                        avez été suspendu</h1>
                    <p className="mb-4 text-lg font-light text-gray-400">Votre compte sera utilisable
                        au {new Intl.DateTimeFormat('fr-ca', {
                            dateStyle: 'long',
                            timeStyle: 'short'
                        }).format(new Date(authUser.suspendedUntil))}.</p>
                </>
            )

        localStorage.removeItem('token')
        setAuthUser(null)
    }, [])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <PresentationHeader/>
            <div className="flex-grow flex justify-center items-center">
                <div className="rounded-3xl bg-base-300 w-1/3 p-10 flex flex-col justify-center items-center">
                    <FaLock className="mx-auto mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 text-primary-500"/>
                    {message}
                    <Link to="/" className="btn btn-primary rounded-full text-lg">Accueil</Link>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Banned;