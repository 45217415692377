import {IoSend} from "react-icons/io5"
import Select from "react-select"
import {FiAlertCircle} from "react-icons/fi"
import React, {useContext, useEffect, useRef, useState} from "react"
import {useLocation, useNavigate, useOutletContext} from "react-router-dom"
import axios from "axios"
import {AuthContext} from "../contexts/AuthContext"
import {FaReply} from "react-icons/fa"
import {SocketContext} from "../contexts/SocketContext"


const MessageResponder = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const lastMessageSent = useRef()
    const currentBox = useOutletContext()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const {socket, onlineUsers} = useContext(SocketContext)
    const [message, setMessage] = useState({
        sender: {},
        receiver: {}
    })
    const [conversation, setConversation] = useState(null)
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showSubmit, setShowSubmit] = useState(true)

    const fetchConversation = async (receivedMessageId) => {
        setIsLoading(true)
        let url;
        try {
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            url = `${API_URL}/message/conversation/${receivedMessageId}`

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            if (response.status === 200) {
                setConversation(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/messenger")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/messenger")
                    break
                case 404:
                    navigate("/messenger")
                    break
                case 409:
                    navigate("/messenger")
                    break
                case 422:
                    navigate("/messenger")
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        const receivedMessage = location.state?.message

        fetchConversation(receivedMessage._id)

        if (receivedMessage) {
            setMessage(receivedMessage)
            setFormData({...formData,
                object: `RE: ${receivedMessage.object}`,
                sender: receivedMessage.receiver._id,
                receiver: receivedMessage.sender._id
            })
        }

        setTimeout(() => {
            lastMessageSent.current?.scrollIntoView({ behavior: "smooth" });
        }, 100)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            lastMessageSent.current?.scrollIntoView({ behavior: "smooth" });
        }, 100)
    }, [conversation])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const receivedErrors = validateFormData(formData)
        if (Object.keys(receivedErrors).length > 0) {
            setErrors(receivedErrors)
            return
        }

        try {
            setIsLoading(true)
            setShowSubmit(false)
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const response = await axios.post(`${API_URL}/message/reply`, {message: message, formData: formData}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 201) {
                navigate(`/messenger/${message._id}`)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem('token')
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    const Message = ({receivedMessage}) => {
        const date = new Date(receivedMessage.createdAt)
        const stringDate = date.toLocaleDateString("en-CA")
        const stringTime = date.toLocaleTimeString("en-CA")

        return (
            <div ref={lastMessageSent} className="flex flex-col justify-start">
                <h2 className="text-4xl mb-8">{receivedMessage.object}</h2>
                <div className="flex justify-start items-center gap-10 mb-5">
                    <div
                        className={`ml-2 avatar ${(currentBox === "INBOX" || currentBox === "TRASHED")
                            ? (onlineUsers.includes(receivedMessage.sender._id) ? 'online' : 'offline')
                            : (onlineUsers.includes(receivedMessage.receiver._id) ? 'online' : 'offline')}`}>
                        <div className="ring-primary ring-offset-base-300 w-24 rounded-full ring ring-offset-2">
                            <img className="rounded-full"
                                 src={`https://robohash.org/${receivedMessage.receiver.pfpFileName}?set=set2`}
                                 alt="Photo de profil"/>
                        </div>
                    </div>
                    <div>
                        <h2 className="text-4xl">À: {receivedMessage.receiver.username}</h2>
                        <h3>De: {receivedMessage.sender.username}</h3>
                        <p>Envoyé le {stringDate} à {stringTime}</p>
                    </div>
                </div>
                <div>
                    <p className="text-xl whitespace-pre-wrap mb-5">{receivedMessage.message}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="p-10 h-full">
            <form onSubmit={handleSubmit} noValidate className="flex flex-col justify-start h-full">
                <div className="flex justify-between items-center mb-5">
                    <h2 className="text-4xl">Réponse</h2>
                    {showSubmit && (
                        <>
                            {isLoading ? (
                                <button className="btn btn-primary rounded-full text-xl w-44">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <button type="submit"
                                        className="btn btn-primary rounded-full text-xl w-44">
                                    <IoSend size={28}/>
                                    Envoyer
                                </button>
                            )}
                        </>
                    )}
                </div>
                <p className="text-xl mr-4">
                    À: {message.sender.username}
                </p>

                <label className="form-control w-full mt-5">
                    <input name="object" type="text" maxLength="150" placeholder="Ajoutez un objet"
                           value={formData.object}
                           onChange={handleChange}
                           className="input input-bordered w-full text-white"/>
                </label>
                {errors.object && (
                    <div className="flex justify-start items-center gap-1">
                        <FiAlertCircle size={24} className="text-red-500"/>
                        <p className="text-red-500 text-lg">{errors.object}</p>
                    </div>
                )}
                <hr className="mt-5"/>
                <div className="mt-5 flex-grow overflow-auto flex flex-col justify-start">

                    {conversation && conversation.messages.map((message, index) => (
                        <>
                            <Message receivedMessage={message} key={index}/>
                            <hr className="mb-3"/>
                        </>
                    ))}

                    <div className="w-full flex-grow">
                        <label className="form-control w-full h-full min-h-64">
                        <textarea name="message" placeholder="Tapez ici" value={formData.message}
                                  onChange={handleChange}
                                  className="input input-bordered w-full resize-none h-full text-white p-4 whitespace-pre-wrap"/>
                        </label>
                    </div>
                    {errors.message && (
                        <div className="flex justify-start items-center gap-1">
                            <FiAlertCircle size={24} className="text-red-500"/>
                            <p className="text-red-500 text-lg">{errors.message}</p>
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

export default MessageResponder

const validateFormData = (formData) => {
    const errors = {}

    if (!formData.object) {
        errors.object = "Ce champ est requis."
    }

    if (!formData.message) {
        errors.message = "Ce champ est requis."
    }

    return errors
}