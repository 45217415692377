import {useEffect, createContext, useState} from "react"

export const AuthContext = createContext(null)

export const AuthContextProvider = ({children}) => {
    const [authUser, setAuthUser] = useState(null)

    return <AuthContext.Provider value={{authUser, setAuthUser}}>{children}</AuthContext.Provider>
}

