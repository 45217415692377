import React, {useContext, useEffect, useState} from "react"
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {formatTimeAgo} from '../utils/formatTimeAgo';
import {AuthContext} from "../contexts/AuthContext";

const cardsByPage = 5

const Reports = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const [reports, setReports] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [suspendFormData, setSuspendFormData] = useState({
        time: 3
    })

    useEffect(() => {
        getReports()
    })

    const getReports = async  () => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/reports`
        try {
            const response = await axios.get(url, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            setReports(response.data.filter(x => x._id !== authUser._id).sort((a, b) => a.createdAt - b.createdAt))
        }
        catch (error) {
            console.log(error)
        }
    }

    const suspend = user => {
        document.getElementById('modal_suspend_reports').showModal()
        setSuspendFormData({
            user: user,
            time: 3
        })
    }

    const changeSupendFormData = e => {
        const {value} = e.target
        setSuspendFormData({
            ...suspendFormData,
            time: parseInt(value)
        })
    }

    const close_dialog = () => {
        document.getElementById('modal_suspend_reports').close()
    }
    
    const confirm_dialog = async e => {
        e.preventDefault()

        const now = new Date()
        const hours = suspendFormData.time * 60 * 60 * 1000
        const date = now.setTime(now.getTime() + hours)
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${suspendFormData.user._id}/suspend`

        try {
            await axios.patch(url, {suspendedUntil: date}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getReports()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }

        close_dialog()
    }

    const ban = async user => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/user/${user._id}/ban`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const deleteReport = async report => {
        const url = `${process.env.REACT_APP_API_URL || "http://localhost:3001"}/reports/${report._id}/delete`
        console.log(url)
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            getReports()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const showProofFromReport = (report) => {
        if (!report.proof)
            return <></>

        if(report.proof.type === 'FORUM-THREAD' || report.proof.type === 'FORUM-REPLY')
            return <a className="btn" href={`/forum/threads/${report.proof.postId}`} target="_blank">Voir la preuve</a>

        return (<></>)
    }

    return (
        <>
            <ul>
                {reports.slice(currentPage * cardsByPage, (currentPage + 1) * cardsByPage).map(report => (
                    <li key={report._id}>
                        <div className="shadow-md rounded-lg mb-10 bg-neutral">
                            <div className="p-4">
                                <div className="flex justify-between mb-2">
                                    <a href={"/profile/" + report.user._id}>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="mask mask-squircle h-12 w-12">
                                                    <img
                                                        src={`http://robohash.org/${report.user.pfpFileName}?set=set2`}
                                                        alt={report.user.username}/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{report.user.username}</div>
                                            </div>
                                        </div>
                                    </a>
                                    <button className="btn btn-sm btn-square btn-outline"
                                            onClick={() => deleteReport(report)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                    </button>
                                </div>
                                <p className="text-white">{report.reason}</p>
                                {showProofFromReport(report)}
                            </div>
                            <div className="p-4 border-t border-gray-200 flex justify-between">
                                <div>
                                    <p className="text-gray-400">
                                        envoyé par: {report.sender.username}
                                    </p>
                                    <p className="text-gray-400">
                                        Il y a {formatTimeAgo(report.createdAt)}
                                    </p>
                                </div>
                                <div className="flex gap-3">
                                    {report.user.userType !== "ADMIN" &&
                                        <>
                                            <button onClick={() => suspend(report.user)}
                                                    className="btn btn-warning">Suspendre
                                            </button>
                                            <button onClick={() => ban(report.user)} className="btn btn-error">Bannir
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>

            <div className="join w-full justify-center">
                {[...Array(Math.ceil(reports.length / cardsByPage)).keys()].map((i) => (
                    <button key={i} className={"join-item btn " + (currentPage === i ? "btn-active" : "")}
                            onClick={() => setCurrentPage(i)}>
                        {i + 1}
                    </button>
                ))}
            </div>

            <dialog id="modal_suspend_reports" className="modal modal-bottom sm:modal-middle">
                <form className="modal-box" method="post" onSubmit={confirm_dialog}>
                    <h3 className="font-bold text-lg">{"Suspendre " + suspendFormData.user?.username}</h3>

                    <label htmlFor="countries" className="block mb-2 text-sm font-medium text-white">Durée de la
                        suspension</label>
                    <select name="time" value={suspendFormData.time} onChange={changeSupendFormData}
                            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                        <option value={3}>3 heures</option>
                        <option value={12}>12 heures</option>
                        <option value={24}>24 heures</option>
                        <option value={72}>3 jours</option>
                    </select>

                    <div className="modal-action">
                        <div className="w-full grid sm:grid-cols-2 gap-3">
                            <button type="submit" className="btn btn-sm btn-success"
                                    onClick={confirm_dialog}>Confirmer
                            </button>
                            <button type="reset" className="btn btn-sm btn-error" onClick={close_dialog}>Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </dialog>
        </>
    )
}

export default Reports