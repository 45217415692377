import React from "react";
import Logo from "../images/allure/logo.png"
import AsDuVolant from "../images/allure/card-as.png"
import Citerne from "../images/allure/card-camion-citerne.png"
import Increvable from "../images/allure/card-increvable.png"
import VehiculePrioritaire from "../images/allure/card-vehicule-prioritaire.png"
import Bornes25 from "../images/allure/card-25.png"
import Bornes50 from "../images/allure/card-50.png"
import Bornes75 from "../images/allure/card-75.png"
import Bornes100 from "../images/allure/card-100.png"
import Bornes200 from "../images/allure/card-200.png"
import Essence from "../images/allure/card-essence.png"
import PanneEssence from "../images/allure/card-panne-essence.png"
import Accident from "../images/allure/card-accident.png"
import Reparation from "../images/allure/card-reparation.png"
import Crevaison from "../images/allure/card-crevaison.png"
import RoueSecours from "../images/allure/card-roue-secours.png"
import Roulez from "../images/allure/card-roulez.png"
import Arret from "../images/allure/card-stop.png"
import Limite from "../images/allure/card-limite.png"
import FinLimite from "../images/allure/card-fin-limite.png"

const AllureRules = () => {
    return (
        <dialog id="helpModal" className="modal">
            <div className="modal-box max-w-7xl">
                <form method="dialog">
                    <button
                        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕
                    </button>
                </form>
                <h3 className="font-bold mb-3 text-center">Règles du jeu</h3>
                <hr className="mb-6 opacity-50"/>
                <div className="flex justify-between gap-3">
                    <div className="rounded-2xl w-1/2">
                        <img src={Logo} alt="Logo À Vive Allure!"
                             className="w-2/3 justify-self-center"/>
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">CONTENU
                            DU JEU</h4>
                        <p className="text-center font-bold my-2">106 cartes,
                            réparties comme suit :</p>
                        <div
                            className="grid grid-cols-6 gap-3 border border-primary rounded-2xl p-2 mb-3">
                            <p className="mt-2 h-16 text-center">Les <strong>Bottes</strong> protègent
                                des</p>
                            <div className="col-span-1"></div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 1</p>
                                <img className="w-2/3"
                                     src={VehiculePrioritaire}
                                     alt="Carte Vaisseau Prioritaire"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 1</p>
                                <img className="w-2/3" src={Citerne}
                                     alt="Carte Vaisseau Citerne"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 1</p>
                                <img className="w-2/3" src={Increvable}
                                     alt="Carte Module Incassable"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 1</p>
                                <img className="w-2/3" src={AsDuVolant}
                                     alt="Carte As des Commandes"/>
                            </div>
                            <p className="mt-1 w-24 h-[4.75rem] text-center">
                                <strong>Attaques</strong> qui sont réparées par
                                les</p>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 5</p>
                                <img className="w-2/3" src={Arret}
                                     alt="Carte Arrêt"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 4</p>
                                <img className="w-2/3" src={Limite}
                                     alt="Carte Limite de Vitesse"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 3</p>
                                <img className="w-2/3" src={PanneEssence}
                                     alt="Carte Panne de Carburant"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 3</p>
                                <img className="w-2/3" src={Crevaison}
                                     alt="Carte Module Endommagé"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 3</p>
                                <img className="w-2/3" src={Accident}
                                     alt="Carte Accident"/>
                            </div>
                            <p className="mt-7 h-[3.25rem] text-center">
                                <strong>Parades</strong>
                            </p>
                            <div className="flex items-center justify-center">
                                <p className="text-sm font-bold me-2">x 14</p>
                                <img className="w-2/3" src={Roulez}
                                     alt="Carte Démarrage"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 6</p>
                                <img className="w-2/3" src={FinLimite}
                                     alt="Carte Fin Limite de Vitesse"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 6</p>
                                <img className="w-2/3" src={Essence}
                                     alt="Carte Ravitaillement"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 6</p>
                                <img className="w-2/3" src={RoueSecours}
                                     alt="Carte Module de Secours"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 6</p>
                                <img className="w-2/3" src={Reparation}
                                     alt="Carte Réparations"/>
                            </div>
                            <p className="mt-4 h-16 text-center">
                                Cartes<br/>
                                <strong>Parsecs</strong>
                            </p>
                            <div className="flex items-center justify-center">
                                <p className="text-sm font-bold me-2">x 10</p>
                                <img className="w-2/3" src={Bornes25}
                                     alt="Carte 25"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-sm font-bold me-2">x 10</p>
                                <img className="w-2/3" src={Bornes50}
                                     alt="Carte 50"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-sm font-bold me-2">x 10</p>
                                <img className="w-2/3" src={Bornes75}
                                     alt="Carte 75"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-sm font-bold me-2">x 12</p>
                                <img className="w-2/3" src={Bornes100}
                                     alt="Carte 100"/>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-md font-bold me-2">x 4</p>
                                <img className="w-2/3" src={Bornes200}
                                     alt="Carte 200"/>
                            </div>
                        </div>
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">BUT
                            DU JEU</h4>
                        <p className="text-center mt-2 mb-4">Être le
                            premier joueur à atteindre 1 000 Parsecs !</p>
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">DISPOSITION
                            DE VOTRE ZONE DE JEU</h4>
                        <ul className="list-disc ms-5 mt-2">
                            <li className="mb-2"><strong>Pile de
                                Vitesse</strong> : C’est dans
                                cet emplacement que votre
                                adversaire pose ses cartes <em>Limite de
                                    Vitesse</em>.
                                Vos cartes <em>Fin de Limite de
                                    Vitesse</em> seront placées à cet endroit,
                                par-dessus la
                                carte <em>Limite de Vitesse</em>.
                            </li>
                            <li className="mb-2"><strong>Pile de Bataille</strong> :
                                La première
                                carte posée est obligatoirement
                                un <em>Démarrage</em> à moins que vous ayez posé la
                                Botte <em>Vaisseau
                                    Prioritaire</em>.
                                Votre adversaire pose les cartes Attaques et
                                vous les cartes Parades.
                            </li>
                            <li><strong>Bottes</strong> : Les cartes Bottes ne se
                                disposent pas
                                en pile, mais côte-à-côte, de manière
                                à ce que l'adversaire puisse voir
                                de quelles attaques vous êtes protégés.
                            </li>
                        </ul>
                    </div>
                    <div className="w-1/2">
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">COMMENT
                            JOUER</h4>
                        <div className="px-3">
                            <p className="font-bold my-2">Lorsque c’est votre tour,
                                vous piochez une carte.<br/>
                                Vous avez maintenant 7 cartes en main et 6
                                possibilités :</p>
                            <ul className="list-decimal ms-5">
                                <li className="mb-2">
                                    Si vous avez un <strong>Démarrage</strong>, vous
                                    pouvez
                                    démarrer votre course.
                                    Pour cela, posez votre
                                    carte <strong>Démarrage</strong> devant
                                    vous.<br/>
                                    <span
                                        className="text-secondary italic"><strong>Précision</strong> : la carte Démarrage est très
                                                                        importante car c’est la première
                                                                        carte que vous devez jouer avant de pouvoir
                                                                        démarrer la course.</span>
                                </li>
                                <li className="mb-2">
                                    Si vous avez une <strong>carte
                                    Parsec</strong> ET que vous avez déjà devant
                                    vous une
                                    carte <em>Démarrage</em> ou la botte <em>Vaisseau
                                    Prioritaire</em>, vous pouvez poser votre
                                    carte <strong>Parsec</strong>, et ainsi avancer
                                    dans la course !
                                </li>
                                <li className="mb-2">
                                    Si vous avez une carte <strong>Attaque</strong>,
                                    vous pouvez la poser sur le jeu de
                                    votre adversaire pour le retarder dans sa
                                    course, à condition que celui-ci
                                    ait devant lui une carte <em>Démarrage</em> sur
                                    le dessus de sa pile de Bataille (ou la
                                    botte <em>Vaisseau Prioritaire</em>). La <em>Limite
                                    de Vitesse</em> est la seule carte Attaque
                                    que vous pouvez placer devant votre adversaire
                                    qui n’a pas encore démarré.<br/>
                                    <span
                                        className="text-secondary italic"><strong>Précision</strong> : vous pouvez attaquer un adversaire même si vous êtes vous-même déjà attaqué ou que vous n’avez pas encore démarré.</span>
                                </li>
                                <li className="mb-2">
                                    Si vous avez subi une attaque lors du tour
                                    précédent, vous pouvez stopper cette attaque en
                                    posant par-dessus la
                                    carte <strong>Parade</strong> correspondante.<br/>
                                    <span
                                        className="text-secondary italic"><strong>Précision</strong> : vous n’avez pas besoin de poser un Démarrage pour pouvoir
                                                                        redémarrer. La carte Parade suffit pour poursuivre votre course.</span>
                                </li>
                                <li className="mb-2">
                                    Si vous avez une <strong>Botte</strong>, vous
                                    pouvez la poser devant vous. Elle sert
                                    d’immunité contre une attaque bien précise.<br/>
                                    <span className="text-secondary italic">Précision : le fait de poser une Botte vous donne le droit de rejouer immédiatement.
                                                                            {/* Vous piochez alors une autre carte et rejouez.*/}</span>
                                </li>
                                <li className="mb-2">
                                    Si vous ne pouvez <strong>jouer aucune
                                    carte</strong> sur votre Zone de Jeu ou sur
                                    celle de votre adversaire, jetez une de vos
                                    cartes dans la défausse.
                                </li>
                            </ul>
                            <p className="mb-1">
                                Il vous reste de nouveau 6 cartes en main. Votre
                                tour de jeu est terminé
                                et c’est maintenant à votre adversaire de jouer.
                            </p>
                            <p className="mb-1">
                                Le jeu continue ainsi jusqu’à ce que l’un d’entre
                                vous totalise EXACTEMENT
                                1 000 Parsecs avec les cartes Parsecs posées devant
                                lui, ou bien dès qu’il n’y
                                a plus de cartes dans la pioche.
                            </p>
                            <p className="text-secondary italic">
                                <strong>Précision</strong> : si personne n’atteint 1
                                000 Parsecs, le joueur qui gagne est celui
                                qui en est le plus proche, sans les dépasser.
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="my-6 opacity-50"/>
                <div className="flex justify-between gap-3">
                    <div className="rounded-2xl w-1/2">
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">LES
                            CARTES PARSECS</h4>
                        <div className="flex justify-between px-3 mb-4">
                            <div className="w-4/5">
                                <ul className="my-2 list-disc ms-5">
                                    <li>
                                        Ces cartes vous permettent d’avancer. Le
                                        nombre
                                        indiqué dessus représente des Parsecs
                                        (environ 3
                                        années-lumière). Une fois la carte Parsec
                                        jouée,
                                        votre compteur de Parsecs augmentera.
                                    </li>
                                </ul>
                                <p className="text-secondary italic">
                                    <strong>Attention</strong> : pour pouvoir poser
                                    une
                                    carte Parsec, il faut
                                    que vous ayez au début de la partie une carte
                                    Démarrage
                                    ou la Botte Vaisseau Prioritaire.
                                </p>
                            </div>
                            <img className="w-1/5 mt-2" src={AsDuVolant}
                                 alt="Carte Parsec"/>
                        </div>
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1 mt-4">LES
                            CARTES ATTAQUES ET PARADES</h4>
                        <div className="px-3">
                            <ul className="my-2 list-disc ms-5">
                                <li className="mb-2">
                                    Si vous
                                    souhaitez <strong>ralentir</strong> votre
                                    adversaire, vous pouvez jouer une carte <em>Limite
                                    de
                                    Vitesse</em> sur sa pile de Vitesse. La
                                    carte <em>Limite de Vitesse</em> peut se cumuler
                                    avec
                                    chacune des 4 autres attaques.
                                </li>
                                <li className="mb-2">
                                    Pour <strong>stopper</strong> un adversaire qui
                                    circule, vous pouvez l’attaquer. Pour cela,
                                    posez
                                    votre carte Attaque (autre que la carte <em>Limite
                                    de Vitesse</em>) sur sa pile de Bataille !
                                </li>
                                <li className="mb-2">
                                    Si votre adversaire joue une carte Attaque sur
                                    votre pile de Bataille, vous devez
                                    jouer la carte Parade correspondante, avant de
                                    pouvoir à nouveau jouer une
                                    carte Parsec et avancer.
                                </li>
                            </ul>
                            <p className="text-secondary italic mb-6">
                                <strong>Précision</strong> : un joueur ne peut pas
                                être attaqué simultanément avec 2 attaques sur
                                sa pile de Bataille. En revanche, il peut être à la
                                fois victime d’une Attaque sur sa
                                pile de Bataille et d’une Limite de Vitesse sur sa
                                pile de Vitesse !
                            </p>
                            <div className="flex justify-end mb-2">
                                <p className="text-2xl font-bold text-error me-8">ATTAQUES</p>
                                <p className="text-2xl font-bold text-success me-11">PARADES</p>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-1/2 p-3">
                                    <h4 className="text-xl text-primary mb-2">LIMiTE
                                        DE VITESSE</h4>
                                    <p>Cette carte se place sur la pile
                                        de Vitesse de votre adversaire. Elle
                                        l’empêche de poser des cartes
                                        Parsecs supérieures à 50,
                                        jusqu’à ce qu’il recouvre cette
                                        attaque d’une carte <em>Fin de Limite de
                                            Vitesse</em>.</p>
                                </div>
                                <div className="w-1/2 p-3">
                                    <div className="flex justify-evenly mb-2">
                                        <img className="w-1/3 h-fit"
                                             src={Limite}
                                             alt="Carte Limite de Vitesse"/>
                                        <img className="w-1/3 h-fit"
                                             src={FinLimite}
                                             alt="Carte Fin Limite de Vitesse"/>
                                    </div>
                                    <div className="flex justify-evenly">
                                        <p className="text-md text-error ms-2">LIMITE
                                            DE
                                            VITESSE</p>
                                        <p className="text-md text-success">FIN
                                            LIMITE DE VITESSE</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-1/2 p-3">
                                    <h4 className="text-xl text-primary mb-2">ARRÊT</h4>
                                    <p>Cette carte se place sur la pile
                                        de Bataille de votre adversaire qui
                                        circule. Elle l’empêche de poser
                                        de nouvelles cartes Parsecs,
                                        jusqu’à ce qu’il recouvre cette
                                        attaque d’une carte <em>Démarrage</em>.</p>
                                </div>
                                <div className="w-1/2 p-3">
                                    <div className="flex justify-evenly mb-2">
                                        <img className="w-1/3 h-fit"
                                             src={Arret}
                                             alt="Carte Arrêt"/>
                                        <img className="w-1/3 h-fit"
                                             src={Roulez}
                                             alt="Carte Démarrage"/>
                                    </div>
                                    <div className="flex justify-evenly">
                                        <p className="text-md text-error ms-3">ARRÊT</p>
                                        <p className="text-md text-success ms-7">DÉMARRAGE</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-1/2 p-3">
                                    <h4 className="text-xl text-primary mb-2">PANNE
                                        DE CARBURANT</h4>
                                    <p>Cette carte se place sur la pile
                                        de Bataille de votre adversaire qui
                                        circule. Elle l’empêche de poser
                                        de nouvelles cartes Parsecs,
                                        jusqu’à ce qu’il recouvre cette
                                        attaque d’une carte <em>Ravitaillement</em>.</p>
                                </div>
                                <div className="w-1/2 p-3">
                                    <div className="flex justify-evenly mb-2">
                                        <img className="w-1/3 h-fit"
                                             src={PanneEssence}
                                             alt="Carte Panne de Carburant"/>
                                        <img className="w-1/3 h-fit"
                                             src={Essence}
                                             alt="Carte Ravitaillement"/>
                                    </div>
                                    <div className="flex justify-evenly ms-2">
                                        <p className="text-md text-error text-center">PANNE
                                            DE <br/>CARBURANT</p>
                                        <p className="text-md text-success ms-2">RAVITAILLEMENT</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-1/2 p-3">
                                    <h4 className="text-xl text-primary mb-2">MODULE
                                        ENDOMMAGÉ</h4>
                                    <p>Cette carte se place sur la pile
                                        de Bataille de votre adversaire qui
                                        circule. Elle l’empêche de poser de
                                        nouvelles cartes Parsecs, jusqu’à
                                        ce qu’il recouvre cette attaque
                                        d’une carte <em>Module de Secours</em>.</p>
                                </div>
                                <div className="w-1/2 p-3">
                                    <div className="flex justify-evenly mb-2">
                                        <img className="w-1/3 h-fit"
                                             src={Crevaison}
                                             alt="Carte Module Endommagé"/>
                                        <img className="w-1/3 h-fit"
                                             src={RoueSecours}
                                             alt="Carte Module de Secours"/>
                                    </div>
                                    <div className="flex justify-evenly">
                                        <p className="text-md text-error">MODULE
                                            ENDOMMAGÉ</p>
                                        <p className="text-md text-success">MODULE
                                            DE SECOURS</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-1/2 p-3">
                                    <h4 className="text-xl text-primary mb-2">ACCIDENT</h4>
                                    <p>Cette carte se place sur la pile
                                        de Bataille de votre adversaire qui
                                        circule. Elle l’empêche de poser de
                                        nouvelles cartes Parsecs, jusqu’à
                                        ce qu’il recouvre cette attaque
                                        d’une carte <em>Réparations</em>.</p>
                                </div>
                                <div className="w-1/2 p-3">
                                    <div className="flex justify-evenly mb-2">
                                        <img className="w-1/3 h-fit"
                                             src={Accident}
                                             alt="Carte Accident"/>
                                        <img className="w-1/3 h-fit"
                                             src={Reparation}
                                             alt="Carte Réparations"/>
                                    </div>
                                    <div className="flex justify-evenly">
                                        <p className="text-md text-error ms-3 me-4">ACCIDENT</p>
                                        <p className="text-md text-success">RÉPARATIONS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-2xl w-1/2">
                        <h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">LES
                            BOTTES</h4>
                        <div className="px-3 mb-4">
                            <p className="font-bold mt-2">Les cartes Bottes sont une
                                immunité contre les attaques qui leur sont
                                associées, pendant toute la durée du jeu.</p>
                            <ul className="my-2 list-disc ms-5 mb-4">
                                <li className="mb-2">
                                    Jouez une carte Botte pour empêcher votre
                                    adversaire de vous attaquer avec
                                    une attaque spécifique durant toute la durée du
                                    jeu.
                                </li>
                                <li className="mb-2">
                                    S’il y a une carte Attaque sur votre pile de
                                    Bataille et que vous jouez la carte
                                    Botte correspondante, la Botte annule
                                    immédiatement l’effet de l’attaque.
                                </li>
                                <li className="mb-2">
                                    Lorsque vous jouez une carte Botte, vous pouvez
                                    rejouer immédiatement.
                                </li>
                            </ul>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-4/5 p-3">
                                    <h4 className="text-xl text-primary mb-2">MODULE
                                        INCASSABLE</h4>
                                    <p>Une fois que vous avez posé cette Botte,
                                        votre
                                        adversaires ne peut plus vous attaquer avec
                                        une carte <em>Module Endommagé</em>.</p>
                                </div>
                                <img className="w-1/5 m-3 h-fit"
                                     src={Increvable}
                                     alt="Carte Module Incassable"/>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-4/5 p-3">
                                    <h4 className="text-xl text-primary mb-2">VAISSEAU
                                        CITERNE</h4>
                                    <p>Une fois que vous avez posé cette Botte,
                                        votre
                                        adversaires ne peut plus vous attaquer avec
                                        une carte <em>Panne de Carburant</em>.</p>
                                </div>
                                <img className="w-1/5 m-3 h-fit"
                                     src={Citerne}
                                     alt="Carte Vaisseau Citerne"/>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-4/5 p-3">
                                    <h4 className="text-xl text-primary mb-2">AS DES
                                        COMMANDES</h4>
                                    <p>Une fois que vous avez posé cette Botte,
                                        votre
                                        adversaires ne peut plus vous attaquer avec
                                        une carte <em>Accident</em>.</p>
                                </div>
                                <img className="w-1/5 m-3 h-fit"
                                     src={AsDuVolant}
                                     alt="Carte As des Commandes"/>
                            </div>
                            <div
                                className="flex justify-between border border-primary rounded-2xl mb-4">
                                <div className="w-4/5 p-3">
                                    <h4 className="text-xl text-primary mb-2">VAISSEAU
                                        PRIORITAIRE</h4>
                                    <p className="mb-2">La carte <em>Vaisseau
                                        Prioritaire</em> est la
                                        carte la plus
                                        puissante du jeu ! Une fois que vous avez
                                        posé
                                        cette Botte, votre adversaire ne peut plus
                                        vous attaquer avec une
                                        carte <em>Arrêt</em> ni avec
                                        une carte <em>Limite de Vitesse</em>.</p>
                                    <p className="text-secondary italic">
                                        <strong>Précision</strong> : si vous exposez
                                        cette carte au début
                                        de la partie, vous êtes dispensé de poser
                                        un <em>Démarrage</em> pour démarrer.</p>
                                </div>
                                <img className="w-1/5 m-3 h-fit"
                                     src={VehiculePrioritaire}
                                     alt="Carte Vaisseau Prioritaire"/>
                            </div>
                        </div>
                        {/*<h4 className="text-xl font-bold rounded-t-2xl text-center bg-primary text-base-100 py-1">LE*/}
                        {/*    COUP-FOURRÉ</h4>*/}
                        {/*<div className="px-3">*/}
                        {/*    <p className="mt-2">Il consiste à exposer une*/}
                        {/*        Botte <strong>aussitôt</strong> que votre*/}
                        {/*        adversaire*/}
                        {/*        place dans votre jeu*/}
                        {/*        l’attaque correspondante, et ce, même si ce*/}
                        {/*        n’est*/}
                        {/*        pas à votre tour de jouer.</p>*/}
                        {/*    <p className="text-secondary italic my-2">*/}
                        {/*        <strong>Exemple</strong> : un adversaire place*/}
                        {/*        un*/}
                        {/*        Module Endommagé sur votre pile de Bataille, et*/}
                        {/*        vous*/}
                        {/*        avez*/}
                        {/*        justement parmi vos 6 cartes la botte Module*/}
                        {/*        Incassable ; vous l’abattez immédiatement en*/}
                        {/*        appuyant sur «Coup-Fourré !»</p>*/}
                        {/*    <p className="font-bold">Le Coup-Fourré vous donne 3*/}
                        {/*        avantages :</p>*/}
                        {/*    <ul className="my-2 list-decimal ms-5 mb-2">*/}
                        {/*        <li className="mb-2">*/}
                        {/*            Rejeter dans la défausse l’attaque que vous*/}
                        {/*            venez ainsi d’annuler.*/}
                        {/*        </li>*/}
                        {/*        <li className="mb-2">*/}
                        {/*            Piocher une carte pour compléter votre main*/}
                        {/*            -*/}
                        {/*            car vous venez*/}
                        {/*            d’exposer subitement une carte, sans avoir*/}
                        {/*            préalablement pioché.*/}
                        {/*        </li>*/}
                        {/*        <li className="mb-2">*/}
                        {/*            Rejouer immédiatement, c’est-à-dire prendre*/}
                        {/*            encore une carte dans la pioche et*/}
                        {/*            jouer comme si c’était votre tour. Après*/}
                        {/*            cela, à*/}
                        {/*            votre adversaire de jouer.*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*    <p className="text-secondary italic">*/}
                        {/*        <strong>Remarque</strong> : la Botte exposée*/}
                        {/*        vous*/}
                        {/*        immunise contre les attaques correspondantes*/}
                        {/*        pour le reste de la partie.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <form method="dialog" className="modal-backdrop">
                <button className="cursor-default">close</button>
            </form>
        </dialog>
    )
}

export default AllureRules