import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {formatTimeAgo} from '../utils/formatTimeAgo';
import {BiSolidLike, BiSolidDislike, BiSolidMessageDetail, BiDotsVerticalRounded, BiSolidSend, BiX} from 'react-icons/bi';
import { IoIosWarning } from "react-icons/io";
import { ImReply } from "react-icons/im";
import axios from "axios";

const ForumReply = ({id, forumThread, message, createdAt, modifiedAt, user, currentUser, isLiked, isDisliked, likes, dislikes, mentionedUsers, replyTo, deleteReply}) => {
    const [formData, setFormData] = useState({
        message: message,
        user: currentUser,
        forumThread: forumThread
    });
    const [errorReply, setErrorReply] = useState(false);
    const [deleteButtonText, setDeleteButtonText] = useState("Supprimer");
    const [likesCount, setLikesCount] = useState(likes);
    const [dislikesCount, setDislikesCount] = useState(dislikes);
    const [liked, setLiked] = useState(isLiked);
    const [disliked, setDisliked] = useState(isDisliked);
    const [isFormActivated, setIsFormActivated] = useState(false);
    const [newMessage, setNewMessage] = useState(message);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

    const replaceMentionsWithLinks = (message) => {
        const mentionRegex = /@(\w+)/g;
        const splitMessage = message.split(mentionRegex);

        return splitMessage.map((part, index) => {
            if (index % 2 !== 0) {
                const user = mentionedUsers[part];

                if (user) {
                    return (
                        <Link key={index} to={`/profile/${user._id}`} className="text-primary hover:underline">
                            @{user.username}
                        </Link>
                    );
                }

                return <span key={index}>@{part}</span>;
            }

            return part;
        });
    };

    const confirmDelete = async () => {
        if (deleteButtonText === 'Supprimer') {
            setDeleteButtonText('Confirmer');
        }
        else {
            try {
                await axios.delete(`${API_URL}/forum/replies/${id}`, {
                    headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
                });
                deleteReply(id)
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const handleReplyEdit = async (e) => {
        e.preventDefault();
        if (formData.message.length === 0) {
            setErrorReply(true);
            return;
        }
        try {
            await axios.put(`${API_URL}/forum/replies/${id}`, formData, {
                headers: {"Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`}
            });
            setIsFormActivated(false);
            setNewMessage(formData.message)
        } catch (error) {
            console.error(error);
        }
    }

    const handleReplyChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleReplyLike = async (replyId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/replies/${replyId}/like`, {}
                , {
                    headers:  { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
                });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    const handleReplyDislike = async (replyId) => {
        try {
            const response = await axios.post(`${API_URL}/forum/replies/${replyId}/dislike`, {}, {
                headers:  { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
            });
            setLiked(response.data.isLiked);
            setLikesCount(response.data.likes);
            setDisliked(response.data.isDisliked);
            setDislikesCount(response.data.dislikes);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <li key={id}
            className="card card-compact bg-neutral border-2 border-secondary border-opacity-50 rounded-2xl shadow-xl m-3">
            <div className="card-body flex-row justify-between align-middle my-2">
                {isFormActivated ? (
                    <form onSubmit={handleReplyEdit} className="flex w-full mx-2 text-lg"
                          noValidate>
                        <button type="button" className="me-2" onClick={() => setIsFormActivated(false)}><BiX size={32}/></button>
                        <input
                            type="text"
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleReplyChange}
                            className={`input flex-grow input-bordered ${errorReply ? 'border-red-500' : ''}`}
                            required
                        />
                        <button type="submit" className="ms-2 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300">
                            <BiSolidSend className="text-accent" size={32}/>
                        </button>
                    </form>
                ) : (
                    <p className={`leading-4 mx-1 text-lg whitespace-pre-wrap ${/\b\w{100,}\b/.test(newMessage) ? 'break-all' : 'break-normal'}`}>{replaceMentionsWithLinks(newMessage)}</p>
                )}
                <span
                    className="text-xl self-center leading-4 text-nowrap">Il y a {formatTimeAgo(createdAt)}{modifiedAt && ('*')}</span>
            </div>
            <div className="card-actions justify-between align-middle mx-4 mb-2">
                <Link to={`/profile/${user._id}`}
                      className="inline-flex align-middle hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                    <img
                        className="rounded-full w-10 ring-2 ring-white ring-opacity-65"
                        src={`https://robohash.org/${user.pfpFileName}?set=set2`}
                        alt={user.username}
                    />
                    <span className="text-xl leading-9 ms-2">{user.username}</span>
                </Link>
                <div className="flex align-middle">
                    {modifiedAt && (
                        <span
                            className="text-md leading-9 text-nowrap me-3">*Modifié il y a {formatTimeAgo(modifiedAt)}</span>
                    )}
                    <button
                        className={`${liked ? 'text-primary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"'}`}
                        onClick={() => handleReplyLike(id)}>
                        <BiSolidLike size={32}/>
                    </button>
                    <span className="leading-9 ms-1 me-2">{likesCount}</span>
                    <button
                        className={`${disliked ? 'text-secondary hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300' : 'hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300'}`}
                        onClick={() => handleReplyDislike(id)}>
                        <BiSolidDislike size={32}/>
                    </button>
                    <span className="leading-9 ms-1 me-2">{dislikesCount}</span>
                    <button
                        className="ms-1 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"
                        onClick={() => replyTo(user.username)}>
                        <ImReply size={32}/>
                    </button>
                    <button
                        className="ms-1 text-yellow-500 hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300">
                        <IoIosWarning size={32}/>
                    </button>
                    {(currentUser.userType === "ADMIN" || currentUser.userType === "MOD") && (
                        <button tabIndex={0}
                                className="ms-1 dropdown">
                            <BiDotsVerticalRounded
                                className="hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"
                                size={32}/>
                            <ul tabIndex={0}
                                className="dropdown-content menu bg-base-300 rounded-box z-[1] w-52 p-2 shadow">
                                <li><span onClick={() => setIsFormActivated(true)}>Modifier</span></li>
                                <li><span className="text-red-600" onClick={() => confirmDelete()}>{deleteButtonText}</span></li>
                            </ul>
                        </button>
                    )}
                </div>
            </div>
        </li>
    );
};

export default ForumReply;
