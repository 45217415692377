import React, {useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Reports from "./Reports";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const ReportModal = ({user, proof}) => {
    console.log(user)
    const navigate = useNavigate()

    const [text, setText] = useState("")

    const close_dialog = () => {
        document.getElementById('modal_report').close()
    }

    const confirm_dialog = async e => {
        e.preventDefault()

        const url = `${process.env.REACT_APP_API_URL || 'http://localhost:3001'}/reports`
        try {
            let body = {
                user: user._id,
                reason: text
            }

            if (proof)
                body.proof = proof

            await axios.post(url, body, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
        } catch (error) {
            navigate(`/${error.response.status}`)
        }

        close_dialog()
    }

    return (
        <dialog id="modal_report" className="modal modal-bottom sm:modal-middle">
            <form className="modal-box" method="post" onSubmit={confirm_dialog}>
                <h3 className="font-bold text-lg">{"Signaler " + user.username}</h3>

                <label htmlFor="countries" className="block mb-2 text-sm font-medium text-white">Raison</label>
                <textarea value={text} className="w-full" onChange={e => {setText(e.target.value)}} />

                <div className="modal-action">
                    <div className="w-full grid sm:grid-cols-2 gap-3">
                        <button type="submit" className="btn btn-sm btn-success" onClick={confirm_dialog}>
                            Confirmer
                        </button>
                        <button type="reset" className="btn btn-sm btn-error" onClick={close_dialog}>Annuler
                        </button>
                    </div>
                </div>
            </form>
        </dialog>
    )
}

export default ReportModal