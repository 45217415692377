import Header from "./Header"
import React, {useContext, useEffect, useState} from "react"
import Footer from "./Footer"
import {Link, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FaEdit} from "react-icons/fa"
import {AuthContext} from "../contexts/AuthContext"
import AvatarPicture from "./AvatarPicture"
import axios from "axios"


const ProfileEdit = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)

    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [showSubmit, setShowSubmit] = useState(false)

    useEffect(() => {
        document.title = "Modification de profil | RetroRealm"
    }, [])

    useEffect(() => {
        setFormData(authUser)
    }, [authUser])

    useEffect(() => {
        if (formData !== authUser) {
            setShowSubmit(true)
        } else {
            setShowSubmit(false)
        }
    }, [formData])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001"
            const response = await axios.patch(API_URL + `/user/edit/${authUser._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setAuthUser(response.data)
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false)
                }, [3000])
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    localStorage.removeItem("token")
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12 mb-8">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/profile" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack
                        size={56}
                        className="text-white"/></Link>
                    <h2 className="text-5xl">Paramètres du profil</h2>
                    <p className="mr-14"></p>
                </div>

                <div className="bg-base-300 rounded-2xl px-10 py-5 flex justify-start items-center mb-5">
                    <div className="flex gap-6 justify-start items-center">
                        <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                            <img className="rounded-full" src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}
                                 alt="Photo de profil"/>
                        </div>
                        <div>
                            <h3 className="text-3xl">{authUser.username}</h3>
                            <p className="text-xl">{calculateAge(authUser.dob)} ans</p>
                        </div>
                    </div>
                </div>

                <div className="flex gap-6 justify-between items-start">
                    <div>
                        <form onSubmit={handleSubmit} noValidate>
                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-2xl">Photo de profil</span>
                                </div>
                            </label>
                            <div className="flex justify-around items-center mb-8">

                                <label htmlFor="pfp-1"
                                       className="p-2">
                                    <input type="radio" id="pfp-1" name="pfpFileName"
                                           value={authUser.username + "1" + ".png"}
                                           className="hidden peer"
                                           checked={formData.pfpFileName === authUser.username + "1" + ".png"}
                                           onChange={handleChange}/>
                                    <AvatarPicture url={`${formData.username}1`}/>
                                </label>
                                <label htmlFor="pfp-2"
                                       className="p-2">
                                    <input type="radio" id="pfp-2" name="pfpFileName"
                                           value={authUser.username + "2" + ".png"}
                                           className="hidden peer"
                                           checked={formData.pfpFileName === authUser.username + "2" + ".png"}
                                           onChange={handleChange}/>
                                    <AvatarPicture url={`${formData.username}2`}/>
                                </label>
                                <label htmlFor="pfp-3"
                                       className="p-2">
                                    <input type="radio" id="pfp-3" name="pfpFileName"
                                           value={authUser.username + "3" + ".png"}
                                           className="hidden peer"
                                           checked={formData.pfpFileName === authUser.username + "3" + ".png"}
                                           onChange={handleChange}/>
                                    <AvatarPicture url={`${formData.username}3`}/>
                                </label>

                                <label htmlFor="pfp-4"
                                       className="p-2">
                                    <input type="radio" id="pfp-4" name="pfpFileName"
                                           value={authUser.username + "4" + ".png"}
                                           className="hidden peer"
                                           checked={formData.pfpFileName === authUser.username + "4" + ".png"}
                                           onChange={handleChange}/>
                                    <AvatarPicture url={`${formData.username}4`}/>
                                </label>
                            </div>

                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-2xl">Genre</span>
                                </div>
                            </label>
                            <div className="flex justify-around items-center mt-4 mb-8">
                                <div className="flex items-center me-4">
                                    <input id="gender-male" type="radio" value="MALE" name="gender"
                                           className="radio radio-primary"
                                           checked={formData.gender === "MALE"}
                                           onChange={handleChange}/>
                                    <label htmlFor="gender-male"
                                           className="mx-2">Homme</label>
                                </div>
                                <div className="flex items-center me-4">
                                    <input id="gender-female" type="radio" value="FEMALE" name="gender"
                                           className="radio radio-primary"
                                           checked={formData.gender === "FEMALE"}
                                           onChange={handleChange}/>
                                    <label htmlFor="gender-female"
                                           className="mx-2">Femme</label>
                                </div>
                                <div className="flex items-center me-4">
                                    <input id="gender-other" type="radio" value="OTHER" name="gender"
                                           className="radio radio-primary"
                                           checked={formData.gender === "OTHER"}
                                           onChange={handleChange}/>
                                    <label htmlFor="gender-other"
                                           className="mx-2">Autre</label>
                                </div>
                            </div>

                            <label className="form-control w-full">
                                <div className="label">
                                    <span className="label-text text-2xl">Jeu préféré</span>
                                </div>
                            </label>
                            <div className="flex justify-around items-center mt-4 mb-8">
                                <div className="flex items-center me-4">
                                    <input id="favorite-odyssey" type="radio" value="ODYSSEY" name="favoriteGame"
                                           className="radio radio-primary"
                                           checked={formData.favoriteGame === "ODYSSEY"}
                                           onChange={handleChange}/>
                                    <label htmlFor="favorite-odyssey"
                                           className="mx-2">Odyssey</label>
                                </div>
                                <div className="flex items-center me-4">
                                    <input id="favorite-allure" type="radio" value="ALLURE" name="favoriteGame"
                                           className="radio radio-primary"
                                           checked={formData.favoriteGame === "ALLURE"}
                                           onChange={handleChange}/>
                                    <label htmlFor="favorite-allure"
                                           className="mx-2">À Vive Allure!</label>
                                </div>
                            </div>

                            <label className="form-control w-full" htmlFor="bio">
                                <div className="label">
                                    <span className="label-text text-2xl">Bio</span>
                                </div>
                            </label>
                            <textarea
                                id="bio"
                                value={formData.bio}
                                name="bio"
                                onChange={handleChange}
                                className="textarea textarea-primary resize-none text-xl bg-base-300 w-full"></textarea>

                            {showSubmit && (
                                <>
                                    {isLoading ? (
                                        <button className="btn btn-primary rounded-full w-44 mt-8">
                                            <span className="loading loading-spinner"></span>
                                            Chargement
                                        </button>
                                    ) : (
                                        <button type="submit"
                                                className="btn btn-primary rounded-full w-44 mt-8">Modifier le
                                            profil</button>
                                    )}
                                </>
                            )}

                        </form>
                    </div>
                    <div className="bg-base-300 rounded-2xl px-10 py-5 w-5/12">
                        <h3 className="mb-3">Adresse courriel</h3>
                        <div className="flex justify-between items-center mb-5">
                            <p>{authUser.email}</p>
                            <Link to="/profile-edit/email"
                                  className="btn btn-primary btn-sm rounded-full px-7">Modifier</Link>
                        </div>

                        <h3 className="mb-3">Date de naissance</h3>
                        <p className="mb-5">{new Date(authUser.dob).toLocaleDateString('en-CA')}</p>

                        <h3 className="mb-3">Mot de passe</h3>
                        <div className="flex flex-col justify-start gap-4">
                            <Link to="/profile-edit/password" className="btn btn-primary btn-sm w-64 rounded-full">Modifier
                                le mot de
                                passe</Link>
                            <Link to="/profile-delete" className="btn btn-error btn-sm w-64 rounded-full">Supprimer votre compte</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>Profil modifié avec succès</span>
                </div>
            </div>
        </div>
    )
}

export default ProfileEdit